import {Injectable} from '@angular/core';
import {TagDTOType, TagsFiltersType, TagType, UpdateTagsForEntityDTOType} from '../../data-model/tagTypes';

@Injectable({
    providedIn: 'root'
})

export class TagProviderMapper {
    constructor() {

    }

    mapUpdateTagsItemForEntityToDTO(entity: TagsFiltersType, tagIds: string[]): UpdateTagsForEntityDTOType {
        const tagEntityDTO: UpdateTagsForEntityDTOType = {} as UpdateTagsForEntityDTOType;
        tagEntityDTO.TagIds = [];

        tagEntityDTO.EntityType = entity.entityType;
        tagEntityDTO.EntityId = entity.entityId;
        tagEntityDTO.TagIds = tagIds;

        return tagEntityDTO;
    }

    mapEntityForUpdateTags(entityType: string, entityId: string): TagsFiltersType {
        return {
            entityType,
            entityId
        };
    }

    mapItemToTagDTO(tag: TagType): TagDTOType {
        const tagDTO: TagDTOType = {} as TagDTOType;

        tagDTO.Id = tag.id;
        tagDTO.Name = tag.name;
        tagDTO.ScopedNone = tag.scopedNone;
        tagDTO.ScopedPatient = tag.scopedPatient;

        return tagDTO;
    }
}
