import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    BackOfficeChangeAssignationType,
    BaseReservationTimeSlotType,
    DeleteReservationFiltersType,
    EditReservationFiltersType,
    MoveReservationFiltersType,
    ReservationInstanceType,
    ReservationResultType
} from '../../../data-model/infrastructure-reservation.types';
import * as lodash from 'lodash';
import {constants} from '../../../shared/constants/constants';
import {infrastructureReservationConstants} from '../infrastructure-reservation.constants';
import * as moment from 'moment';
import {NgbActiveModal, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DateTimeUtils} from '../../../shared/utils/dateTime.utils';
import {InfrastructureReservationUtils} from '../infrastructure-reservation.utils';
import {take} from 'rxjs/operators';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../../shared/services/messages.service';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
// tslint:disable-next-line:max-line-length
import {InfrastructureReservationProvider} from '../../../shared/base-modules/providers/infrastructure-reservation/infrastructure-reservation.provider';
import {IntervalType} from '../../../shared/base-modules/data-model/intervalTypes';

@AutoUnsubscribe()
@Component({
    templateUrl: './create-reservation-modal.component.html',
    styleUrls: ['./create-reservation-modal.component.scss']
})
export class CreateReservationModalComponent implements OnInit, OnDestroy {
    @Input() resourceTimeSlot;
    @Input() calendarTimeSlots;
    @Input() action;

    reservation: any = {};
    assignationType: any;
    splitOption: any;
    changeAssignationType: BackOfficeChangeAssignationType;
    startDate: NgbDateStruct = {} as NgbDateStruct;
    endDate: NgbDateStruct = {} as NgbDateStruct;
    minDate: NgbDateStruct = {} as NgbDateStruct;
    startDateFormatL: string;
    endDateFormatL: string;
    hourFromTime: string;
    hourToTime: string;
    actionBtnLabel: string;
    modalTitle: string;
    modalText1: string;
    modalText2: string;
    modalText3: string;
    showReservationConflicts = false;
    showTimeSlotAlternative = false;
    showBackButton = false;
    showActionButton = false;
    isTimeValid = true;
    isDateValid = true;
    isDateGreaterThanYearValid = true;
    currentPage = 1;
    editReservationSimulationList: BaseReservationTimeSlotType[] = [];
    conflictInstances: ReservationInstanceType[] = [];
    successfulInstances: ReservationInstanceType[] = [];
    infrastructureReservationConstants = infrastructureReservationConstants;
    constants = constants;

    constructor(private dateTimeUtils: DateTimeUtils,
                private infrastructureReservationUtils: InfrastructureReservationUtils,
                private infrastructureReservationProvider: InfrastructureReservationProvider,
                public ngxLoader: NgxUiLoaderService,
                public messagesService: MessagesService,
                public activeModal: NgbActiveModal) {
    }


    ngOnInit(): void {
        this.reservation = lodash.cloneDeep(this.resourceTimeSlot);

        this.initDataForDisplay();

        this.initDataWhenTimeSlotsConflicts();

        this.onPageChanged(this.getCurrentPage(this.action));
    }

    ngOnDestroy(): void {
    }

    onPageChanged(page: number): void {
        switch (page) {
            case 0: {
                this.showBackButton = false;
                this.showActionButton = false;
                this.currentPage = page;
                this.getTextsByAction(this.action, this.currentPage);
                break;
            }
            case 1: {
                this.initDataForDisplay();
                this.editReservationSimulationList = [];
                this.showBackButton = (this.action !== constants.CREATE && this.action !== constants.MOVE);
                this.showActionButton = true;
                this.actionBtnLabel = 'button.next';
                this.currentPage = page;
                this.isTimeValid = this.isSelectedTimeValid(this.reservation.timeSlot.hourFrom, this.reservation.timeSlot.hourTo, this.action);
                this.getTextsByAction(this.action, this.currentPage);
                break;
            }
            case 2: {
                this.showActionButton = true;
                this.actionBtnLabel = 'label.' + this.action;
                this.showBackButton = !(this.action === constants.MOVE && !this.showReservationConflicts);
                this.currentPage = page;
                this.getTextsByAction(this.action, this.currentPage);
                break;
            }
            case 3: {
                this.saveByAction(true);
                this.getTextsByAction(this.action, this.currentPage);
                break;
            }
            case 4: {
                this.saveByAction(false);
                break;
            }
        }
    }

    createInfrastructureReservation(isSimulation: boolean): void {
        this.ngxLoader.start();
        this.reservation.isSimulation = isSimulation;

        this.infrastructureReservationProvider.createReservation(this.reservation)
            .pipe(take(1),
            )
            .subscribe((response) => {
                this.onResponseReceived(response, isSimulation);
            }, (message) => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(message);
            }, () => {
                this.ngxLoader.stop();
                if (!isSimulation) {
                    this.messagesService.success('toastr.success.reservationSaved', true);
                }
            });
    }

    moveInfrastructureReservation(isSimulation: boolean): void {
        this.ngxLoader.start();
        this.reservation.isSimulation = isSimulation;
        let reservationToBeMoved: MoveReservationFiltersType = {} as MoveReservationFiltersType;
        if (this.showReservationConflicts) {
            reservationToBeMoved = lodash.cloneDeep(this.reservation);
        } else {
            reservationToBeMoved.infrastructureId = this.reservation.infrastructureId;
            reservationToBeMoved.isSimulation = this.reservation.isSimulation;
            reservationToBeMoved.assignationType = this.reservation.assignationType;
            reservationToBeMoved.endDate = this.reservation.endDate;
            reservationToBeMoved.timeSlot = {hourFrom: undefined, hourTo: undefined};
        }

        reservationToBeMoved.originalReservationId = this.reservation.reservationId;

        this.infrastructureReservationProvider.moveReservation(reservationToBeMoved)
            .pipe(take(1),
            )
            .subscribe((response) => {
                this.onResponseReceived(response, isSimulation);
            }, (message) => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(message);
            }, () => {
                this.ngxLoader.stop();
                if (!isSimulation) {
                    this.messagesService.success('toastr.success.reservationMoved', true);
                }
            });
    }

    editInfrastructureReservation(action: string): void {
        this.ngxLoader.start();
        const reservationToBeEdited: EditReservationFiltersType = {} as EditReservationFiltersType;

        reservationToBeEdited.endDate = this.reservation.endDate;
        reservationToBeEdited.reservationId = this.reservation.reservationId;
        reservationToBeEdited.assignationType = this.reservation.assignationType;
        reservationToBeEdited.editType = lodash.upperFirst(action);
        reservationToBeEdited.timeSlot = this.reservation.timeSlot;

        this.infrastructureReservationProvider.editReservation(reservationToBeEdited)
            .pipe(take(1),
            )
            .subscribe((response) => {
            }, (message) => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(message);
            }, () => {
                this.ngxLoader.stop();
                if (this.action === infrastructureReservationConstants.SPLIT) {
                    this.messagesService.success('toastr.success.reservationSplit', true);
                } else if (this.action === infrastructureReservationConstants.SPLIT) {
                    this.messagesService.success('toastr.success.reservationShrink', true);
                }

                this.activeModal.close({});
            });
    }

    deleteInfrastructureReservation(): void {
        this.ngxLoader.start();
        const reservationToBeDeleted: DeleteReservationFiltersType = {} as DeleteReservationFiltersType;
        reservationToBeDeleted.assignationType = this.reservation.assignationType;
        reservationToBeDeleted.dateTo = this.reservation.endDate;
        reservationToBeDeleted.reservationId = this.reservation.reservationId;

        this.infrastructureReservationProvider.deleteReservation(reservationToBeDeleted)
            .pipe(take(1))
            .subscribe((response) => {
            }, (message) => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(message);
            }, () => {
                this.ngxLoader.stop();
                this.messagesService.success('toastr.success.reservationDeleted', true);
                this.activeModal.close({});
            });
    }

    onActionButtonClick(action: string): void {
        this.action = action;
        this.onPageChanged(this.getCurrentPage(this.action));
    }

    onHourToChanged(hourToTime: string): void {
        this.reservation.timeSlot.hourTo = moment.duration(hourToTime).asMinutes();
        this.hourToTime = hourToTime;
        this.isTimeValid = this.isSelectedTimeValid(this.reservation.timeSlot.hourFrom, this.reservation.timeSlot.hourTo, this.action);
        if (this.isTimeValid && (this.action === infrastructureReservationConstants.SPLIT ||
            this.action === infrastructureReservationConstants.SHRINK)) {
            //get resulted reservations
            this.editReservationSimulationList = this.getPreviewReservationsForEdit(this.reservation.timeSlot,
                this.resourceTimeSlot.timeSlot, this.action);
        }
    }

    onSplitOptionChanged(checkedSplitOption: string, checked: boolean): void {
        switch (checkedSplitOption) {
            case infrastructureReservationConstants.DELETE_ORIGINAL_RESERVATION: {
                if (checked) {
                    this.splitOption.keepOriginalReservation = !checked;
                    this.reservation.splitOption = infrastructureReservationConstants.DELETE_ORIGINAL_RESERVATION;
                }
                break;
            }
            case infrastructureReservationConstants.KEEP_ORIGINAL_RESERVATION: {
                if (checked) {
                    this.splitOption.deleteOriginalReservation = !checked;
                    this.reservation.splitOption = infrastructureReservationConstants.KEEP_ORIGINAL_RESERVATION;
                }
                break;
            }
        }
    }

    onAssignationTypeChanged(checkedAssignationType: string, checked: boolean): void {
        this.reservation.endDate = undefined;
        switch (checkedAssignationType) {
        case infrastructureReservationConstants.SINGLE_INSTANCE: {
                if (checked) {
                    this.assignationType.allInstances = !checked;
                    this.assignationType.untilEndDate = !checked;
                    this.reservation.assignationType = infrastructureReservationConstants.SINGLE_INSTANCE;
                }
                break;
            }
        case infrastructureReservationConstants.ALL_INSTANCES: {
                if (checked) {
                    this.assignationType.singleInstance = !checked;
                    this.assignationType.untilEndDate = !checked;
                    this.reservation.assignationType = infrastructureReservationConstants.ALL_INSTANCES;
                }
                break;
            }
        case infrastructureReservationConstants.UNTIL_END_DATE: {
                if (checked) {
                    this.assignationType.singleInstance = !checked;
                    this.assignationType.allInstances = !checked;
                    this.reservation.assignationType = infrastructureReservationConstants.UNTIL_END_DATE;
                    this.reservation.endDate = moment(this.reservation.startDate).add(1, 'day').format('YYYY-MM-DD');
                } else {
                    this.reservation.endDate = undefined;
                }
                break;
            }
        default: {
                //statements;
                break;
            }
        }
    }

    onStartDateChange(startDate: NgbDateStruct): void {
        if (startDate) {
            this.reservation.startDate = moment({
                year: startDate.year,
                month: startDate.month - 1,
                day: startDate.day
            }).format('YYYY-MM-DD');
            this.startDateFormatL = this.reservation.startDate;
        }
        this.isDateValid = this.infrastructureReservationUtils.isSelectedDateValid(this.reservation.startDate, this.reservation.endDate);
        this.isDateGreaterThanYearValid = (this.infrastructureReservationUtils.isDateDiffLessThanYear(this.reservation.startDate, this.reservation.endDate) || this.action !== constants.CREATE);
    }

    onChangeAssignationTypeChanged(checkedAssignationType: string, checked: boolean): void {
        this.reservation.endDate = undefined;
        switch (checkedAssignationType) {
        case infrastructureReservationConstants.THIS_INSTANCE: {
                if (checked) {
                    this.changeAssignationType.allInstances = !checked;
                    this.changeAssignationType.thisAndFollowingInstances = !checked;
                    this.changeAssignationType.thisAndFollowingInstancesTillEndDate = !checked;
                    this.reservation.assignationType = infrastructureReservationConstants.THIS_INSTANCE;
                }
                break;
            }
        case infrastructureReservationConstants.ALL_INSTANCES: {
                if (checked) {
                    this.changeAssignationType.thisInstance = !checked;
                    this.changeAssignationType.thisAndFollowingInstances = !checked;
                    this.changeAssignationType.thisAndFollowingInstancesTillEndDate = !checked;
                    this.reservation.assignationType = infrastructureReservationConstants.ALL_INSTANCES;
                }
                break;
            }
        case infrastructureReservationConstants.THIS_AND_FOLLOWING_INSTANCES: {
                if (checked) {
                    this.changeAssignationType.thisInstance = !checked;
                    this.changeAssignationType.allInstances = !checked;
                    this.changeAssignationType.thisAndFollowingInstancesTillEndDate = !checked;
                    this.reservation.assignationType = infrastructureReservationConstants.THIS_AND_FOLLOWING_INSTANCES;
                }
                break;
            }
        case infrastructureReservationConstants.THIS_AND_FOLLOWING_INSTANCES_TILL_END_DATE: {
                if (checked) {
                    this.changeAssignationType.thisInstance = !checked;
                    this.changeAssignationType.allInstances = !checked;
                    this.changeAssignationType.thisAndFollowingInstances = !checked;
                    this.reservation.assignationType = infrastructureReservationConstants.THIS_AND_FOLLOWING_INSTANCES_TILL_END_DATE;
                    this.reservation.endDate = moment(this.reservation.startDate).add(1, 'day').format('YYYY-MM-DD');
                } else {
                    this.reservation.endDate = undefined;
                }
                break;
            }
        default: {
                //statements;
                break;
            }
        }
    }

    onEndDateChange(endDate: NgbDateStruct): void {
        if (endDate) {
            this.reservation.endDate = moment({
                year: endDate.year,
                month: endDate.month - 1,
                day: endDate.day
            }).format('YYYY-MM-DD');
            this.endDateFormatL = this.reservation.endDate;
        }
        this.isDateValid = this.infrastructureReservationUtils.isSelectedDateValid(this.reservation.startDate, this.reservation.endDate);
        this.isDateGreaterThanYearValid = (this.infrastructureReservationUtils.isDateDiffLessThanYear(this.reservation.startDate, this.reservation.endDate) || this.action !== constants.CREATE);
    }

    isScreenDataValid(): boolean {
        return (this.isTimeValid && this.isDateValid && this.isDateGreaterThanYearValid  &&
                (this.assignationType.singleInstance || this.assignationType.allInstances || this.assignationType.untilEndDate ||
                    // tslint:disable-next-line:max-line-length
                    this.changeAssignationType.thisInstance || this.changeAssignationType.allInstances || this.changeAssignationType.thisAndFollowingInstances || this.changeAssignationType.thisAndFollowingInstancesTillEndDate)) &&
            (this.splitOption.deleteOriginalReservation || this.splitOption.keepOriginalReservation) &&
            !this.checkForTimeSlotsOverlapping(this.reservation.timeSlot, this.calendarTimeSlots);
    }

    onHourFromChanged(hourFromTime: string): void {
        this.reservation.timeSlot.hourFrom = moment.duration(hourFromTime).asMinutes();
        this.hourFromTime = hourFromTime;
        if (this.action === infrastructureReservationConstants.SPLIT) {
            this.reservation.timeSlot.hourTo = moment.duration(hourFromTime).asMinutes();
            this.hourToTime = hourFromTime;
        }
        this.isTimeValid = this.isSelectedTimeValid(this.reservation.timeSlot.hourFrom, this.reservation.timeSlot.hourTo, this.action);
        if (this.isTimeValid && (this.action === infrastructureReservationConstants.SPLIT ||
            this.action === infrastructureReservationConstants.SHRINK)) {
            //get resulted reservations / blockings
            this.editReservationSimulationList = this.getPreviewReservationsForEdit(this.reservation.timeSlot,
                this.resourceTimeSlot.timeSlot, this.action);
        }
    }

    private onResponseReceived(response: ReservationResultType, isSimulation: boolean): void {
        this.conflictInstances = response.conflictInstances;
        this.successfulInstances = response.successfulInstances;

        this.currentPage = this.currentPage + 1;

        if (isSimulation) {
            if (this.conflictInstances.length > 0) {
                this.actionBtnLabel = 'button.saveWithConflicts';
            } else {
                this.actionBtnLabel = 'button.save';
            }
        } else {
            this.activeModal.close({reservation: this.reservation, conflicts: this.conflictInstances});
        }
    }

    private saveByAction(isSimulation: boolean): void {
        switch (this.action) {
            case constants.CREATE: {
                this.createInfrastructureReservation(isSimulation);
                break;
            }
            case constants.MOVE: {
                this.moveInfrastructureReservation(isSimulation);
                break;
            }
            case infrastructureReservationConstants.SHRINK: {
                this.editInfrastructureReservation(this.action);
                break;
            }
            case infrastructureReservationConstants.SPLIT: {
                this.editInfrastructureReservation(this.action);
                break;
            }
            case constants.DELETE: {
                this.deleteInfrastructureReservation();
                break;
            }
        }
    }

    private getTextsByAction(action: string, page: number) {
        switch (action) {
            case constants.CREATE: {
                switch (page) {
                    case 1: {
                        if (this.showReservationConflicts) {
                            this.modalTitle = 'label.reservationConflict';
                        } else {
                            this.modalTitle = 'label.repeatReservation';
                        }
                        this.modalText2 = 'label.reservationInterval';
                        break;
                    }
                    case 2: {
                        this.modalTitle = 'label.repeatReservation';
                        break;
                    }
                    case 3: {
                        this.modalTitle = 'label.reservationConfirmationSave';
                        break;
                    }
                }
                break;
            }
            case constants.MOVE: {
                switch (page) {
                    case 1: {
                        if (this.showReservationConflicts) {
                            this.modalTitle = 'label.reservationConflict';
                        } else {
                            this.modalTitle = 'label.reservationConfirmation';
                        }
                        this.modalText2 = 'label.reservationInterval';
                        break;
                    }
                    case 2: {
                        this.modalTitle = 'label.reservationConfirmation';
                        break;
                    }
                    case 3: {
                        this.modalTitle = 'label.reservationConfirmationSave';
                        break;
                    }
                }
                break;
            }
            case constants.CLICK: {
                this.modalTitle = 'label.actionForReservation';
                this.modalText1 = 'label.chooseAction';
                break;
            }
            case infrastructureReservationConstants.SHRINK: {
                switch (page) {
                    case 1: {
                        this.modalTitle = 'label.shrinkReservation';
                        this.modalText2 = 'label.reservationIntervalShrink';
                        this.modalText3 = 'label.reservationPreviewShrink';
                        break;
                    }
                    case 2: {
                        this.modalTitle = 'label.shrinkReservation';
                        break;
                    }
                    case 3: {
                        this.modalTitle = 'label.reservationConfirmationSave';
                        break;
                    }
                }
                break;
            }
            case infrastructureReservationConstants.SPLIT: {
                switch (page) {
                    case 1: {
                        this.modalTitle = 'label.splitReservation';
                        this.modalText2 = 'label.reservationIntervalSplit';
                        this.modalText3 = 'label.reservationPreviewSplit';
                        break;
                    }
                    case 2: {
                        this.modalTitle = 'label.splitReservation';
                        break;
                    }
                    case 3: {
                        this.modalTitle = 'label.reservationConfirmationSave';
                        break;
                    }
                }
                break;
            }
            case constants.DELETE: {
                switch (page) {
                    case 1: {
                        this.modalTitle = 'label.deleteReservation';
                        break;
                    }
                    case 2: {
                        this.modalTitle = 'label.deleteReservation';
                        break;
                    }
                    case 3: {
                        this.modalTitle = 'label.reservationConfirmationSave';
                        break;
                    }
                }
                break;
            }
        }
    }

    private getCurrentPage(action: string): number {
        let firstPage: number;
        if (action === constants.CLICK) {
            firstPage = 0;
        } else if (action === constants.DELETE) {
            firstPage = 2;
        } else {
            firstPage = action === constants.MOVE && !this.showReservationConflicts ? 2 : 1;
        }
        return firstPage;
    }

    private initDataForDisplay(): void {
        this.assignationType = {
            singleInstance: false,
            allInstances: false,
            untilEndDate: false
        };

        this.changeAssignationType = {
            thisInstance: false,
            allInstances: false,
            thisAndFollowingInstances: false,
            thisAndFollowingInstancesTillEndDate: false
        };

        if (this.action === constants.CREATE) {
            this.assignationType.allInstances = true;
            this.reservation.assignationType = infrastructureReservationConstants.ALL_INSTANCES;
        } else {
            this.changeAssignationType.thisInstance = true;
            this.reservation.assignationType = infrastructureReservationConstants.THIS_INSTANCE;
        }

        this.splitOption = {
            deleteOriginalReservation: true,
            keepOriginalReservation: false
        };

        if (this.action === constants.MOVE) {
            this.reservation.splitOption = infrastructureReservationConstants.DELETE_ORIGINAL_RESERVATION;
        }

        const endDate = moment.parseZone(this.reservation.startDate).add(1, 'day');
        this.startDate = this.dateTimeUtils.convertDateInNgbDateStruct(this.reservation.startDate);
        this.endDate = {
            year: moment.parseZone(this.reservation.startDate).year(),
            month: moment.parseZone(this.reservation.startDate).month() + 1,
            day: endDate.date(),
        };
        this.minDate = {
            year: moment().year(),
            month: moment().month() + 1,
            day: moment().date(),
        };

        this.reservation.startDate = moment.parseZone(this.reservation.startDate).format('YYYY-MM-DD');
        this.startDateFormatL = moment.parseZone(this.reservation.startDate).format('L');
        this.endDateFormatL = endDate.format('L');

        this.hourFromTime = this.dateTimeUtils.getStringHourFromMinutes(this.reservation.timeSlot.hourFrom);
        this.hourToTime = this.dateTimeUtils.getStringHourFromMinutes(this.reservation.timeSlot.hourTo);
    }

    private initDataWhenTimeSlotsConflicts(): void {
        if (this.calendarTimeSlots.length > 0) {
        this.calendarTimeSlots = lodash.orderBy(this.calendarTimeSlots, ['hourFrom']);
        this.showReservationConflicts = this.checkForTimeSlotsOverlapping(this.reservation.timeSlot, this.calendarTimeSlots);

        if (this.showReservationConflicts) {
            const timeSlotsArray = this.infrastructureReservationUtils.getAllTimeSlotsArray(this.calendarTimeSlots, this.reservation.timeSlot);

            const timeSlotGap: BaseReservationTimeSlotType = {} as BaseReservationTimeSlotType;
            for (let i = 0; i < timeSlotsArray.length + 1; i++) {
                if (i === 0) {
                    timeSlotGap.hourFrom = 0; // start of day
                    timeSlotGap.hourTo = timeSlotsArray[i];
                } else if (i === timeSlotsArray.length) {
                    timeSlotGap.hourFrom = timeSlotsArray[i - 1];
                    timeSlotGap.hourTo = 1440; // end of day
                } else {
                    timeSlotGap.hourFrom = timeSlotsArray[i - 1];
                    timeSlotGap.hourTo = timeSlotsArray[i];
                }

                //check this is a valid gap
                //-must be in selected time slot interval
                //-must not overlap with other existing time slots
                //-must overlap with resource time slot
                this.showTimeSlotAlternative = this.isSelectedTimeValid(timeSlotGap.hourFrom, timeSlotGap.hourTo, this.action) &&
                    !this.checkForTimeSlotsOverlapping(timeSlotGap, this.calendarTimeSlots) &&
                    this.checkForTimeSlotsOverlapping(this.reservation.timeSlot, [timeSlotGap]);
                if (this.showTimeSlotAlternative) {
                    //find a suitable time slot
                    if (this.reservation.timeSlot.hourFrom < timeSlotGap.hourFrom) {
                        this.reservation.timeSlot.hourFrom = timeSlotGap.hourFrom;
                    }

                    if (this.reservation.timeSlot.hourTo > timeSlotGap.hourTo) {
                        this.reservation.timeSlot.hourTo = timeSlotGap.hourTo;
                    }
                    break;
                }
            }
        }

        //update the time for time picker
        this.hourFromTime = this.dateTimeUtils.getStringHourFromMinutes(this.reservation.timeSlot.hourFrom);
        this.hourToTime = this.dateTimeUtils.getStringHourFromMinutes(this.reservation.timeSlot.hourTo);
    }
}

    private isSelectedTimeValid(hourFrom: number, hourTo: number, action: string): boolean {
        if (action === infrastructureReservationConstants.SPLIT) {
            return hourFrom > this.resourceTimeSlot.timeSlot.hourFrom && hourFrom < this.resourceTimeSlot.timeSlot.hourTo;
        } else if (action === infrastructureReservationConstants.SHRINK) {
            return (hourFrom > this.resourceTimeSlot.timeSlot.hourFrom && hourFrom < this.resourceTimeSlot.timeSlot.hourTo &&
                hourTo <= this.resourceTimeSlot.timeSlot.hourTo) || (hourFrom >= this.resourceTimeSlot.timeSlot.hourFrom &&
                hourTo < this.resourceTimeSlot.timeSlot.hourTo && hourTo > this.resourceTimeSlot.timeSlot.hourFrom);
        } else {
            return hourTo > hourFrom &&
                hourFrom >= this.resourceTimeSlot.timeSlot.hourFrom && hourFrom < this.resourceTimeSlot.timeSlot.hourTo &&
                hourTo > this.resourceTimeSlot.timeSlot.hourFrom && hourTo <= this.resourceTimeSlot.timeSlot.hourTo;
        }
    }

    private checkForTimeSlotsOverlapping(reservationTimeSlot: IntervalType, calendarTimeSlots): boolean {
        let overlapFound = false;
        for (const timeSlot of calendarTimeSlots) {
            overlapFound = this.infrastructureReservationUtils.checkIfTimeSlotIsOverlapping(reservationTimeSlot, timeSlot);
            if (overlapFound) {
                return overlapFound;
            }
        }
        return overlapFound;
    }

    private getPreviewReservationsForEdit(selectedTimeSlot: IntervalType, originalTimeSlot: IntervalType, action: string):
        BaseReservationTimeSlotType[] {
        const editReservationSimulationList: BaseReservationTimeSlotType[] = [];
        switch (action) {
            case infrastructureReservationConstants.SPLIT: {
                editReservationSimulationList.push({
                    hourFrom: originalTimeSlot.hourFrom,
                    hourTo: selectedTimeSlot.hourFrom
                });
                editReservationSimulationList.push({
                    hourFrom: selectedTimeSlot.hourFrom,
                    hourTo: originalTimeSlot.hourTo
                });
                break;
            }
            case infrastructureReservationConstants.SHRINK: {
                if (selectedTimeSlot.hourFrom > originalTimeSlot.hourFrom && selectedTimeSlot.hourTo < originalTimeSlot.hourTo) {
                    editReservationSimulationList.push({
                        hourFrom: originalTimeSlot.hourFrom,
                        hourTo: selectedTimeSlot.hourFrom
                    });
                    editReservationSimulationList.push({
                        hourFrom: selectedTimeSlot.hourTo,
                        hourTo: originalTimeSlot.hourTo
                    });
                } else if (selectedTimeSlot.hourFrom === originalTimeSlot.hourFrom && selectedTimeSlot.hourTo < originalTimeSlot.hourTo) {
                    editReservationSimulationList.push({
                        hourFrom: selectedTimeSlot.hourTo,
                        hourTo: originalTimeSlot.hourTo
                    });
                } else if (selectedTimeSlot.hourFrom > originalTimeSlot.hourFrom && selectedTimeSlot.hourTo === originalTimeSlot.hourTo) {
                    editReservationSimulationList.push({
                        hourFrom: originalTimeSlot.hourFrom,
                        hourTo: selectedTimeSlot.hourFrom
                    });
                }
                break;
            }
        }

        return editReservationSimulationList;
    }
}
