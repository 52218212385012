import {Injectable} from '@angular/core';
import {SystemConfigurationDTOType, SystemConfigurationType} from '../../data-model/systemConfigurationTypes';

@Injectable({
  providedIn: 'root'
})

export class SystemConfigProviderMapper {
  public mapSystemConfigsDTOToEntity(systemConfigsDTO: SystemConfigurationDTOType[]): SystemConfigurationType[] {
    const systemConfigs: SystemConfigurationType[] = [];
    for (const value of systemConfigsDTO) {
      systemConfigs.push(this.mapSystemConfigDTOToEntity(value));
    }
    return systemConfigs;
  }

  public mapSystemConfigDTOToEntity(systemConfigDTO: SystemConfigurationDTOType): SystemConfigurationType {
    const systemConfig: SystemConfigurationType = {} as SystemConfigurationType;
    systemConfig.name = systemConfigDTO.Name;
    systemConfig.value = systemConfigDTO.Value;
    systemConfig.cancelled = systemConfigDTO.Cancelled;
    systemConfig.id = systemConfigDTO.Id;
    systemConfig.modifiedOn = systemConfigDTO.ModifiedOn;
    systemConfig.createdOn = systemConfigDTO.CreatedOn;
    systemConfig.rowVersion = systemConfigDTO.RowVersion;
    systemConfig.modifiedBy = systemConfigDTO.ModifiedBy;
    systemConfig.createdBy = systemConfigDTO.CreatedBy;
    systemConfig.modifiedByClient = systemConfigDTO.ModifiedByClient;
    systemConfig.createdByClient = systemConfigDTO.CreatedByClient;

    return systemConfig;
  }

  public mapItemToSystemConfigDTO(systemConfig: SystemConfigurationType): SystemConfigurationDTOType {
    const systemConfigDTO: SystemConfigurationDTOType = {} as SystemConfigurationDTOType;
    systemConfigDTO.Name = systemConfig.name;
    systemConfigDTO.Value = systemConfig.value;
    systemConfigDTO.Cancelled = systemConfig.cancelled;
    systemConfigDTO.Id = systemConfig.id;
    systemConfigDTO.ModifiedOn = systemConfig.modifiedOn;
    systemConfigDTO.CreatedOn = systemConfig.createdOn;
    systemConfigDTO.RowVersion = systemConfig.rowVersion;
    systemConfigDTO.ModifiedBy = systemConfig.modifiedBy;
    systemConfigDTO.CreatedBy = systemConfig.createdBy;
    systemConfigDTO.ModifiedByClient = systemConfig.modifiedByClient;
    systemConfigDTO.CreatedByClient = systemConfig.createdByClient;

    return systemConfigDTO;
  }
}
