<div class="container-fluid overview-screen-md">
    <!---Header-->
    <div class="d-block">
        <h3 translate="label.home"></h3>
    </div>
    <!--End Header-->

    <!-- Body -->
    <div class="d-block main-dashboard-container">
        <h1 class="margin-auto" translate="label.welcome"></h1>
    </div>
    <!-- End Body -->
</div>
