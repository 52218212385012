import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {LoggerService} from '../../shared/services/logger.service';

@Injectable()
 // this class is used for catch exceptions and log them into azure insights;
export class CustomErrorHandler extends ErrorHandler {
  constructor(private injector: Injector) {
    super();
  }

  handleError(error: any): void {
    const loggerService = this.injector.get(LoggerService);
    loggerService.logException(error);
    super.handleError(error);
  }
}
