import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, HostListener, ElementRef} from '@angular/core';
import {debounceTime, distinctUntilChanged, take} from 'rxjs/operators';
import {IGenericProvider} from '../../data-model/genericProviderInterface';
import * as lodash from 'lodash';

@Component({
    selector: 'base-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnChanges {
    @Input() buttonName: string;
    @Input() initialFilterValues: any[];
    @Input() dependentFilters: any;
    @Input() isMultipleSelectedItem: boolean;
    @Input() providerInstance: IGenericProvider;
    @Input() useSelectedValueAsLabel: boolean;
    @Input() hideSelectedItems: boolean;
    @Input() customTemplate?: any;
    @Input() customError400Label?: string;
    @Input() customInitialNotFoundLabel?: string;
    @Input() minSearchLength?: number;
    @Input() externalURL?: string;
    // add additionalExclusionList input parameter if we want to exclude some items from the beginning
    @Input() additionalExclusionList?: string[];
    @Output() selectedItemsOutput: EventEmitter<any[]> = new EventEmitter<any[]>();
    items: any[] = [];
    selectedItems: any;
    showDropdown: boolean;
    showSpinner: boolean;
    defaultNotFoundLabel = 'toastr.error.notFound';
    notFoundLabel: string = this.defaultNotFoundLabel;

    constructor(private elementRef: ElementRef) {
    }

    @HostListener('document:click', ['$event'])

    click(e) {
        if (!this.isMultipleSelectedItem && !this.elementRef.nativeElement.contains(e.target)) {
            this.showDropdown = false;
            this.updateQuerySearchPhrase('');
        }
    }

    ngOnInit() {
        this.setDefaultValues();
        this.checkRequiredInputs();
        this.showDropdown = false;
        this.selectedItems = this.getSelectedItems(this.initialFilterValues, this.isMultipleSelectedItem);
        this.updateQueryExclusionList(this.selectedItems, this.additionalExclusionList);
        this.notFoundLabel = this.customInitialNotFoundLabel;
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            const chng = changes[propName];
            if (propName === 'initialFilterValues' && !chng.isFirstChange()) {
                this.selectedItems = this.getSelectedItems(this.isMultipleSelectedItem ? chng.currentValue :
                    chng.currentValue[0], this.isMultipleSelectedItem);
                this.updateQueryExclusionList(this.selectedItems, this.additionalExclusionList);
            }
        }
    }

    // filter button
    onFilterClick(showDropdown) {
        if (showDropdown) {
            this.updateQueryExclusionList(this.selectedItems, this.additionalExclusionList);
            this.getData(this.providerInstance, this.dependentFilters);
        } else {
            // reset search phrase
            this.updateQuerySearchPhrase('');
        }
    }

    onChange(selectedItems) {
        // if single select filter => the dropdown must be close after select an item
        if (!this.isMultipleSelectedItem) {
            this.showDropdown = false;
        }

        this.selectedItems = this.getSelectedItems(selectedItems, this.isMultipleSelectedItem);

        // send Parent the original name
        const originalSelectedItems = this.getOriginalItemName(this.selectedItems);
        // send selected items towards parent
        this.selectedItemsOutput.emit(this.changeInArray(originalSelectedItems));

        // must update exclusion list which will be send towards server
        this.updateQueryExclusionList(selectedItems, this.additionalExclusionList);

        // get updated values
        this.getData(this.providerInstance, this.dependentFilters);
    }

    onRemoveItem(item) {
        if (item.id) {
            // remove from array
            if (this.isMultipleSelectedItem) {
                lodash.remove(this.selectedItems, {id: item.id});
            } else {
                // remove object
                this.selectedItems = {};
            }
        }

        // send selected items towards parent
        this.selectedItemsOutput.emit(this.changeInArray(this.selectedItems));
    }

    onSearch(searchWord) {
        this.updateQuerySearchPhrase(searchWord.term);
        this.getData(this.providerInstance, this.dependentFilters);
    }

    getSubscribeResponse(filters) {
        if (!filters.hasOwnProperty('values') && filters.constructor === Array) {
            this.items = filters;
        } else {
            this.items = filters.value;
        }
        for (const item of this.items) {
            if (item.specialityName) {
                // this property is for keeping service name. It is used only for service filter
                item.nameWithoutSpeciality = lodash.cloneDeep(item.name);
                // this property is used for bindings
                item.name = '<b>' + item.specialityName + '</b> ' + item.name;

            }
            item.disabled = !!item.isParent;
        }
    }

    getGetDataSearchingObservable(providerName, dependentFilters) {
        if (!!this.externalURL) {
            return providerName.getDataSearching(dependentFilters, this.externalURL);
        }
        return providerName.getDataSearching(dependentFilters);
    }

    getData(providerName, dependentFilters) {
        this.notFoundLabel = this.customInitialNotFoundLabel ?? this.defaultNotFoundLabel;
        if ((this.dependentFilters?.searchPhrase?.length ?? 0) < this.minSearchLength) {
            return;
        }
        this.showSpinner = true;
        this.getGetDataSearchingObservable(providerName, dependentFilters)
            .pipe(take(1),
                debounceTime(2000),
                distinctUntilChanged()
            )
            .subscribe(filters => {
                if (!filters.hasOwnProperty('values') && filters.constructor === Array && filters?.length === 0
                    || filters?.value?.length === 0) {
                    this.notFoundLabel = this.defaultNotFoundLabel;
                }
                this.getSubscribeResponse(filters);
            }, (error) => {
                if (error.status === 400) {
                    this.notFoundLabel = this.customError400Label ?? this.defaultNotFoundLabel;
                } else {
                    this.notFoundLabel = this.defaultNotFoundLabel;
                }
                this.showSpinner = false;
            }, () => {
                this.showSpinner = false;
            });
    }

    onClickedOutside($event: Event) {
        this.showDropdown = false;
        // reset search phrase
        this.updateQuerySearchPhrase('');
    }

    showSelectedValueAsLabel(selectedItems, isMultipleSelectedItem, useSelectedValueAsLabel): boolean {
        /* Display selected value as label is useSelectedValueAsLabel=true and at least one item is selected

        single-select|selectedValue|useSelectedValueAsLabel => displayed selected value as label
        YES|YES|YES => YES
        YES|YES|NO => NO
        YES|NO|YES => NO
        YES|NO|NO => NO

        NO|YES|YES => YES
        NO|YES|NO => NO
        NO|NO|YES => NO
        NO|NO|NO => NO
         */
        return !!(useSelectedValueAsLabel &&
          (!lodash.isEmpty(selectedItems) && this.customTemplate ||
            (selectedItems?.hasOwnProperty('name') || (selectedItems && selectedItems[0]?.hasOwnProperty('name'))
          )));
    }


    private setDefaultValues() {
        if (this.isMultipleSelectedItem === undefined) {
            this.isMultipleSelectedItem = false;
        }
        if (this.useSelectedValueAsLabel === undefined) {
            this.useSelectedValueAsLabel = false;
        }
        if (this.hideSelectedItems === undefined) {
            this.hideSelectedItems = false;
        }
        if (this.customError400Label === undefined) {
            this.customError400Label = this.defaultNotFoundLabel;
        }
        if (this.customInitialNotFoundLabel === undefined) {
            this.customInitialNotFoundLabel = this.defaultNotFoundLabel;
        }
        if (this.minSearchLength === undefined) {
            this.minSearchLength = 0;
        }
        if (this.additionalExclusionList === undefined) {
            this.additionalExclusionList = [];
        }
    }

    private checkRequiredInputs() {
        if (this.dependentFilters === undefined) {
            throw new Error('dependentFilters is required');
        }
        if (this.providerInstance === undefined) {
            throw new Error('providerInstance is required');
        }
    }

    private getItemIds(list: any[]): any[] {
        const selectedItems: any[] = [];
        // if the method parameter is empty object, we make empty array
        if (list === undefined) {
            list = [];
        }
        // if the method parameter is an array, we add it in the selectedItems list
        if (list.length > 0) {
            for (const item of list) {
                selectedItems.push(item.id);
            }
        }

        return selectedItems;
    }

    // update exclusion list for dependent filter
    private updateQueryExclusionList(selectedItems: any[] | {}, additionalExclusionList: string[]) {
        const listToSent: any[] = this.changeInArray(selectedItems);
        // @ts-ignore
        this.dependentFilters.exclusionList = lodash.uniq(lodash.concat(...additionalExclusionList, this.getItemIds(listToSent)));
    }

    // update search phrase for dependent filter
    private updateQuerySearchPhrase(searchWord: string) {
        this.dependentFilters.searchPhrase = searchWord;
    }

    private changeInArray(selectedItems: any[] | {}): any[] {
        let listToSend = [];
        // check if selectedItems is array
        if (Array.isArray(selectedItems)) {
            listToSend = selectedItems;
        } else if (!lodash.isEmpty(selectedItems)) {
            // if selectedItems is object, we make it an array because we want to transfer the parent an array
            listToSend = [selectedItems];
        } else {
            // if selectedItems is empty object, we make empty array
            listToSend = [];
        }

        return listToSend;
    }

    private getSelectedItems(selectedItems, isMultipleSelectedItem) {
        let list;

        if (selectedItems === undefined && !isMultipleSelectedItem) {
            list = undefined;
        } else if (selectedItems === undefined && isMultipleSelectedItem) {
            list = [];
        } else if (selectedItems.length > 0 && !isMultipleSelectedItem) {
            list = selectedItems[0];
        } else {
            list = selectedItems;
        }

        return list;
    }

    private getOriginalItemName(selectedItems): any {
        const items = lodash.cloneDeep(selectedItems);
        if (items) {
            if (typeof items === 'object' && !items.length) {
                if (items.nameWithoutSpeciality) {
                    items.name = lodash.cloneDeep(items.nameWithoutSpeciality);
                    delete items.nameWithoutSpeciality;
                }
            } else if (items && items.length > 0) {
                for (const item of items) {
                    if (item.nameWithoutSpeciality?.length > 0) {
                        item.name = lodash.cloneDeep(item.nameWithoutSpeciality);
                        delete item.nameWithoutSpeciality;
                    }
                }
            }
        }
        return items;
    }
}
