import {Injectable} from '@angular/core';
import * as lodash from 'lodash';
import {
    FormValidationType,
    ODataOrderByQueryType,
    ScreenTemplateLayoutType,
    TableFiltersType
} from '../../data-model/general.type';
import {constants} from '../constants/constants';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ODataQueryObjectType} from "../base-modules/data-model/oDataObjectTypes";

@Injectable({
    providedIn: 'root'
})
export class GeneralUtils {
    constructor() {
    }

    RegexURL = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{1,256}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/;

    isSystemConfigValueTrue(stringValue: string | boolean): boolean {
        return stringValue === 'true' || stringValue === 'True' || stringValue === true;
    }

    getItemsPerPageList(): number[] {
        return [5, 10];
    }

    getInitialTableFilter(): TableFiltersType {
        return {
            itemsPerPage: constants.itemsPerPage,
            currentPage: 1,
            filter: {},
            orderBy: {},
            expand: {}
        };
    }

    setTemplateLayout(pageTitle: string, action: string, createOrSaveButton?: string, cancelOrBackButton?: string): ScreenTemplateLayoutType {
        const template: ScreenTemplateLayoutType = {} as ScreenTemplateLayoutType;
        template.pageTitle = pageTitle;
        template.action = action;
        if (createOrSaveButton !== undefined) {
            template.createOrSaveButton = createOrSaveButton;
        }
        if (cancelOrBackButton) {
            template.cancelOrBackButton = cancelOrBackButton;
        }

        return template;
    }

    addSelectedValueFalseToObjectList(objectList: any[]): any[] {
        return lodash.map(objectList, (object) => {
            object.selected = false;
            return object;
        });
    }

    addSelectedValueTrueToObjectList(objectList: any[]): any[] {
        return lodash.map(objectList, (object) => {
            object.selected = true;
            return object;
        });
    }

    countDecimals(value: number): number {
        if (Math.floor(value.valueOf()) === value.valueOf()) {
            return 0;
        }

        return value.toString().split('.')[1].length || 0;
    }

    getOrderByQuery(orderBy: ODataOrderByQueryType): string[] | undefined {
        const orderByQuery: string[] = [];
        for (const item in orderBy) {
            if (orderBy.hasOwnProperty(item)) {
                orderByQuery.push(lodash.upperFirst(item) + ' ' + orderBy[item]);
            }
        }
        //if the orderByQuery array is empty return undefined in order to not send orderBy to the server
        return (orderByQuery && orderByQuery.length > 0) ? orderByQuery : undefined;
    }

    getModalOptions(): NgbModalOptions {
        return {
            backdrop: 'static',
            keyboard: false,
            size: 'lg'
        };
    }

    getUrlValidation(url: string, isRequired: boolean): FormValidationType {
        if (this.isEmpty(url)) {
            return {isValid: !isRequired, errorMessage: isRequired ? 'label.pleaseAddUrl' : ''} as FormValidationType;
        }
        if (!url.match(this.RegexURL)) {
            return {isValid: false, errorMessage: 'label.invalidUrl'} as FormValidationType;
        }
        return {isValid: true, errorMessage: ''} as FormValidationType;
    }

    isEmpty(value: any): boolean {
        return !!(value === undefined || value === null || value === '');
    }

    isUndefinedOrNull(obj: any): boolean {
        return obj === undefined || obj === null;
    }

    getNumberFromStringOtherwiseUndefined(stringNumber: string): number {
        // Returns the number within a string. If the result is not a number, return undefined instead
        return isNaN(Number(stringNumber)) ? undefined : Number(stringNumber);
    }

    getAccessToken(): string {
        return this.readCookie('XSRF-TOKEN-AWAUsers');
    }

    hasAccessToken(): boolean {

        let token = this.readCookie('XSRF-TOKEN-AWAUsers');

        return token != null && token != '';
    }

    readCookie(name) {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
}
