<div class="btn-group filter-component w-100">
    <div type="button"
         class="btn btn-file dropdown-toggle button-filter"
         (click)="showDropdown = !showDropdown; onFilterClick(showDropdown);"
         [ngClass]="{'show': showDropdown}">
        <!-- display SelectedValue as label-->
        <span class="selected-value pr-1"
              *ngIf="showSelectedValueAsLabel(selectedItems, isMultipleSelectedItem, useSelectedValueAsLabel)">
             <!-- display single-select item-->
            <span *ngIf="!isMultipleSelectedItem">
                <span aria-hidden="true" class="ng-value-icon left"
                      (click)="onRemoveItem(selectedItems); $event.stopPropagation()">×</span>
                <span *ngIf="!customTemplate && selectedItems.specialityName"><b>{{selectedItems.specialityName}}</b></span>&nbsp;
                <span *ngIf="!customTemplate">{{selectedItems.name}}</span>
                <span *ngIf="!!customTemplate">
                    <ng-container *ngTemplateOutlet="customTemplate; context: {$implicit: selectedItems}"></ng-container>
                </span>
            </span>
            <!-- display multiple-select item-->
            <span *ngIf="isMultipleSelectedItem">
                <span *ngFor="let item of selectedItems">
                    <span aria-hidden="true" class="ng-value-icon left"
                          (click)="onRemoveItem(item); $event.stopPropagation()">×</span>
                    <span *ngIf="!customTemplate && item?.specialityName"><b>{{item?.specialityName}}</b></span>&nbsp;
                    <span *ngIf="!customTemplate">{{item?.name}}</span>
                    <span *ngIf="!!customTemplate">
                        <ng-container *ngTemplateOutlet="customTemplate; context: {$implicit: item}"></ng-container>
                    </span>
                </span>
            </span>
        </span>
        <!-- display button title-->
        <span class="text-capitalize"
              translate="{{buttonName}}"
              *ngIf="!showSelectedValueAsLabel(selectedItems, isMultipleSelectedItem, useSelectedValueAsLabel)">
        </span>
    </div>

    <div class="dropdown-menu"
         x-placement="bottom-start"
         *ngIf="showDropdown"
         [exclude]="'.button-filter'"
         (clickOutside)=onClickedOutside($event)
         [ngClass]="{'show': showDropdown, 'hide-selected':hideSelectedItems}">

        <ng-select
                [(ngModel)]="selectedItems"
                [multiple]="isMultipleSelectedItem"
                [loading]=showSpinner
                loadingText="{{'label.loading' | translate}}"
                notFoundText="{{notFoundLabel | translate}}"
                [isOpen]="true"
                [clearable]="!hideSelectedItems"
                [clearOnBackspace]="!hideSelectedItems"
                autofocus
                dropdownPosition="bottom"
                [hideSelected]="true"
                [clearSearchOnAdd]="true"
                placeholder='{{buttonName | translate}}'
                (change)="onChange($event)"
                (search)="onSearch($event)"
                [closeOnSelect]=!isMultipleSelectedItem
        >
            <ng-option *ngFor="let item of items"
                       [value]="item"
                       [disabled]="item.isParent">
                <div [ngClass]="{'isParent':item.isParent}">
                    <!-- If no speciality name, display item.name as simple text -->
                    <span *ngIf="!customTemplate && !item.specialityName">{{item.name}}</span>
                    <!-- If we have speciality name, display the speciality with b tags and nameWithoutSpeciality as simple text -->
                    <span *ngIf="!customTemplate && !!item.specialityName">
                        <b>{{item.specialityName}}</b>
                        {{item.nameWithoutSpeciality}}
                    </span>
                    <span *ngIf="!!customTemplate">
                        <ng-container *ngTemplateOutlet="customTemplate; context: {$implicit: item}"></ng-container>
                    </span>
                </div>
            </ng-option>
        </ng-select>
    </div>
</div>


