import {Inject, Injectable} from '@angular/core';
import {HttpBaseProvider} from './http-base/http-base.provider';
import {Observable} from 'rxjs';
import {GeneralProviderMapper} from './generalProviderMapper';

@Injectable({
    providedIn: 'root'
})
export class GeneralProvider {
    private environment;

    constructor(@Inject('environment') environment,
                private generalProviderMapper: GeneralProviderMapper,
                private httpBase: HttpBaseProvider) {
        this.environment = environment;
    }

    downloadImage(imageKey: string): Observable<any> {
        const FULL_URL = this.environment.BASE_URL + `DownloadImage(ImageKey='${imageKey}')`;
        return this.httpBase.get(FULL_URL, true, 'blob');
    }

    getCancelGraphForRef(objectType: string, objectId: string, refObjectType: string, refObjectId: string, baseUrlAPI?: string) {
        // create the full URL for adding patients
        if (baseUrlAPI === undefined) {
            baseUrlAPI = this.environment.BASE_URL;
        }

        const url = baseUrlAPI + 'CancelFramework/GetCancelGraphForRef?objectType=' + objectType +
            '&ObjectId=' + objectId + '&refObjectType=' + refObjectType + '&refObjectId=' + refObjectId;

        return this.httpBase.get(url);
    }

    getSystemTimeZones() {
        const url = this.environment.BASE_URL + 'GetSystemTimeZones';
        return this.httpBase.get(url);
    }

    getCountriesForLanguage(languageCode) {
        const url = this.environment.BASE_URL + 'Countries/GetCountriesForLanguage(LanguageCode=\'' + languageCode + '\')';
        return this.httpBase.get(url);
    }
}

