export const environment = {
    BASE_URL: '#{baseUrl}',
    WEBAPP_URL: '#{webAppUrl}',
    SHOW_AVAIL_SUNDAY: '#{showSundayAvailability}',
    IS_PRODUCTION: '#{isProductionEnvironment}',
    IS_PRODUCTION_BUILD: true,
    INSIGHTS_ID: '#{insightsId}',
    DEFAULT_COUNTRY_CODE: 'cl',
    WEBAPP_OLD_BACKOFFICE_URL: '#{webAppOldBackofficeUrl}'
};
