//for a good overview, please add a new constant in alphabetic order
//and group by category
export const constants = {
  // storage names
  ACCESS_CONFIG_STORAGE_NAME: 'AccessConfig',
  TENANT_CONFIG_STORAGE_NAME: 'TenantConfig',
  SYSTEM_CONFIG_STORAGE_NAME: 'SystemConfig',
  USER_INFO_STORAGE_NAME: 'UserInfo',
  DEFAULT_HOME_PAGE: 'dashboard',
  APP_VERSION: 'appVersion',
  inputDebounceTime: 400, // milliseconds
  itemsPerPage: 10,
  SYSTEM_CONFIG_PAGE: 'systemConfig',
  FULL_CALENDAR_LICENSE_KEY: '0388368490-fcs-1651772756',
  CLICK: 'click',
  MOVE: 'move',
  CREATE: 'create',
  DELETE: 'delete',
  // status
  ASSIGNED: 'Assigned',
  BLOCKED: 'Blocked',
  RESERVED: 'Reserved',
  TO_BE_ASSIGNED: 'ToBeAssigned',
};

