import {VersionInfoMessageComponent} from './../component/version-info-message/version-info-message.component';
import {Injectable, Component} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {LoggerService} from './logger.service';
import {ErrorKeyMessageType} from '../../data-model/general.type';


@Injectable({
    providedIn: 'root'
})
export class MessagesService {
    private KEY_ERROR_DUPLICATE = 'keyErrorDuplicateEntry';

    constructor(private toastrService: ToastrService,
                private translateService: TranslateService,
                private loggerService: LoggerService) {
    }


    translateMessage(message: string): Observable<string> {
        return this.translateService.get(message);
    }

    error(message: string, translateMessage?: boolean): void {
        if (translateMessage === false) {
            this.toastrService.error(message);
        } else {
            this.translateMessage(message)
                .subscribe(translatedMessage => {
                    this.toastrService.error(translatedMessage);
                }, error => {
                    this.toastrService.warning(message);
                });
        }
    }

    info(message: string, translateMessage?: boolean): void {
        if (translateMessage === false) {
            this.toastrService.info(message);
        } else {
            this.translateMessage(message)
                .subscribe(translatedMessage => {
                    this.toastrService.info(translatedMessage);
                }, error => {
                    this.toastrService.warning(message);
                });
        }
    }

    success(message: string, translateMessage?: boolean): void {
        if (translateMessage === false) {
            this.toastrService.success(message);
        } else {
            this.translateMessage(message)
                .subscribe(translatedMessage => {
                    this.toastrService.success(translatedMessage);
                }, error => {
                    this.toastrService.warning(message);
                });
        }
    }

    warning(message: string, translateMessage?: boolean): void {
        if (translateMessage === false) {
            this.toastrService.warning(message);
        } else {
            this.translateMessage(message)
                .subscribe(translatedMessage => {
                    this.toastrService.warning(translatedMessage);
                }, error => {
                    this.toastrService.warning(message);
                });
        }
    }

    handlingErrorMessage(err: any): void {
        this.loggerService.log(err);
        if (err && err.status) {
            switch (err.status) {
                case 400:
                    this.handlingErrorMessage400code(err);
                    break;
                case 401:
                    this.error('toastr.error.notAuthorized');
                    break;
                case 404:
                    this.error('toastr.error.notFound');
                    break;
                case 500:
                    this.error('toastr.error.serverError');
                    break;
                case 412:
                    this.error('toastr.error.refreshPage');
                    break;
                case -1:
                    this.error('toastr.error.requestAborted');
                    break;
                default:
                    this.error('toastr.error.serverError');
            }
        } else {
            this.error('toastr.error.serverError');
            this.loggerService.logException(err);
        }

    }

    private handlingErrorMessage400code(err: any): void {
        if (err.error && err.error.value) {
            this.onErrorCode400(err.error.value);
        } else if (err.error && err.error.key) {
            this.onErrorCode400WithKey(err.error);
        } else if (err.error && err.error.error) {
            this.onErrorCode400(err.error.error.message);
        } else {
            this.error('toastr.error.serverError');
        }
    }

    private onErrorCode400(errorKey: string) {
        let message = '';
        switch (errorKey) {
            case this.KEY_ERROR_DUPLICATE:
                this.error('toastr.error.keyErrorDuplicateEntry');
                break;
            default:
                message = errorKey;
                this.error(message, false);
        }
    }

    private onErrorCode400WithKey(error: ErrorKeyMessageType) {
        if (this.displayErrorByKey(error.key)) {
            this.error(error.message, false);
        } else {
            this.error('toastr.error.serverError');
        }
    }

    infoAboutVersion(version: string): void {
        const activeToast = this.toastrService.info(null,
            null, {
                toastComponent: VersionInfoMessageComponent,
                disableTimeOut: true,
                tapToDismiss: false,
                toastClass: 'toast',
                closeButton: true,
                positionClass: 'toast-top-right'
            });
        activeToast.toastRef.componentInstance.version = version;
    }

    private displayErrorByKey(key: string): boolean {
        // Whenever the backend will translate the error messages
        // we need to add the key in this array and show the error
        // only if we have the key
        const errorKeys = [];

        return errorKeys.includes(key);
    }

}
