import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';
import * as lodash from 'lodash';
import {NgbDate, NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {IntervalTypeEnum} from '../../data-model/generalTypes';
import {FormValidationType} from '../../data-model/general.type';
import {ConfigDataService} from '../services/config-data.service';
import {DateRangeOptionsType} from '../base-modules/data-model/generalTypes';

@Injectable({
    providedIn: 'root'
})
export class DateTimeUtils {
    constructor(private configDataService: ConfigDataService) {
    }
    isInBrowserTimeZone(timezoneOffset: number): boolean {
        if (timezoneOffset === moment.parseZone(moment()).utcOffset()) {
            return true;
        }
        return false;
    }
    isYearOfDateInPast(date: NgbDateStruct): boolean {
        const today = new Date();
        return date.year < today.getFullYear();
    }

    isYearInPast(year: number): boolean {
        const today = new Date();
        return year < today.getFullYear();
    }

    getStringHourFromMinutes(minutes: number): string {
        return moment.parseZone().startOf('day').add(minutes, 'minutes').format('HH:mm');
    }

    getMinutesFromStringHours(time: string): number {
        return moment(time, 'HH:mm').hours() * 60 + moment(time, 'HH:mm').minutes();
    }

    convertDateInNgbDateStruct(date: Date): NgbDateStruct {
        return {
            year: moment.parseZone(date).year(),
            month: moment.parseZone(date).month() + 1,
            day: moment.parseZone(date).date(),
        };
    }

    getNgbDateFromMoment(momentObject: Moment): NgbDateStruct {
        return {
            year: momentObject.year(),
            month: momentObject.month() + 1,
            day: momentObject.date(),
        } as NgbDateStruct;
    }

    // @TODO: move these to sked as well
    getStringFromNgbDate(date: NgbDateStruct): string {
        return `${date.year}-${date.month}-${date.day}`;
    }

    getMomentFromNgbDate(date: NgbDateStruct): Moment {
        return moment(date.year + '-' + date.month + '-' + date.day, 'YYYY-MM-DD');
    }

    addDaysToNgbDate(date: NgbDateStruct, days: number): NgbDateStruct {
        const momentDatePlusDays = this.getMomentFromNgbDate(date).add(days, 'days');
        return this.getNgbDateFromMoment(momentDatePlusDays);
    }
    // /////

    getStringDateAsNgbDate(date: string): NgbDate {
        return {
            year: moment(date.substring(0, 19)).year(),
            month: moment(date.substring(0, 19)).month() + 1,
            day: moment(date.substring(0, 19)).day()
        } as NgbDate;
    }

    getNgbDateWithoutOneMonth(date: NgbDateStruct): NgbDateStruct {
        return {year: date.year, month: date.month - 1, day: date.day};
    }

    getNgbDateWithOneMonth(date: NgbDateStruct): NgbDateStruct {
        return {year: date.year, month: date.month + 1, day: date.day};
    }

    getStartOfDayIgnoringOffset(date: string): string {
        const pattern = 'YYYY-MM-DD HH:mm:ss';
        return moment(date.substring(0, 19)).startOf('day').format(pattern);
    }

    getDateWithAddedTime(date: string, time: string): string {
        const pattern = 'YYYY-MM-DD HH:mm:ss';
        const newDate = this.addTimeToDate(moment(date.substring(0, 19)).format('YYYY-MM-DDTHH:mm:ss'), time);

        return moment(newDate.substring(0, 19)).format(pattern);
    }

    isTodayOrFuture(date: Date): boolean {
        return !moment(moment(date).startOf('day')).isBefore(moment().startOf('day'));
    }

    getDateWithOneMonth(date: NgbDateStruct): NgbDateStruct {
        return {year: date.year, month: date.month + 1, day: date.day};
    }

    getISODateFormat(date: any) {
        const updatedDate = moment({year: date.year, month: date.month - 1, day: date.day});
        return moment(updatedDate).format();
    }

    validateDiffDates(date1: NgbDateStruct, date2: NgbDateStruct) {
        return !(date1 && date2 && moment(date1).isAfter(moment(date2), 'minutes'));
    }

    getTodayNgbDateStruct(): NgbDateStruct {
        const today = new Date();
        return this.convertDateInNgbDateStruct(today);
    }

    getInitialDateRangeOptions(): DateRangeOptionsType {
        return {
            fromDate: null,
            toDate: null,
            minDate: null,
            maxDate: null,
            maximumRange: null,
            disabled: false
        };
    }

    addTimeToDate(date: string, time: string): string {
        let dateWithTime: string;

        const timeInMinutes = this.getMinutesFromStringHours(time);
        dateWithTime = moment(date).add(timeInMinutes, 'minutes').format();

        return dateWithTime;
    }

    isDateRangeValidForIntervalKind(requestedIntervalKind: IntervalTypeEnum, validFrom: string, validTo: string): boolean {
        return !(requestedIntervalKind === IntervalTypeEnum.RANGE && (!validFrom || !validTo));
    }

    isDateRangeTodayOrFuture(startDate: Date, endDate: Date): boolean {
        return this.isTodayOrFuture(startDate) && this.isTodayOrFuture(endDate);
    }

    ngbTimeStructToHourMinuteString(time: NgbTimeStruct): string {
        if (time === undefined) {
            return undefined;
        }
        return `${time.hour}:${time.minute}`;
    }

    hourMinuteStringToNgbTimeStruct(time: string): NgbTimeStruct {
        if (time.split(':')?.length < 1) {
            return undefined;
        }
        return {
            hour: parseInt(time.split(':')[0], 10),
            minute: parseInt(time.split(':')[1], 10),
            second: 0
        } as NgbTimeStruct;
    }

    getValidateDateAndTimeRange(validFrom: string, validTo: string, endOfDay: boolean, timeZoneId: string): FormValidationType {
        if (lodash.isEmpty(validFrom) || lodash.isEmpty(validTo)) {
            return {isValid: false, errorMessage: 'label.error.required'} as FormValidationType;
        }
        const actualValidTo = endOfDay ?
            this.addOrSubtractDaysFromTimeString(validTo, timeZoneId, 1) :
            validTo;
        if (!this.isDateRangeTodayOrFuture(new Date(validFrom), new Date(actualValidTo))) {
            return {isValid: false, errorMessage: 'toastr.error.dateNotInPast'} as FormValidationType;
        }
        if (moment(validFrom, 'YYYY-MM-DDTHH:mm:ss').isSameOrAfter(moment(actualValidTo, 'YYYY-MM-DDTHH:mm:ss'))) {
            return {isValid: false, errorMessage: 'label.error.invalidDiffDate2'} as FormValidationType;
        }
        return {isValid: true, errorMessage: ''} as FormValidationType;
    }

    // If days > 0 it adds days, if days < 0 it subtracts -days
    addOrSubtractDaysFromTimeString(timeString: string, timeZoneId: string, days: number): string {
        try {
            if (days >= 0) {
                // @ts-ignore
                return moment.tz(
                    moment(timeString).add(days, 'days').parseZone()
                        .startOf('day').parseZone().format('YYYY-MM-DDTHH:mm:ss'),
                    timeZoneId
                ).format();
            }
            // @ts-ignore
            return moment.tz(
                moment(timeString).subtract(0-days, 'days').parseZone()
                    .startOf('day').parseZone().format('YYYY-MM-DDTHH:mm:ss'),
                timeZoneId
            ).format();
        } catch {
            return undefined;
        }
    }

    getMomentStringFromDateAndTime(date: NgbDateStruct, time: NgbTimeStruct, timeZoneId?: string): string {
        const momentDate = moment({
            year: date.year,
            month: date.month - 1,
            day: date.day,
            hour: !!time.hour ? time.hour : 0,
            minute: !!time.minute ? time.minute : 0,
            second: !!time.second ? time.second : 0
        });

        if (!!timeZoneId) {
            // @ts-ignore
            return moment.tz(momentDate.format('YYYY-MM-DDTHH:mm:ss'), timeZoneId).format();
        }
        return momentDate.format();
    }

    isExpiredDate(date: string) {
        return moment(date).isBefore(moment().toISOString());
    }

    getNgbDateStructFromString(date: string): NgbDateStruct {
        return this.convertDateInNgbDateStruct(new Date(date));
    }
}
