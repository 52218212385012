import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {NavBarService} from './nav-bar.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';
import {TranslatedLanguageService} from '../../shared/services/translated-language.service';
import {Router} from '@angular/router';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {constants} from '../../shared/constants/constants';
import {SessionStorageService} from '@efaps/ngx-store';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {tap} from 'rxjs/operators';
import {UserProvider} from '../../shared/base-modules/providers/user-provider/user.provider';

@AutoUnsubscribe()
@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, OnDestroy {
    toggle = window.innerWidth >= 1440;
    translatedLanguage: string;
    currentUser: any;
    logoPath: string;
    isFullScreen: boolean;
    systemConfigFeatureAccess = false;
    systemConfigActivityDisplay = false;

    @HostListener('window:keydown', ['$event']) handleKeyDown(event: KeyboardEvent) {
        if (event.code === 'F11') {
            // todo find lisener for exit fullscreen
            // this.isFullScreen = !this.isFullScreen;
            // this.navBarService.toggleFullScreenEvent(this.isFullScreen);
        }
    }

    constructor(private navBarService: NavBarService,
                private translateService: TranslateService,
                private translatedLanguageService: TranslatedLanguageService,
                private configDataService: ConfigDataService,
                private router: Router,
                private sessionStorageService: SessionStorageService,
                private userProvider: UserProvider) {
    }

    ngOnInit() {
        this.logoPath = environment.BASE_URL + 'DownloadImage(ImageKey=\'LogoName\')';
        this.loadTranslatedLanguage();
        this.getUserInfo();
        this.toggleSidebar();

        this.onFullScreenChange();
        this.ifNeededListenToFeatureAccessChanges('systemConfig', 'admin-new-systemConfig', 'SystemConfigurationRead');
    }

    ngOnDestroy(): void {
    }

    loadTranslatedLanguage(): void {
        // We only load the translated language after the initial request is done
        this.configDataService.initialConfigRequestFinish.subscribe(isInitialRequestDone => {
            if (isInitialRequestDone) {
                this.translatedLanguage = this.getTranslatedLanguage();
            }
        });
    }

    goToDashboard(): void {
        this.router.navigate(['/dashboard']);
    }

    toggleSidebar() {
        // NavBar component calls function of NavBar service and passes current value of toggled boolean representing
        // current state of expand/collapse of sidebar
        this.toggle = !this.toggle;
        this.navBarService.toggleSideBarEvent(this.toggle);
    }

    toggleFullscreen() {
        // NavBar component calls function of NavBar service and passes current value of toggled boolean representing
        // current state of fullScreen/not fullScreen
        this.isFullScreen = !this.isFullScreen;
        // @ts-ignore
        if (document.webkitIsFullScreen) {
            this.exitFullscreen();
        } else {
            this.activateFullscreen();
        }
    }

    onSelectedLang(languageCode: string): void {
        // this is actualy the country code and not the language => needs to be refactored
        this.translatedLanguage = languageCode;
        this.translatedLanguageService.onSetLanguage(this.translatedLanguage);
    }

    private ifNeededListenToFeatureAccessChanges(baseVariableName: string, featureName: string, activityName: string) {
        // If featureAccessConfig exists, we take values from that
        if (this.configDataService.featureAccessConfig) {
            this[`${baseVariableName}FeatureAccess`] = this.configDataService.isFeatureActive(featureName);
        } else {
            // Otherwise, we wait until config-data service fills data in storage, then we take values from there
            this.sessionStorageService.observe(constants.ACCESS_CONFIG_STORAGE_NAME).subscribe((featureAccess) => {
                if (featureAccess?.newValue) {
                    this[`${baseVariableName}FeatureAccess`] = (featureAccess.newValue[featureName] === 'true');
                }
            });
        }
    }

    private activateFullscreen() {
        this.isFullScreen = true;
        this.navBarService.toggleFullScreenEvent(this.isFullScreen);
        const fullscreenElement = document.documentElement;
        if (fullscreenElement.requestFullscreen) {
            fullscreenElement.requestFullscreen();
            // @ts-ignore
        } else if (fullscreenElement.mozRequestFullScreen) {
            /* Firefox */
            // @ts-ignore
            fullscreenElement.mozRequestFullScreen();
            // @ts-ignore
        } else if (fullscreenElement.webkitRequestFullscreen) {
            /* Chrome, Safari and Opera */
            // @ts-ignore
            fullscreenElement.webkitRequestFullscreen();
            // @ts-ignore
        } else if (fullscreenElement.msRequestFullscreen) {
            /* IE/Edge */
            // @ts-ignore
            fullscreenElement.msRequestFullscreen();
        }
    }

    /**
     * Exits Full Screen Mode.
     */
    private exitFullscreen() {
        this.isFullScreen = false;
        this.navBarService.toggleFullScreenEvent(this.isFullScreen);
        if (document.exitFullscreen) {
            document.exitFullscreen();
            // @ts-ignore
        } else if (document.mozCancelFullScreen) {
            /* Firefox */
            // @ts-ignore
            document.mozCancelFullScreen();
            // @ts-ignore
        } else if (document.webkitExitFullscreen) {
            /* Chrome, Safari and Opera */
            // @ts-ignore
            document.webkitExitFullscreen();
            // @ts-ignore
        } else if (document.msExitFullscreen) {
            /* IE/Edge */
            // @ts-ignore
            document.msExitFullscreen();
        }
    }

    private getUserInfo() {
        const userInfoStorage = this.sessionStorageService.get(constants.USER_INFO_STORAGE_NAME);
        if (userInfoStorage && userInfoStorage.id) {
            this.currentUser = userInfoStorage;
        } else {
            return this.userProvider.getUserInformation()
                .pipe(
                    tap(response => {
                            // save response into browser storage
                            this.sessionStorageService.set(constants.USER_INFO_STORAGE_NAME, response);
                        }
                    ))
                .subscribe((userResponse) => {
                    this.currentUser = userResponse;
                });
        }
        //todo check if something needs to be done related to user login
        // this.openIdConnectService.userLoaded$
        //     .pipe(
        //         take(1),
        //         mergeMap(userLoaded => {
        //             if (userLoaded) {
        //                 return this.configDataService.getUserByToken();
        //             } else {
        //                 return '';
        //             }
        //         }))
        //     .subscribe((userInfo) => {
        //         this.currentUser = userInfo;
        //     }, err => {
        //     });
    }

    private getTranslatedLanguage(): string {
        return this.translatedLanguageService.translatedLanguage;
    }

    private onFullScreenChange() {
        document.addEventListener('fullscreenchange', (event) => {
            if (document.fullscreenElement) {
            } else {
                //Exit fullscreen
                this.isFullScreen = false;
                this.navBarService.toggleFullScreenEvent(this.isFullScreen);
            }
        });
    }
}
