<aside class="left-sidebar">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar">
        <!-- Sidebar navigation-->
        <nav class="sidebar-nav">
            <ul id="sidebarnav" class="in" *ngIf="showSideBarContent">
                <!--Home-->
                <li class="sidebar-item">
                    <a [routerLink]="['dashboard']" routerLinkActive="active">
                        <i class="fas fa-home"></i>
                        <span class="hide-menu d-block" translate="label.home"></span>
                    </a>
                </li>
                <!-- Infrastructure Reservation -->
                <li class="sidebar-item">
                    <a [routerLink]="['infrastructureReservation']" routerLinkActive="active">
                        <i class="fas fa-hospital"></i>
                        <span class="hide-menu d-block" translate="label.infrastructureReservationManagement"></span>
                    </a>
                </li>
            </ul>
        </nav>
        <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
</aside>
