import { constants } from './../constants/constants';
import { ConfigDataService } from './config-data.service';
import { MessagesService } from './messages.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})

export class RefreshPageToastrService {

  constructor(private http: HttpClient,
    private messageService: MessagesService,
    private configDataService: ConfigDataService) { }


  updateAppVersion() {
    this.http.get("./assets/json/appVersion.json").subscribe((res: any) => {
      this.compareStatusOfAppVersion(res);
    }, err => {
      this.messageService.error(err);
    });
  }

  compareStatusOfAppVersion(res: any) {
    let appVersion = this.configDataService.getValueFromLocalStorage(constants.APP_VERSION);
    if (appVersion != null) {
      if (!(appVersion == res.version)) {
        this.messageService.infoAboutVersion(res.version);
      }
    } else {
      this.configDataService.setValueToLocalStorage(constants.APP_VERSION, res.version);
    }
  }
}
