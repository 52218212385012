import * as lodash from 'lodash';
// import * as lodash from 'lodash';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TenantConfigMapper {
  constructor() {
  }

  public autoMapDTOToEntity(dtoObj: any) {

    // entity to return - we don't know if it's an object or an array
    let entity;

    // check type of object
    if (dtoObj instanceof Array) {

      // initialize entity
      entity = [];

      // for (let i = 0; i < dtoObj.length; i++) {
      for (const obj of dtoObj) {
        // recursively call the mapper for each item
        entity.push(this.autoMapDTOToEntity(obj));
      }
    } else if (dtoObj instanceof Object) {
      // seams to be an object
      entity = {};

      // loop over each attribute of the object
      for (const key in dtoObj) {
        if (dtoObj.hasOwnProperty(key)) {
          // change attribute name to lowerCase
          const attrName = lodash.lowerFirst(key);

          // check type of newly found object
          if (dtoObj[key] instanceof Array) {

            // initialize found object to array
            entity[attrName] = [];

            for (let j = 0; j < dtoObj[key].length; j++) {
              // recursively call the mapper for each sub item
              entity[attrName].push(this.autoMapDTOToEntity(dtoObj[key][j]));
            }
          } else if (dtoObj[key] instanceof Object) {
            // seams to be an object
            // recursively call the mapper for the found object
            entity[attrName] = this.autoMapDTOToEntity(dtoObj[key]);
          } else {
            entity[attrName] = dtoObj[key];
          }
        }
      }
    } else {
      // a simple item has been passed just return it
      entity = dtoObj;
    }

    return entity;
  }
}
