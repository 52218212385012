<div class="tag-component-container tags-input">
    <tag-input [disable]="options.isDisabled"
               [displayBy]="options.displayByPropertyName"
               [identifyBy]="options.identifyByPropertyName"
               [ngModelOptions]="{standalone: options.isStandalone}"
               [ngModel]="options.selectedTags"
               [onlyFromAutocomplete]="options.isOnlyFromAutocomplete"
               [maxItems]="options.maxItems"
               [placeholder]="(options.placeholder | translate)"
               [secondaryPlaceholder]="(options.secondaryPlaceholder | translate)"
               (ngModelChange)="selectedItemsOutput($event)">
        <tag-input-dropdown [autocompleteItems]="options.tagList"
                            [displayBy]="options.displayByPropertyName"
                            [identifyBy]="options.identifyByPropertyName"
                            [showDropdownIfEmpty]="options.isShowDropdownIfEmpty"
                            class="w-100">
            <ng-template let-index="index" let-item="item">
                <span class="style-tags">{{ item.name }}</span>
            </ng-template>
        </tag-input-dropdown>
    </tag-input>
</div>

