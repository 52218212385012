import {Inject, Injectable} from '@angular/core';
import {IGenericProvider} from '../../../../data-model/genericProviderInterface';
import {HttpBaseProvider} from '../http-base/http-base.provider';
import {HttpBaseProviderUtils} from '../http-base/http-base.provider.utils';
import {ResourceProviderMapper} from './resource.provider.mapper';
import {Observable} from 'rxjs';
import {ODataQueryObjectType} from '../../data-model/oDataObjectTypes';
import {HttpHeaders} from '@angular/common/http';
import {RequestOptionsType} from '../../data-model/generalTypes';
import {
    ResourceDependentFiltersType,
    ResourceSearchResultDTOType,
    ResourceSearchResultType, ResourceType
} from '../../data-model/resourceTypes';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class ResourceProvider implements IGenericProvider {
    private RESOURCES = 'Resources';
    private environment;

    constructor(private httpBase: HttpBaseProvider,
                private httpBaseUtils: HttpBaseProviderUtils,
                private resourceProviderMapper: ResourceProviderMapper,
                @Inject('environment') environment) {
        this.environment = environment;
    }

    getDataSearching(queryFilter: ResourceDependentFiltersType): Observable<ResourceSearchResultType> {
        const FULL_URL = this.environment.BASE_URL + 'search/GetResources';
        const body = this.getBodyForDataSearching(queryFilter);

        return this.httpBase.post(FULL_URL, body, true)
            .pipe(map((response: ResourceSearchResultDTOType) => {
                const result: ResourceSearchResultType = {} as ResourceSearchResultType;
                result.value = this.resourceProviderMapper.mapFilterDataDTOToEntity(response.value);

                return result;
            }));
    }

    addEntry(item: ResourceType): Observable<{ value: ResourceType }> {
        const FULL_URL = this.environment.BASE_URL + this.RESOURCES;

        return this.httpBase.post(FULL_URL, this.resourceProviderMapper.mapItemToResourceDTO(item), false);
    }

    deleteEntry(id: string): Observable<any> {
        const FULL_URL = this.environment.BASE_URL + this.RESOURCES + '(' + id + ')';

        return this.httpBase.delete(FULL_URL, false);
    }

    getEntityNameForCancelGraph(): string {
        return this.RESOURCES;
    }

    getEntries(filter: ODataQueryObjectType): Observable<{ value: ResourceType[], count?: number }> {
        const query = this.httpBaseUtils.getQuery(filter);
        const FULL_URL = this.environment.BASE_URL + this.RESOURCES + query;

        return this.httpBase.get(FULL_URL, false);
    }

    updateEntry(item: any): Observable<{ value: ResourceType }> {
        const FULL_URL = this.environment.BASE_URL + this.RESOURCES + '(' + item.id + ')';
        const ifMatchHeader: HttpHeaders = new HttpHeaders().append('If-Match', item.etag);
        const requestOptions: RequestOptionsType = {headers: ifMatchHeader};

        return this.httpBase.patch(FULL_URL, this.resourceProviderMapper.mapItemToResourceDTO(item), requestOptions, false);
    }

    private getBodyForDataSearching = (queryFilter: ResourceDependentFiltersType): {} => ({
        searchPhrase: queryFilter.searchPhrase,
        includeAvailabilities: queryFilter.includeAvailabilities,
        onlyProfessionals: queryFilter.onlyProfessionals,
        serviceId: queryFilter.serviceId,
        locationId: queryFilter.locationId,
        coveragePlanId: queryFilter.coveragePlanId,
        areaId: queryFilter.areaId,
        resourceTypeId: queryFilter.resourceTypeId,
        mainAreaId: queryFilter.mainAreaId,
        onlyAssignedToUser: queryFilter.onlyAssignedToUser,
        onlyAssignedToLocationsOfUser: queryFilter.onlyAssignedToLocationsOfUser,
        includeSelfPayer: queryFilter.includeSelfPayer,
        onlyAvailableForWaitList: queryFilter.onlyAvailableForWaitList,
        resourceTypeExclusionList: queryFilter.resourceTypeExclusionList,
        exclusionList: queryFilter.exclusionList
    })
}
