<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<section class="error-page" id="wrapper">
    <div class="error-box">
        <div class="error-body text-center" >
            <div *ngIf="!displayLoginAgainMessage">
                <h1 class="text-info">404</h1>
                <h3 class="text-uppercase" translate="label.pageNotFound"></h3>
            </div>
            <div *ngIf="displayLoginAgainMessage">
                <h3 class="text-uppercase" translate="label.loginAgain"></h3>
            </div>
        </div>
    </div>
</section>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
