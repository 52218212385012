import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {TranslatedLanguageService} from '../../shared/services/translated-language.service';
import {GeneralUtils} from '../../shared/utils/general.utils';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private cookieService: CookieService,
                private route: ActivatedRoute,
                private generalUtils: GeneralUtils,
                private languageService: TranslatedLanguageService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add headers
        request = request.clone({
            withCredentials: true,
            setHeaders:
                {
                    Accept: 'application/json, */*',
                    'X-AppTimezone': moment().utcOffset().toString(),
                    'X-Slots-Debug': 'false',
                    'Accept-Language': this.languageService.getUsedLanguage()
                }
        });

        if (this.generalUtils.hasAccessToken()) {
            request = request.clone({setHeaders: {'X-XSRF-TOKEN': this.generalUtils.getAccessToken()}});
        }

        // set current route in request header
        if (this.route && this.route.snapshot && this.route.snapshot['_routerState']) {
            // request = request.clone({setHeaders: {'X-DisplayRules-Ui': this.route.snapshot['_routerState'].url.toString()}});
            request = request.clone({setHeaders: {'X-DisplayRules-Ui': 'login'}});
        }

        // @ts-ignore
        return next.handle(request);
    }

    readCookie(name) {
        const nameEQ = name + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') { c = c.substring(1, c.length); }
            if (c.indexOf(nameEQ) === 0) { return c.substring(nameEQ.length, c.length); }
        }
        return null;
    }
}
