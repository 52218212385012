import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NavBarService {
    private toggleSideBarSubject$ = new Subject<boolean>();
    toggleSideBar$ = this.toggleSideBarSubject$.asObservable();
    toggleFullScreen$ = new Subject<boolean>();

    constructor() {
    }

    toggleSideBarEvent(toggle: boolean): void {
        // toggleSideBarEvent function is called from NavBar component to pass toggled value, then providers pushes event to subscriber
        this.toggleSideBarSubject$.next(toggle);
    }
    toggleFullScreenEvent(toggle: boolean): void {
        // toggleSideBarEvent function is called from NavBar component to pass toggled value, then providers pushes event to subscriber
        this.toggleFullScreen$.next(toggle);
    }
}
