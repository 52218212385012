import {ErrorHandler, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EnsureModuleLoadedOnceGuard} from './ensure-module-loaded-once.guard';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppInsightsService, ApplicationInsightsModule} from '@markpieszak/ng-application-insights';
import {environment} from '../../environments/environment';

import {CustomErrorHandler} from './interceptors/custom-error-handler';
import {HttpRequestFailInterceptor} from './interceptors/http-request-fail.interceptor';
import {CookieService} from 'ngx-cookie-service';
import {HttpConfigInterceptor} from './interceptors/http-config.interceptor';
import {WebStorageModule} from '@efaps/ngx-store';
import {NgbDateParserFormatter, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {CustomDatepickerI18n} from './services/datepicker-i18n.service';
import {NgbDateCustomParserFormatter} from './services/datepicker-display-format-parser.service';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {HttpRetryInterceptor} from './interceptors/http-retry.interceptor';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    WebStorageModule,
    ApplicationInsightsModule.forRoot({
      instrumentationKey: environment.INSIGHTS_ID
    })
  ],
  exports: [HttpClientModule],
  providers: [AppInsightsService,
    CookieService,
    // custom translation for datePicker
    {
      provide: NgbDatepickerI18n,
      useClass: CustomDatepickerI18n
    },
    // custom display format for datePicker
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateCustomParserFormatter
    },
    // interceptors
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestFailInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRetryInterceptor,
      multi: true
    },
    // error handle
    {
      provide: ErrorHandler, // catch all the unhandled errors and log the error
      useClass: CustomErrorHandler
    },
    // create wizard
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {displayDefaultIndicatorType: false}
    }
  ]
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {// Ensure that CoreModule is only loaded into AppModule
// Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
