<header class="topbar">
    <nav class="navbar top-navbar navbar-expand-md navbar-light">
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- toggle and nav items -->
        <!-- ============================================================== -->
        <ul class="navbar-nav mr-auto navbar-toggle">
            <!-- This is  -->
            <!--<li class="nav-item"><a class="nav-link nav-toggler hidden-md-up waves-effect waves-dark"><i class="ti-menu"></i></a>-->
            <!--</li>-->
            <li class="nav-item"
                (click)="toggleSidebar()"
                [ngClass]="{'disabled':isFullScreen}"><a
                    class="nav-link sidebartoggler hidden-sm-down waves-effect waves-dark"><i
                    class="ti-menu"></i></a></li>
        </ul>

        <div class="navbar-header">
            <a class="navbar-brand" (click)="goToDashboard()">
                <!-- Logo icon -->
                <!--        <img src="../../../assets/images/logo.png" alt="{{'label.dashboard'|translate}}"/>-->
                <img [src]="logoPath" alt="{{'label.dashboard'|translate}}"/>
            </a>
        </div>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <div class="navbar-collapse">
            <ul class="navbar-nav mr-auto">
            </ul>
            <!-- ============================================================== -->
            <!-- User profile and search -->
            <!-- ============================================================== -->
            <ul class="navbar-nav my-lg-0">
                <!-- ============================================================== -->
                <!-- Language -->
                <!-- ============================================================== -->
                <sbase-language (onSelectedLanguageOutput)="onSelectedLang($event)"
                                [initialLanguageCode]='translatedLanguage'
                                *ngIf="!!translatedLanguage">
                </sbase-language>

                <!-- ============================================================== -->
                <!-- Access config -->
                <!-- ============================================================== -->
                <li class="nav-item dropdown"
                    [featureDisplay]="'admin-new-systemConfig'"
                    [activityDisplay]="'SystemConfigurationRead'"
                    *ngIf="systemConfigFeatureAccess && systemConfigActivityDisplay">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark"
                       ngbTooltip="{{'label.systemConfig'| translate}}" [routerLink]="['systemConfig']">
                        <i class="fas fa-cog"></i>
                    </a>
                </li>

                <!-- ============================================================== -->
                <!-- Profile -->
                <!-- ============================================================== -->
                <sbase-user-info [userInfo]="currentUser"></sbase-user-info>
            </ul>
        </div>
    </nav>
</header>
