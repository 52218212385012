import {Injectable} from '@angular/core';
import buildQuery from 'odata-query';
import {ODataQueryObjectType} from '../../data-model/oDataObjectTypes';
import {compare, Operation} from 'fast-json-patch';
import * as lodash from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class HttpBaseProviderUtils {
    constructor() {
    }

    public encodeStringAndRemoveInvalidChars(value: string): string {
        return value;
    }

    public getCustomJsonPatchArray(value: any): any {
        return value;
    }

    public encodeStringForFilterQuery(value: string): string {
        return value;
    }

    public getQuery(value: ODataQueryObjectType) {
        const {top, skip, orderBy, count, filter, expand, select} = value;
        const query = buildQuery({top, skip, orderBy, count, filter, expand, select});
        return query;
    }

    // We have two collections: initial and final
    //  - We first sort all elements from the final collection based on their unique identifiers (undefined values at the end of the array)
    //  - We then take only the unique items (by everything except the unique identifier)
    //  - All elements from final that have id: undefined are new objects that are not on the server yet; we want them to be, so we add them
    //  - The rest of the elements in final must have their id in the initial collection
    //  - If there is an object in the initial collection that doesn't have the id in the final collection then it's an object we remove
    //  - The rest of the objects in the initial collection must have a match in the final collection: these items were edited, so
    //    we either use a replace operation, or a remove and an add operation
    //// Doesn't support replace operations yet
    getJsonPatchForComplexCollections(initialCollectionDTO: any[], finalCollectionDTO: any[],
                                      entityName: string, uniqueIdentifierName = 'Id',
                                      supportsReplace = false): Operation[] {
        const patchData = [];
        // We only take unique values, so even if multiple items are the same (except unique identifier) to the same item in the
        // initial collection we only take one item from the final collection
        // We also sort this collection first, with the items with undefined unique identifier at the end, so the uniqWith takes an item
        // that doesn't have an undefined unique identifier (if it exists)
        //// Check test cases 15-17 for examples
        let actualFinalCollectionDTO = lodash.cloneDeep(finalCollectionDTO);
        actualFinalCollectionDTO = lodash.orderBy(actualFinalCollectionDTO, uniqueIdentifierName, 'asc');
        actualFinalCollectionDTO = lodash.uniqWith(actualFinalCollectionDTO, (item1, item2) => {
            const {[uniqueIdentifierName]: id1, ...rest1} = item1;
            const {[uniqueIdentifierName]: id2, ...rest2} = item2;
            return lodash.isEqual(rest1, rest2);
        });

        // Check each item in the mapped final collection:
        //  - if its unique identifier is still undefined, it means there's no item in the
        //    initial collection that is lodash.isEqual to it, so it's a newly created item, so we use the add operation
        actualFinalCollectionDTO.forEach((item) => {
            if (item[uniqueIdentifierName] === undefined || item[uniqueIdentifierName] === null) {
                // Operation Add on all finalCollectionDTO with unique identifier: undefined or null
                const {[uniqueIdentifierName]: id, ...rest} = item;
                patchData.push({op: 'add', path: '/' + entityName, value: rest});
            }
        });

        // Check each item in the initial collection:
        //  - if it doesn't have the id in the final collection then the user removed the item, so we use the remove operation
        //  - if it does have the id in the final collection then the user edited the item: we either use replace or use remove and add
        initialCollectionDTO.forEach((item) => {
            const finalCollectionItemWithSameUniqueIdentifierAsItem = lodash.find(actualFinalCollectionDTO,
                (searchItem) => searchItem[uniqueIdentifierName] === item[uniqueIdentifierName]);
            if (finalCollectionItemWithSameUniqueIdentifierAsItem === undefined) {
                // Operation Remove on all items in initialCollectionDTO with Id not in finalCollectionDTO
                patchData.push({op: 'remove', path: '/' + entityName, value: {[uniqueIdentifierName]: item[uniqueIdentifierName]}});
            } else {
                // item and finalCollectionItemWithSameUniqueIdentifierAsItem have the same unique identifier: check if they are equal
                // If not equal:
                if (!lodash.isEqual(item, finalCollectionItemWithSameUniqueIdentifierAsItem)) {
                    if (supportsReplace) {
                        // If collection supports replace, we do replaces for each change
                        const finalItem = lodash.cloneDeep(finalCollectionItemWithSameUniqueIdentifierAsItem);
                        // For each attribute in the initial collection item, we check if the attribute exists in the final collection item
                        lodash.forIn(item, (value, key) => {
                            // If it doesn't exist or it's value is undefined, we map it to null
                            // (we basically removed this attribute's value)
                            if (!lodash.has(finalItem, key) || finalItem[key] === undefined || finalItem[key] === null) {
                                finalItem[key] = null;
                            }
                        });
                        // Now we know that all attributes in the initial item exist in the final item as well,
                        // We add a replace operation for each attribute (different than the unique identifier) in the final item
                        lodash.forIn(finalItem, (value, key) => {
                            if (key !== uniqueIdentifierName && finalItem[key] !== item[key]) {
                                patchData.push({
                                    op: 'replace',
                                    path: `/${entityName}/${finalItem[uniqueIdentifierName]}/${key}`,
                                    value: finalItem[key]
                                });
                            }
                        });
                    } else {
                        // If doesn't support replace, we do remove initial, add final
                        patchData.push({op: 'remove', path: '/' + entityName, value: {[uniqueIdentifierName]: item[uniqueIdentifierName]}});
                        const {[uniqueIdentifierName]: id, ...rest} = finalCollectionItemWithSameUniqueIdentifierAsItem;
                        patchData.push({
                            op: 'add',
                            path: '/' + entityName,
                            value: rest
                        });
                    }
                }
            }
        });

        // Return only unique operations, so we don't add the same element twice
        return lodash.uniqWith(patchData, lodash.isEqual);
    }

    public getCustomJsonPatchArrayById(oldArray: any[], newArray: any[], collectionName: string, entityIdName: string = 'Id'): Operation[] {

        // JsonPatch for replace
        const oldItemsToReplaceDTO: any[] = lodash.intersectionBy(oldArray, newArray, entityIdName);
        const newItemsToReplaceDTO: any[] = lodash.intersectionBy(newArray, oldArray, entityIdName);
        const patchDataForReplace: Operation[] = compare(oldItemsToReplaceDTO, newItemsToReplaceDTO);

        // JsonPatch for remove
        const itemsToRemoveDTO: any[] = lodash.differenceBy(oldArray, newArray, entityIdName);
        const patchDataForRemove: Operation[] = compare(itemsToRemoveDTO, []);

        // JsonPatch for add
        const itemsToAddDTO: any[] = lodash.differenceBy(newArray, oldArray, entityIdName);
        const patchDataForAdd: Operation[] = compare([], itemsToAddDTO);

        // full list of JsonPatch
        const patchData: Operation[] = lodash.concat(patchDataForReplace, patchDataForRemove, patchDataForAdd);

        // update standard JsonPatch
        for (const operation of patchData) {
            // check if something was changed in collections
            switch (operation.op) {
                case'replace':
                    operation.path = this.updateOperationPath(operation.path, oldItemsToReplaceDTO, collectionName);
                    break;
                case'add':
                    operation.path = this.updateOperationPath(operation.path, undefined, collectionName);
                    break;
                case'remove':
                    // @ts-ignore
                    operation.value = this.getValuePatchForDelete(operation.path, itemsToRemoveDTO);
                    operation.path = this.updateOperationPath(operation.path, undefined, collectionName);
                    break;
            }
        }

        return patchData;
    }


    private updateOperationPath(operationPath: string, collection: any[], collectionName: string): string {
        // operationPath example: /3/Name
        let newPath = '';
        const index = this.getIndexFromString(operationPath);
        let replaceWith: string;

        if (index > -1) {
            if (collection && collection.length > 0) {
                replaceWith = collectionName + '/' + collection[index].Id;
            } else if (collectionName) {
                replaceWith = collectionName;
            }
        }

        // replace index with id or collection name
        if (index.toString() && replaceWith) {
            newPath = lodash.replace(operationPath, index.toString(), replaceWith);
        } else {
            newPath = operationPath;
        }

        return newPath;
    }


    private getValuePatchForDelete(operationPath: string, oldItemsToReplaceDTO: any[]): { Id: string } {
        let value: { Id: string };
        const index = this.getIndexFromString(operationPath);
        if (index > -1) {
            value = {Id: oldItemsToReplaceDTO[index].Id};
        }
        return value;
    }


    private getIndexFromString(operationPath: string): number {
        let indexValue: number;
        // split string by /
        const splitArray: string[] = operationPath.split('/');

        for (const item of splitArray) {
            // convert from string to number
            indexValue = parseInt(item, 10);
            if (indexValue > -1) {
                return indexValue;
            }
        }
        return undefined;
    }

    public getJsonPatchForCollection(initialArray: any[], finalArray: any[], entityName: string): any[] {
        let objectToRemove = [];
        let objectToAdd = [];
        const collectionPatchData = [];

        // REMOVE SECTION
        objectToRemove = lodash.differenceBy(initialArray, finalArray, 'Id');
        objectToRemove = lodash.uniqBy(objectToRemove, 'Id');

        // ADD SECTION
        if (initialArray.length > 0) {
            objectToAdd = lodash.differenceBy(finalArray, initialArray, 'Id');
        } else {
            objectToAdd = finalArray;
        }

        for (let i = 0; i < objectToRemove.length; i++) {
            collectionPatchData.push({op: 'remove', path: '/' + entityName, value: {Id: objectToRemove[i].Id}});
        }

        for (let j = 0; j < objectToAdd.length; j++) {
            collectionPatchData.push({op: 'add', path: '/' + entityName, value: objectToAdd[j]});
        }

        return collectionPatchData;
    }

    /**
     *
     * @param initialArray - initial array of strings, ex: ['text1', 'text2']
     * @param finalArray - final array of strings, ex: ['text1', 'textAdded', 'text2']
     * @param entityName - The name of the entity which will be used for Pack
     */
    getJsonPatchForStringLists(initialArray: string[], finalArray: string[], entityName: string): Operation[] {
        const replaceOperations = [] as Operation[];
        if (!lodash.isEqual(lodash.sortBy(initialArray), lodash.sortBy(finalArray))) {
            replaceOperations.push({
                op: 'replace',
                path: '/' + entityName,
                value: finalArray
            });
        }
        return replaceOperations;
    }
}
