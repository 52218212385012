<div class="container-fluid overview-screen-md infrastructure-reservation">
    <div class="row h-100 d-flex">
        <!-- ============================================================== -->
        <!-- Center, Resource, Infrastructure Filters, Navigation Calendar -->
        <!-- ============================================================== -->
        <div class="col-md-3 my-1" *ngIf="showFilters" [ngClass]="filterContainerCustomCss"  >
            <div class="filter-buttons">
                <!-- Close Filter -->
                <!-- ============================================================== -->
                <div class="row">
                    <div class="col align-self-end font-weight-bold cursor-pointer">
                        <mat-icon aria-hidden="false" fontIcon="home" (click)="filtersOpened()">
                            <span class="material-symbols-outlined">
                            arrow_back_ios
                        </span></mat-icon>
                    </div>
                </div>
                <!-- Center Filter -->
                <!-- ============================================================== -->
                <div class="row">
                    <div class="col-xl-3 col-lg-12 align-self-center font-weight-bold"
                         [ngClass]="{'has-error': !requestTableFilters?.center?.id}"
                    >
                        <span translate="label.location"></span>
                    </div>
                    <div class="col-xl-9 col-lg-12">
                        <base-filter (selectedItemsOutput)="onSelectedCenterFilter($event)"
                                     [buttonName]="'label.choose'"
                                     [dependentFilters]="mainDependentFilters.location"
                                     [initialFilterValues]="initialCenterValues"
                                     [isMultipleSelectedItem]="false"
                                     [providerInstance]="centerProvider"
                                     [useSelectedValueAsLabel]="true"
                                     [hideSelectedItems]="true"
                                     [ngClass]="{'has-error': !requestTableFilters?.center?.id, 'has-no-value-selected': !requestTableFilters.center}">
                        </base-filter>
                    </div>
                </div>
                <!-- ============================================================== -->
                <!-- End Center Filter -->
                <!-- ============================================================== -->

                <!-- ============================================================== -->
                <!-- Infrastructure Filter -->
                <!-- ============================================================== -->
                <div class="row" [ngClass]="{'cursor-not-allowed': !requestTableFilters?.center?.id}">
                    <div class="col-xl-3 col-lg-12 align-self-center font-weight-bold">
                        <span translate="label.name"></span>
                    </div>

                    <div class="col-xl-9 col-lg-12">
                        <base-filter (selectedItemsOutput)="onSelectedInfrastructureFilter($event)"
                                     [buttonName]="'label.choose'"
                                     [dependentFilters]="mainDependentFilters.infrastructure"
                                     [initialFilterValues]="initialInfrastructureValues"
                                     [isMultipleSelectedItem]="false"
                                     [providerInstance]="resourceProvider"
                                     [hideSelectedItems]="true"
                                     [useSelectedValueAsLabel]="true"
                                     [ngClass]="{'pointer-events-none': !requestTableFilters?.center?.id, 'has-no-value-selected' : !requestTableFilters.infrastructure}">
                        </base-filter>
                    </div>
                </div>
                <!-- ============================================================== -->
                <!-- End Infrastructure Filter -->
                <!-- ============================================================== -->

                <!-- ============================================================== -->
                <!-- Tag Filter -->
                <!-- ============================================================== -->
                <div class="row" [ngClass]="{'cursor-not-allowed': !requestTableFilters?.center?.id}">
                    <div class="col-xl-3 col-lg-12 align-self-center font-weight-bold">
                        <span translate="label.tags"></span>
                    </div>

                    <div class="col-xl-9 col-lg-12">
                        <base-tag-component
                                [options]="tagOptions"
                                (onChangeTagsOutput)="onChangeTags($event)">
                        </base-tag-component>
                    </div>
                </div>
                <!-- ============================================================== -->
                <!-- End Tag Filter -->
                <!-- ============================================================== -->

                <!-- ============================================================== -->
                <!-- Professional Filter -->
                <!-- ============================================================== -->
                <div class="row cursor-not-allowed" [ngClass]="{'cursor-not-allowed': !requestTableFilters?.center?.id}">
                    <div class="col-xl-3 col-lg-12 align-self-center font-weight-bold">
                        <span translate="label.professional"></span>
                    </div>

                    <div  class="col-xl-9 col-lg-12" >
                        <base-filter (selectedItemsOutput)="onSelectedResourceFilter($event)"
                                     [buttonName]="'label.choose'"
                                     [dependentFilters]="mainDependentFilters.resource"
                                     [initialFilterValues]="initialResourceValues"
                                     [isMultipleSelectedItem]="false"
                                     [providerInstance]="resourceProvider"
                                     [useSelectedValueAsLabel]="true"
                                     [hideSelectedItems]="true"
                                     [ngClass]="{'pointer-events-none': !requestTableFilters?.center?.id, 'has-no-value-selected' : !requestTableFilters.resource}">
                        </base-filter>
                    </div>
                </div>
                <!-- ============================================================== -->
                <!-- End Professional Filter -->
                <!-- ============================================================== -->

                <!-- ============================================================== -->
                <!-- Date Picker -->
                <!-- ============================================================== -->
                <div [ngStyle]="{'cursor': !showCalendars ? 'not-allowed' : ''}">
                    <ngb-datepicker name="dp"
                                    ngbDatepicker
                                    [(ngModel)]="requestTableFilters.currentDate"
                                    [disabled]="!showCalendars"
                                    [dayTemplate]="customDay"
                                    #datepicker>
                    </ngb-datepicker>

                    <ng-template #customDay let-date="date" let-currentMonth="currentMonth" let-selected="selected"
                                 let-disabled="disabled" let-focused="focused">
                        <div class="custom-date-day btn-light" [ngClass]="infrastructureReservationUtils.getTodayCssClass(date)"
                             [class.bg-primary]="selected"
                             [class.hidden]="date.month !== currentMonth"
                             [class.focused]="focused"
                             [class.text-muted]="date.month !== currentMonth"
                             (click)="onSelectedDateFilter(date)">
                            {{ date.day }}
                            <div class="conflicts-item" *ngFor="let conflictInstanceItem of displayedGroupedConflicts">
                                <span
                                        [ngClass]="{'my-custom-dot': infrastructureReservationUtils.hasDateConflicts(groupedConflictsArray,conflictInstanceItem.date, date)}">
                                </span>
                            </div>
                        </div>
                    </ng-template>
                </div>
                <!-- ============================================================== -->
                <!-- End  Date Picker -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Conflicts -->
                <!-- ============================================================== -->
                <div class="conflicts-container" *ngIf="conflictsArray.length > 0">
                    <span class="conflicts-title" translate="label.conflicts"></span><span
                        class="conflicts-title">:</span>
                    <div class="separator"></div>
                    <div class="conflicts-list">
                        <div class="conflicts-item" *ngFor="let conflictInstanceItem of conflictsArray">
                            <div *ngIf="!conflictInstanceItem.isSuccessful">
                                <span>{{conflictInstanceItem.formattedDate}}:&nbsp;{{conflictInstanceItem.formattedTimeInterval}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ============================================================== -->
                <!-- End Conflicts -->
                <!-- ============================================================== -->
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- Filters Bar on Close -->
        <!-- ============================================================== -->
        <div class="animate__animated animate__fadeInLeft closeFilterContainer"  *ngIf="!showFilters">
            <div class="pl-3 pt-3">
                <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="home" (click)="filtersOpened()">
                            <span class="material-symbols-outlined">
                            arrow_forward_ios
                        </span></mat-icon>
            </div>
        </div>

        <!-- ============================================================== -->
        <!-- End Center, Resource, Infrastructure Filters and Navigation Calendar -->
        <!-- ============================================================== -->

        <!-- ============================================================== -->
        <!-- Full Calendar -->
        <!-- ============================================================== -->
        <div  [ngClass]="calendarContainerCustomCss">
            <div class="infrastructure-reservation-calendar h-100"
                 *ngIf="showCalendars">
                <ngx-ui-loader class="local-spinner"
                               [hasProgressBar]="false"
                               [loaderId]="calendarSpinnerId">
                </ngx-ui-loader>
                <full-calendar class="resources-calendar"
                               #resourceCalendar
                               [options]="resourceCalendarOptions">
                </full-calendar>
                <full-calendar class="infrastructures-calendar"
                               #infrastructuresCalendar
                               [options]="infrastructuresCalendarOptions">
                </full-calendar>
            </div>

            <!-- Display this message if the mandatory filters do not fill -->
            <!-- ============================================================== -->
            <div class="h2 error-message" *ngIf="!infrastructureReservationUtils.areTableFiltersValid(requestTableFilters)">
                <span translate="label.error.invalidFiltersOnInfrastructureReservation"></span></div>
            <!-- ============================================================== -->
            <!-- End Display this message if the mandatory filters do not fill -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Full Calendar -->
        <!-- ============================================================== -->
    </div>
</div>

