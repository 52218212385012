// module for class - directive , pipes
//import where ever its required

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FeatureDisplayDirective} from './feature-display.directive';
@NgModule({
  declarations: [FeatureDisplayDirective],
  imports: [
    CommonModule
  ],
  exports: [FeatureDisplayDirective]
})
export class DirectiveModule {
}
