import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TagsType} from '../../data-model/tagTypes';
import {TagComponentOptionsType} from './tagTypes';

@AutoUnsubscribe()
@Component({
    selector: 'base-tag-component',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit, OnDestroy {
    @Input() options: TagComponentOptionsType;
    @Output() onChangeTagsOutput: EventEmitter<TagsType[]> = new EventEmitter<TagsType[]>();

    constructor() {
    }

    ngOnInit() {
        this.setDefaultValues();
        this.checkRequiredInputs();
    }

    ngOnDestroy(): void {
    }

    selectedItemsOutput(tags: TagsType[]) {
        this.options.selectedTags = tags;
        this.onChangeTagsOutput.emit(tags);
    }

    private setDefaultValues() {
        if (this.options.isOnlyFromAutocomplete === undefined) {
            this.options.isOnlyFromAutocomplete = true;
        }
        if (this.options.isDisabled === undefined) {
            this.options.isDisabled = false;
        }
        if (this.options.isShowDropdownIfEmpty === undefined) {
            this.options.isShowDropdownIfEmpty = true;
        }
    }

    private checkRequiredInputs() {
        if (this.options.displayByPropertyName === undefined) {
            throw new Error('displayByPropertyName is required');
        }
        if (this.options.identifyByPropertyName === undefined) {
            throw new Error('identifyByPropertyName is required');
        }
        if (this.options.selectedTags === undefined) {
            throw new Error('selectedTags is required');
        }
        if (this.options.tagList === undefined) {
            throw new Error('tagList is required');
        }
    }
}
