import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'hourFromMinutes'
})
export class HourFromMinutesPipe implements PipeTransform {

  transform(minutes: number): string {
    return moment.parseZone().startOf('day').add(minutes, 'minutes').format('HH:mm');
  }

}
