import {FullCalendarModule} from '@fullcalendar/angular';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {NgbActiveModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {MatCheckboxModule} from '@angular/material/checkbox';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import timeGridPlugin from '@fullcalendar/timegrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import listPlugin from '@fullcalendar/list'; // a plugin
import resourceTimeLinePlugin from '@fullcalendar/resource-timeline'; // a plugin
import {CreateReservationModalComponent} from './create-reservation-modal/create-reservation-modal.component';
import {InfrastructureReservationComponent} from './infrastructure-reservation.component';
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatIconModule} from '@angular/material/icon';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
    dayGridPlugin,
    timeGridPlugin,
    listPlugin,
    interactionPlugin,
    resourceTimeLinePlugin
]);

@NgModule({
    declarations: [InfrastructureReservationComponent, CreateReservationModalComponent],
    imports: [
        CommonModule,
        SharedModule,
        BrowserModule,
        FormsModule,
        NgbModule,
        FullCalendarModule,
        NgxMaterialTimepickerModule,
        MatCheckboxModule,
        MatSidenavModule,
        MatIconModule
    ],
    providers: [NgbActiveModal],
    exports: [CreateReservationModalComponent],
    bootstrap: [CreateReservationModalComponent]
})
export class InfrastructureReservationModule {
}
