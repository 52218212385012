import {Injectable} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DateTimeUtils} from '../../shared/utils/dateTime.utils';
import * as moment from 'moment';
import {
    BaseReservationTimeSlotType,
    CalendarResourceType,
    CenterOverviewFiltersType, CenterOverviewItemType,
    ConflictInstancesType,
    ReservationInstanceType, ResourceTimeSlotsFiltersType, ResourceTimeSlotsType,
    InfrastructureReservationFilterType
} from '../../data-model/infrastructure-reservation.types';
import {infrastructureReservationConstants} from './infrastructure-reservation.constants';
import {CalendarOptions, EventContentArg} from '@fullcalendar/angular';
import {constants} from '../../shared/constants/constants';
import * as lodash from 'lodash';
import {IntervalType} from '../../shared/base-modules/data-model/intervalTypes';
import {LocationDependentFiltersType} from '../../shared/base-modules/data-model/centerTypes';
import {ResourceDependentFiltersType, ResourceSearchType} from '../../shared/base-modules/data-model/resourceTypes';
import {TagComponentOptionsType} from '../../shared/base-modules/components/tag/tagTypes';
import {ResourceTypeType} from '../../shared/base-modules/data-model/resourceTypeTypes';
import {DateMarker} from '@fullcalendar/core';

@Injectable({
    providedIn: 'root'
})
export class InfrastructureReservationUtils {
    constructor(private dateTimeUtils: DateTimeUtils) {
    }

    getInitialTableFilters(): InfrastructureReservationFilterType {
        const filters: InfrastructureReservationFilterType = {} as InfrastructureReservationFilterType;
        const currentDay = new Date();
        filters.center = null;
        filters.resource = null;
        filters.infrastructure = null;
        filters.currentDate = this.dateTimeUtils.convertDateInNgbDateStruct(currentDay);
        filters.viewType = infrastructureReservationConstants.DAILY;
        filters.startDate = this.getStartDate(filters.currentDate, filters.viewType);
        filters.endDate = this.getEndDate(filters.currentDate, filters.viewType);
        filters.tags = [];

        return filters;
    }

    getTodayCssClass(date: NgbDateStruct): string {
        const currentDate = moment().format('YYYY-MM-DD');
        const formattedDateCalendar: string = moment(this.dateTimeUtils.getNgbDateWithoutOneMonth(date)).format('YYYY-MM-DD');
        if (currentDate === formattedDateCalendar) {
            return 'label-current-date';
        } else {
            return '';
        }
    }

    getStartDate(date: NgbDateStruct, view: string): NgbDateStruct {
        let startDate;
        if (view === 'weekly') {
            const momentStartDate = moment({year: date.year, month: date.month - 1, day: date.day}).startOf('isoWeek');
            startDate = {
                year: momentStartDate.year(),
                month: momentStartDate.month() + 1,
                day: momentStartDate.date()
            };
        } else {
            const momentStartDate = moment({year: date.year, month: date.month - 1, day: date.day}).startOf('day');
            startDate = {
                year: momentStartDate.year(),
                month: momentStartDate.month() + 1,
                day: momentStartDate.date()
            };
        }

        return startDate;
    }

    getEndDate(date: NgbDateStruct, view: string): NgbDateStruct {
        let endDate;
        if (view === 'weekly') {
            // wee need to add one day because the time will be 00:00
            const momentEndDate = moment(moment(this.dateTimeUtils.getNgbDateWithoutOneMonth(date)).endOf('isoWeek'));
            endDate = {
                year: momentEndDate.year(),
                month: momentEndDate.month() + 1,
                day: momentEndDate.date()
            };
        } else {
            const momentEndDate = moment(this.dateTimeUtils.getNgbDateWithoutOneMonth(date)).endOf('day');
            endDate = {
                year: momentEndDate.year(),
                month: momentEndDate.month() + 1,
                day: momentEndDate.date()
            };
        }

        return endDate;
    }

    getConflictDateList(conflicts: ReservationInstanceType[]): ConflictInstancesType[] {
        const conflictsArray: ConflictInstancesType[] = [];

        if (conflicts && conflicts.length > 0) {
            for (const conflict of conflicts) {
                conflictsArray.push(this.getConflictItem(conflict));
            }
        }

        return conflictsArray;
    }

    getConflictItem(conflict: ReservationInstanceType): ConflictInstancesType {
        const conflictItem: ConflictInstancesType = {} as ConflictInstancesType;

        conflictItem.reservationId = conflict.reservationId;
        conflictItem.date = conflict.date;
        conflictItem.formattedDate = moment(conflict.date).format('YYYY-MM-DD');
        conflictItem.hourFrom = conflict.hourFrom;
        conflictItem.hourTo = conflict.hourTo;
        conflictItem.formattedTimeInterval = this.dateTimeUtils.getStringHourFromMinutes(conflictItem.hourFrom) + '-' +
            this.dateTimeUtils.getStringHourFromMinutes(conflictItem.hourTo);
        conflictItem.isSuccessful = conflict.isSuccessful;
        conflictItem.masterId = conflict.masterId;
        conflictItem.conflictResourceName = conflict.conflictResourceName;

        return conflictItem;
    }

    areTableFiltersValid(tableFilters: InfrastructureReservationFilterType): boolean {
        return !!(tableFilters?.center?.id && tableFilters.startDate && tableFilters.endDate);
    }

    getGeneralCalendarOptions(): CalendarOptions {
        return {
            resourceAreaWidth: '15%',
            dragRevertDuration: 500, //Time it takes for an event to revert to its original position after an unsuccessful drag.
            aspectRatio: 1.5, // width-to-height aspect ratio of the calendar. default: 1.35
            contentHeight: 'auto', // height of the view area of the calendar.
            height: 'auto', //height of the entire calendar, including header and footer.
            initialView: 'resourceTimelineDay', //The initial view when the calendar loads.
            duration: {days: 1}, //important//The exact duration of a custom view.
            slotLabelFormat: {weekday: 'long', day: 'numeric', month: 'long', omitCommas: true},
            // tslint:disable-next-line:max-line-length
            droppable: true, //Determines if external draggable elements or events from other calendars can be dropped onto the calendar. default: false
            editable: true, //Determines whether the events on the calendar can be modified. default: false
            eventTimeFormat: {
                //Determines the time-text that will be displayed on each event.
                hour: '2-digit',
                minute: '2-digit'
                // meridian: false
            },
            slotDuration: '00:30:00', // important The frequency for displaying time slots.
            displayEventTime: false
        } as CalendarOptions;
    }

    getSvgPicture(status: string, isExpiredEvent: boolean): string {
        if (status === constants.BLOCKED || isExpiredEvent) {
            return '<span class="status-icon status-blocked-icon">' +
                '<svg viewBox="0 0 620 620" height="15" width="15">' +
                '    <path d="M436.3,75.7C388,27.401,324.101,0,256,0C115.343,0,0,115.116,0,256c0,140.958,115.075,256,256,256\n' +
                '\t\t\tc140.306,0,256-114.589,256-256C512,187.899,484.6,123.999,436.3,75.7z M256,451c-107.786,0-195-86.985-195-195\n' +
                '\t\t\tc0-42.001,13.2-81.901,37.5-114.901l272.401,272.1C337.899,437.8,298.001,451,256,451z M413.2,370.899L141.099,98.5\n' +
                '\t\t\tC174.101,74.2,213.999,61,256,61c107.789,0,195,86.985,195,195C451,297.999,437.8,337.899,413.2,370.899z"/>\n' +
                '</svg></span>';
        }
        return '';
    }

    getResourceCalendarHeaderOption(): { left: string; center: string; right: string; } {
        return {
            left: '',
            center: 'title',
            right: 'customPreviousButton,customNextButton customTodayButton customRefreshButton'
        };
    }

    getCenterOverviewRequestFilter(filter: InfrastructureReservationFilterType): CenterOverviewFiltersType {
        const requestFilter: CenterOverviewFiltersType = {} as CenterOverviewFiltersType;

        requestFilter.centerId = filter.center.id;
        requestFilter.dateFrom = moment(this.dateTimeUtils.getNgbDateWithoutOneMonth(filter.startDate)).format();
        requestFilter.dateTo = moment(this.dateTimeUtils.getNgbDateWithoutOneMonth(filter.endDate)).format();
        requestFilter.tags = filter.tags.length > 0 ? lodash.map(filter.tags, 'id') : [];
        requestFilter.resourceId = filter.resource ? filter.resource.id : null;
        requestFilter.infrastructureId = filter.infrastructure ? filter.infrastructure.id : null;

        return requestFilter;
    }

    getResourceTimeSlotsRequestFilter(filter: InfrastructureReservationFilterType): ResourceTimeSlotsFiltersType {
        const requestFilter: ResourceTimeSlotsFiltersType = {} as ResourceTimeSlotsFiltersType;

        requestFilter.centerId = filter.center.id;
        requestFilter.resourceId = filter.resource ? filter.resource.id : undefined;
        requestFilter.dateFrom = moment(this.dateTimeUtils.getNgbDateWithoutOneMonth(filter.startDate)).format();
        requestFilter.dateTo = moment(this.dateTimeUtils.getNgbDateWithoutOneMonth(filter.endDate)).format();

        return requestFilter;
    }

    getResourcesForInfrastructuresCalendar(response: CenterOverviewItemType[]): CalendarResourceType[] {
        const infrastructures: CalendarResourceType[] = [];
        for (const item of response) {
            infrastructures.push(
                {
                    id: item.infrastructureId,
                    title: item.name,
                    isResourceHaveReservation: item.resourceHaveReservations
                });
        }

        return infrastructures;
    }

    getResourcesForResourceEvents(response: ResourceTimeSlotsType[]): CalendarResourceType[] {
        const resources: CalendarResourceType[] = [];
        for (const item of response) {
            for (const timeSlot of item.resourceTimeSlots) {
                resources.push(
                    {
                        id: timeSlot.resourceId,
                        title: ' '
                    });
            }
        }

        return lodash.uniqBy(resources, 'id');
    }

    getInfrastructuresCalendarEvents(response: CenterOverviewItemType[]): any[] {
        const events: any[] = [];

        for (const item of response) {
            for (const date of item.dates) {
                for (const timeSlot of date.timeSlots) {
                    const event: any = {};
                    const isSelectedResource = (timeSlot.resourceId && timeSlot.resourceName);
                    const hourFromDate = this.dateTimeUtils.getStringHourFromMinutes(timeSlot.hourFrom);
                    const hourToDate = this.dateTimeUtils.getStringHourFromMinutes(timeSlot.hourTo);
                    event.start = this.dateTimeUtils.getDateWithAddedTime(date.dateTimeOffset, hourFromDate); //date will be in PC offset
                    event.end = this.dateTimeUtils.getDateWithAddedTime(date.dateTimeOffset, hourToDate); //date will be in PC offset
                    event.editable = false;
                    event.resourceEditable = isSelectedResource;
                    event.title = timeSlot.resourceName;
                    event.resourceId = item.infrastructureId;
                    event.status = constants.RESERVED;
                    event.classNames = ['custom-event-style', event.status + '-style'];
                    if (isSelectedResource) {
                        event.classNames.push('current-resource');
                    }

                    //extended params
                    event.eventDate = date.dateTimeOffset;
                    event.hourFrom = timeSlot.hourFrom;
                    event.hourTo = timeSlot.hourTo;
                    event.hourFromDate = this.dateTimeUtils.getStringHourFromMinutes(timeSlot.hourFrom);
                    event.hourToDate = this.dateTimeUtils.getStringHourFromMinutes(timeSlot.hourTo);
                    event.resource = {};
                    event.resource.id = timeSlot.resourceId;
                    event.resource.type = timeSlot.resourceType;
                    event.resource.name = timeSlot.resourceName;
                    event.reservationId = timeSlot.reservationId;
                    event.modifiedOn = timeSlot.modifiedOn;
                    event.modifiedBy = timeSlot.modifiedBy;
                    event.isPartOfSeries = timeSlot.isPartOfSeries;
                    events.push(event);
                }
                //blocked time slots
                for (const timeSlot of date.exclusionTimeSlots) {
                    const hourFromDate = this.dateTimeUtils.getStringHourFromMinutes(timeSlot.hourFrom);
                    const hourToDate = this.dateTimeUtils.getStringHourFromMinutes(timeSlot.hourTo);
                    const blockedEvent: any = {};
                    blockedEvent.start = this.dateTimeUtils.getDateWithAddedTime(date.dateTimeOffset, hourFromDate); //date will be in PC offset
                    blockedEvent.end = this.dateTimeUtils.getDateWithAddedTime(date.dateTimeOffset, hourToDate); //date will be in PC offset
                    blockedEvent.title = '';
                    blockedEvent.display = 'background';
                    blockedEvent.editable = false;
                    blockedEvent.resourceEditable = false;
                    blockedEvent.overlap = false;
                    blockedEvent.resourceId = item.infrastructureId;
                    blockedEvent.status = constants.BLOCKED; //extended parameter
                    blockedEvent.classNames = ['custom-event-style', blockedEvent.status + '-style', 'remove-click-event'];
                    if (timeSlot.hourTo - timeSlot.hourFrom === 1440) {
                        blockedEvent.allDay = true;
                        blockedEvent.classNames.push('blocked-whole-day');
                    }
                    if (timeSlot.hourTo - timeSlot.hourFrom < 1440) {
                        if (timeSlot.hourTo === 1440) {
                            blockedEvent.end = this.dateTimeUtils.getDateWithAddedTime(date.dateTimeOffset,
                                infrastructureReservationConstants.SLOT_MAX_TIME);
                        }
                        blockedEvent.classNames.push('blocked-range');
                    }
                    //extended parameters
                    blockedEvent.hourFrom = timeSlot.hourFrom;
                    blockedEvent.hourTo = timeSlot.hourTo;
                    blockedEvent.hourFromDate = this.dateTimeUtils.getStringHourFromMinutes(timeSlot.hourFrom);
                    blockedEvent.hourToDate = this.dateTimeUtils.getStringHourFromMinutes(timeSlot.hourTo);
                    events.push(blockedEvent);
                }
            }
        }

        return events;
    }

    getResourceCalendarEvents(response: ResourceTimeSlotsType[]): any[] {
        const events: any[] = [];

        for (const item of response) {
            for (const timeSlot of item.resourceTimeSlots) {
                const event: any = {};
                const hourFromDate = this.dateTimeUtils.getStringHourFromMinutes(timeSlot.hourFrom);
                const hourToDate = this.dateTimeUtils.getStringHourFromMinutes(timeSlot.hourTo);
                event.start = this.dateTimeUtils.getDateWithAddedTime(item.date, hourFromDate); //date will be in PC offset
                event.end = this.dateTimeUtils.getDateWithAddedTime(item.date, hourToDate); //date will be in PC offset
                event.resourceId = timeSlot.resourceId;
                event.editable = false;
                event.classNames = ['custom-event-style', timeSlot.status + '-style'];
                event.resourceEditable = (timeSlot.status === constants.TO_BE_ASSIGNED);
                event.title = timeSlot.resourceName + ' ' + hourFromDate + ' - ' + hourToDate; // resource 1 to be change
                event.allDay = false;
                if (!event.resourceEditable) {
                    event.classNames.push('remove-click-event');
                }
                //extended params
                event.eventDate = item.date;
                event.hourFrom = timeSlot.hourFrom;
                event.hourTo = timeSlot.hourTo;
                event.hourFromDate = hourFromDate;
                event.hourToDate = hourToDate;
                event.status = timeSlot.status;
                event.resource = {};
                event.resource.name = timeSlot.resourceName;
                event.resource.id = timeSlot.resourceId;
                event.timeSlotId = timeSlot.timeSlotId;
                event.availabilityId = timeSlot.availabilityId;
                events.push(event);
            }
        }
        return events;
    }

    haveMoreAvailableInfrastructures(availableInfrastructures): boolean {
        return availableInfrastructures > 50;
    }

    getAllTimeSlotsArray(calendarTimeSlots: BaseReservationTimeSlotType[], resourceTimeSlots: BaseReservationTimeSlotType): number[] {
        let allTimeSlotsArray = [];
        for (const timeSlot of calendarTimeSlots) {
            allTimeSlotsArray.push(timeSlot.hourFrom);
            allTimeSlotsArray.push(timeSlot.hourTo);
        }

        allTimeSlotsArray.push(resourceTimeSlots.hourFrom);
        allTimeSlotsArray.push(resourceTimeSlots.hourTo);

        allTimeSlotsArray = lodash.orderBy(allTimeSlotsArray);
        allTimeSlotsArray = lodash.uniq(allTimeSlotsArray);

        return allTimeSlotsArray;
    }

    checkIfTimeSlotIsOverlapping(resourceTimeSlot: IntervalType, calendarTimeSlot: IntervalType): boolean {
        // return (resourceTimeSlot.hourFrom >= calendarTimeSlot.hourFrom || (resourceTimeSlot.hourFrom >= calendarTimeSlot.hourFrom &&
        // resourceTimeSlot.hourFrom < calendarTimeSlot.hourTo)) || (re-sourceTimeSlot.hourTo > calendarTimeSlot.hourFrom ||
        // (resourceTimeSlot.hourTo > calendarTimeSlot.hourFrom && re-sourceTimeSlot.hourTo <= calendarTimeSlot.hourTo));
        return ((resourceTimeSlot.hourFrom >= calendarTimeSlot.hourFrom && resourceTimeSlot.hourFrom < calendarTimeSlot.hourTo) ||
                (resourceTimeSlot.hourTo > calendarTimeSlot.hourFrom && resourceTimeSlot.hourTo < calendarTimeSlot.hourTo)) ||
            (resourceTimeSlot.hourFrom <= calendarTimeSlot.hourFrom && resourceTimeSlot.hourTo >= calendarTimeSlot.hourTo);
    }

    isSelectedDateValid(dateFrom, dateTo): boolean {
        return moment(dateTo).isAfter(moment(dateFrom)) &&
            moment(dateFrom).diff(moment(), 'days') >= 0 &&
            moment(dateTo).diff(moment(), 'days') >= 0;
    }

    isDateDiffLessThanYear(dateFrom, dateTo): boolean {
       return moment(dateTo).diff(dateFrom, 'years') < 1;
    }

    getLocationDependentFilters = (): LocationDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        onlyAssignedToUser: true,
        resourceId: null,
        serviceId: null,
        areaId: null,
    })

    getInfrastructureDependentFilters = (): ResourceDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: false,
        onlyProfessionals: false,
        serviceId: null,
        locationId: null,
        coveragePlanId: null,
        areaId: null,
        onlyAssignedToUser: true,
        onlyAssignedToLocationsOfUser: true,
        resourceTypeId: null,
        includeSelfPayer: false,
        onlyAvailableForWaitList: false,
        mainAreaId: null,
        exclusionList: [],
        resourceTypeExclusionList: []
    })

    getResourceDependentFilters = (): ResourceDependentFiltersType => ({
        searchPhrase: '',
        includeAvailabilities: true,
        onlyProfessionals: false,
        serviceId: null,
        locationId: null,
        coveragePlanId: null,
        areaId: null,
        onlyAssignedToUser: false,
        onlyAssignedToLocationsOfUser: false,
        resourceTypeId: null,
        includeSelfPayer: false,
        onlyAvailableForWaitList: false,
        mainAreaId: null,
        resourceTypeExclusionList: []
    })

    getTagOptionsFilter = (): TagComponentOptionsType => ({
        displayByPropertyName: 'name',
        identifyByPropertyName: 'id',
        placeholder: 'label.choose',
        secondaryPlaceholder: 'label.choose',
        isStandalone: true,
        isDisabled: false,
        isOnlyFromAutocomplete: true,
        isShowDropdownIfEmpty: true,
        appendToBody: false,
        maxItems: 5,
        tagList: [],
        selectedTags: []
    })

    getIdOfResourceTypeInfrastructure(resourceTypes: ResourceTypeType[]): string {
        const resourceType = lodash.find(resourceTypes, {isInfrastructure: true});
        return resourceType ? resourceType.id : undefined;
    }

    getInformationForModal(event: any, date: DateMarker | string, selectedResourceId: string): any {
        const modalData = {} as any;

        modalData.resourceId = event._def.extendedProps.resource.id;
        modalData.timeSlotId = event._def.extendedProps.timeSlotId;
        modalData.infrastructureId = event._def.resourceIds[0];
        modalData.startDate = date;
        modalData.timeSlot = {};
        modalData.timeSlot.hourFrom = event._def.extendedProps.hourFrom;
        modalData.timeSlot.hourTo = event._def.extendedProps.hourTo;
        modalData.reservationId = event._def.extendedProps.reservationId;
        modalData.modifiedBy = event._def.extendedProps.modifiedBy;
        modalData.modifiedOn = event._def.extendedProps.modifiedOn;
        modalData.isEventEditable = ( modalData.resourceId === selectedResourceId);
        modalData.isPartOfSeries = event._def.extendedProps.isPartOfSeries;

        return modalData;
    }

    getExistingEventsIntervals(draggedEvent, events): any[] {
        const slotsIntervals = events
            .filter((event) => {
                return (moment(draggedEvent._instance.range.start).startOf('day').isSame(moment(event._instance.range.start).startOf('day'))
                    && draggedEvent._def.extendedProps.reservationId !== event._def.extendedProps.reservationId);
                // && draggedEvent._def.defId !== event._def.defId);
            })
            .map((event) => {
                return {
                    hourFrom: event._def.extendedProps.hourFrom,
                    hourFromTime: this.dateTimeUtils.getStringHourFromMinutes(event._def.extendedProps.hourFrom),
                    hourTo: event._def.extendedProps.hourTo,
                    hourToTime: this.dateTimeUtils.getStringHourFromMinutes(event._def.extendedProps.hourTo)
                };
            });
        return slotsIntervals ? slotsIntervals : [];
    }

    addCustomTimelineEventsClass(): void {
        // add the custom styles for the time slots container (it is higher than the moment when the events are created)
        const element = document.getElementsByClassName('fc-timeline-events');
        for (let i = 0; i < element.length; i++) {
            element[i].classList.add('custom-timeline-events');
        }
    }

    addCustomTimelineEventHarness(): void {
        // add the custom styles for the time slot container (it is higher than the moment when the events are created)
        const element = document.getElementsByClassName('fc-timeline-event-harness');
        for (let i = 0; i < element.length; i++) {
            element[i].classList.add('custom-timeline-event-harness');
        }
    }

    addTooltipForInfrastructureEvent(info: EventContentArg & { el: HTMLElement }): void {
        // get text element
        const infrastructureNameEl: any = info.el.querySelector('.fc-event-main');

        // add tooltip
        if (infrastructureNameEl) {
            infrastructureNameEl.setAttribute('title',
                info.event._def.title + ' ' + info.event._def.extendedProps.hourFromDate + ' - ' + info.event._def.extendedProps.hourToDate);
        }
    }

    addTooltipForResourceEvent(info: EventContentArg & { el: HTMLElement }): void {
        // get text element
        const infrastructureNameEl: any = info.el.querySelector('.fc-event-main');

        // add tooltip
        if (infrastructureNameEl) {
            infrastructureNameEl.setAttribute('title', info.event._def.title);
        }
    }

    hasDateConflicts(groupedConflictsArray: { [key: string]: ConflictInstancesType[] }, dateWithConflicts: string,
                     calendarDate: NgbDateStruct): boolean {
        const formattedDate: string = moment(dateWithConflicts).format('YYYY-MM-DD');
        const formattedDateCalendar: string = moment(this.dateTimeUtils.getNgbDateWithoutOneMonth(calendarDate)).format('YYYY-MM-DD');
        if (formattedDate === formattedDateCalendar) {
            if (groupedConflictsArray[formattedDate] && !groupedConflictsArray[formattedDate][0].isSuccessful) {
                return true;
            }
        } else {
            return false;
        }
    }

    isEventInPast(eventDate: string, eventHour: number): boolean {
        const now = moment.utc();
        const eventFullDate = moment(eventDate).add(eventHour, 'minutes').utc();

        return moment(eventFullDate).isBefore(now);
    }

    isExpiredEventFromResourceCalendar(eventEnd: string): boolean {
        const now = moment.utc();
        const event = moment(eventEnd).utc();

        return moment(event).isBefore(now);
    }
}
