import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe extends DatePipe implements PipeTransform {

  transform(date: any): any {
    // @ts-ignore
    return super.transform(date, moment.localeData().longDateFormat('dateTime'), 'UTC');
  }

}
