import {ServiceProviderMapper} from '../service/service.provider.mapper';
import {Injectable} from '@angular/core';
import * as lodash from 'lodash';
import {
    ResourceDTOType,
    ResourceSearchDTOType,
    ResourceSearchType,
    ResourceType
} from '../../data-model/resourceTypes';
import {GeneralProviderMapper} from '../generalProviderMapper';

@Injectable({
    providedIn: 'root'
})
export class ResourceProviderMapper {
    constructor(private generalProviderMapper: GeneralProviderMapper,
                private serviceProviderMapper: ServiceProviderMapper) {
    }

    public mapResourcesDTOToEntity(resourcesDTO: ResourceDTOType[]): ResourceType[] {
        const resources: ResourceType[] = [];

        for (const value of resourcesDTO) {
            resources.push(this.mapResourceDTOToEntity(value));
        }

        return resources;
    }

    public mapResourceDTOToEntity(resourceDTO: ResourceDTOType): ResourceType {
        const resource: ResourceType = {} as ResourceType;

        resource.name = resourceDTO.Name;
        resource.priority = resourceDTO.Priority;
        resource.resourceTypeId = resourceDTO.ResourceTypeId;
        resource.isProfessional = resourceDTO.IsProfessional;
        resource.shortId = resourceDTO.ShortId;
        resource.timeSlotsJSON = resourceDTO.TimeSlotsJSON;
        resource.timeSlotsCount = resourceDTO.TimeSlotsCount;
        resource.excludeFromWaitList = resourceDTO.ExcludeFromWaitList;
        resource.id = resourceDTO.Id;

        if (resourceDTO.Services) {
            resource.services = this.serviceProviderMapper.mapServicesDTOToEntity(resourceDTO.Services);
        }

        return resource;
    }

    public mapItemToResourceDTO(resourceData: ResourceType): ResourceDTOType {
        const resourceItemDTO = this.mapResourceItemWithoutListsEntityToDTO(resourceData);

        if (resourceData?.services?.length > 0) {
            resourceItemDTO.Services = this.generalProviderMapper.getIdsArray(resourceData.services);
        }

        if (resourceData?.centers?.length > 0) {
            resourceItemDTO.Centers = this.generalProviderMapper.getIdsArray(resourceData.centers);
        }

        if (resourceData?.channelRestrictions?.length > 0) {
            resourceItemDTO.ChannelRestrictions = this.generalProviderMapper.mapChannelsEntityToDTO(resourceData.channelRestrictions);
        }

        if (resourceData?.coveragePlans?.length > 0) {
            resourceItemDTO.CoveragePlans = this.generalProviderMapper.getIdsArray(resourceData.coveragePlans);
        }

        if (resourceData?.resourceExternalKeys?.length > 0) {
            resourceItemDTO.ResourceExternalKeys = this.generalProviderMapper.mapExternalKeysEntityToDTO(resourceData.resourceExternalKeys);
        }

        return resourceItemDTO;
    }

    public mapResourceItemWithoutListsEntityToDTO(resource: ResourceType): ResourceDTOType {
        const resourceItemDTO: ResourceDTOType = {} as ResourceDTOType;

        resourceItemDTO.Id = resource.id;
        resourceItemDTO.Name = resource.name;
        resourceItemDTO.Priority = resource.priority;
        resourceItemDTO.ResourceTypeId = resource.resourceTypeId;
        resourceItemDTO.IsProfessional = resource.isProfessional;
        resourceItemDTO.ShortId = resource.shortId;
        resourceItemDTO.TimeSlotsJSON = resource.timeSlotsJSON;
        resourceItemDTO.TimeSlotsCount = resource.timeSlotsCount;
        resourceItemDTO.ExcludeFromWaitList = resource.excludeFromWaitList;

        return resourceItemDTO;
    }

    public mapFilterDataDTOToEntity(resourcesDTO: ResourceSearchDTOType[]): ResourceSearchType[] {
        const resourcesEntity: ResourceSearchType[] = [];

        for (let i = 0; i < resourcesDTO.length; i++) {
            resourcesEntity.push(this.mapFilterItemDTOToEntity(resourcesDTO[i]));
        }

        return lodash.orderBy(resourcesEntity, ['name']);
    }

    private mapFilterItemDTOToEntity(resourceDTO: ResourceSearchDTOType): ResourceSearchType {
        const resourceEntity: ResourceSearchType = {} as ResourceSearchType;

        resourceEntity.id = resourceDTO.Id;
        resourceEntity.name = resourceDTO.Name;
        resourceEntity.isProfessional = resourceDTO.IsProfessional;
        resourceEntity.isInfrastructure = resourceDTO.IsInfrastructure;
        resourceEntity.additionalInformation = resourceDTO.AdditionalInformation;
        resourceEntity.onlySelfPayer = resourceDTO.OnlySelfPayer;
        resourceEntity.title = resourceDTO.Title;

        return resourceEntity;
    }
}
