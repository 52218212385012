import {
    CenterOverviewFiltersDTOType,
    CenterOverviewFiltersType,
    CenterOverviewItemType,
    CreateReservationFiltersDTOType,
    CreateReservationFiltersType,
    DeleteReservationFiltersDTOType,
    DeleteReservationFiltersType,
    EditReservationFiltersDTOType,
    EditReservationFiltersType,
    MoveReservationFiltersDTOType,
    MoveReservationFiltersType,
    ReservationResultType,
    ResourceTimeSlotsFiltersDTOType,
    ResourceTimeSlotsFiltersType,
    ResourceTimeSlotsType,
    InfrastructureReservationType
} from '../../../../data-model/infrastructure-reservation.types';
import {Observable} from 'rxjs';
import {Inject, Injectable} from '@angular/core';
import {HttpBaseProvider} from '../http-base/http-base.provider';

@Injectable({
    providedIn: 'root'
})
export class InfrastructureReservationProvider {
    private environment;

    constructor(private httpBase: HttpBaseProvider,
                @Inject('environment') environment) {
        this.environment = environment;
    }

    // GetCenterOverview
    getCenterOverview(filter: CenterOverviewFiltersType): Observable<{ items: CenterOverviewItemType[], count: number }> {
        const FULL_URL = this.environment.BASE_URL + 'InfrastructureReservations/GetCenterOverview';
        const body = this.getBodyForGetCenterOverview(filter);

        return this.httpBase.post(FULL_URL, body);
    }

    // GetResourceTimeSlots
    getResourceTimeSlots(filter: ResourceTimeSlotsFiltersType): Observable<{ value: ResourceTimeSlotsType[] }> {
        const FULL_URL = this.environment.BASE_URL + 'InfrastructureReservations/GetResourceTimeSlots';
        const body = this.getBodyForResourceTimeSlots(filter);

        return this.httpBase.post(FULL_URL, body);
    }

    // CreateReservation
    createReservation(filter: CreateReservationFiltersType): Observable<ReservationResultType> {
        const FULL_URL = this.environment.BASE_URL + 'InfrastructureReservations/CreateReservation';
        const body = this.getBodyForCreateReservation(filter);

        return this.httpBase.post(FULL_URL, body);
    }

    // MoveReservation
    moveReservation(filter: MoveReservationFiltersType): Observable<ReservationResultType> {
        const FULL_URL = this.environment.BASE_URL + 'InfrastructureReservations/MoveReservation';
        const body = this.getBodyForMoveReservation(filter);

        return this.httpBase.post(FULL_URL, body);
    }

    // EditReservation
    editReservation(filter: EditReservationFiltersType): Observable<ReservationResultType> {
        const FULL_URL = this.environment.BASE_URL + 'InfrastructureReservations/EditReservation';
        const body = this.getBodyForEditReservation(filter);

        return this.httpBase.post(FULL_URL, body);
    }

    // DeleteReservation
    deleteReservation(filter: DeleteReservationFiltersType): Observable<InfrastructureReservationType> {
        const FULL_URL = this.environment.BASE_URL + 'InfrastructureReservations/DeleteReservation';
        const body = this.getBodyForDeleteReservation(filter);

        return this.httpBase.post(FULL_URL, body);
    }


    private getBodyForGetCenterOverview(queryFilter: CenterOverviewFiltersType): CenterOverviewFiltersDTOType {
        return {
            CenterId: queryFilter.centerId,
            InfrastructureId: queryFilter.infrastructureId,
            DateFrom: queryFilter.dateFrom,
            DateTo: queryFilter.dateTo,
            Tags: queryFilter.tags,
            ResourceId: queryFilter.resourceId
        };
    }

    private getBodyForResourceTimeSlots(queryFilter: ResourceTimeSlotsFiltersType): ResourceTimeSlotsFiltersDTOType {
        return {
            CenterId: queryFilter.centerId,
            ResourceId: queryFilter.resourceId,
            DateFrom: queryFilter.dateFrom,
            DateTo: queryFilter.dateTo,
        };
    }

    private getBodyForDeleteReservation(queryFilter: DeleteReservationFiltersType): DeleteReservationFiltersDTOType {
        return {
            ReservationId: queryFilter.reservationId,
            DateTo: queryFilter.dateTo,
            AssignationType: queryFilter.assignationType
        };
    }

    private getBodyForEditReservation(queryFilter: EditReservationFiltersType): EditReservationFiltersDTOType {
        return {
            EndDate: queryFilter.endDate,
            ReservationId: queryFilter.reservationId,
            AssignationType: queryFilter.assignationType,
            EditType: queryFilter.editType,
            TimeSlot: {
                HourFrom: queryFilter.timeSlot.hourFrom,
                HourTo: queryFilter.timeSlot.hourTo
            }
        };
    }

    private getBodyForMoveReservation(queryFilter: MoveReservationFiltersType): MoveReservationFiltersDTOType {
        return {
            InfrastructureId: queryFilter.infrastructureId,
            IsSimulation: queryFilter.isSimulation,
            OriginalReservationId: queryFilter.originalReservationId,
            EndDate: queryFilter.endDate,
            AssignationType: queryFilter.assignationType,
            SplitOption: queryFilter.splitOption,
            TimeSlot: {
                HourFrom: queryFilter.timeSlot.hourFrom,
                HourTo: queryFilter.timeSlot.hourTo
            }
        };
    }

    private getBodyForCreateReservation(queryFilter: CreateReservationFiltersType): CreateReservationFiltersDTOType {
        return {
            ResourceId: queryFilter.resourceId,
            InfrastructureId: queryFilter.infrastructureId,
            IsSimulation: queryFilter.isSimulation,
            StartDate: queryFilter.startDate,
            EndDate: queryFilter.endDate,
            TimeSlotId: queryFilter.timeSlotId,
            AssignationType: queryFilter.assignationType,
            TimeSlot: {
                HourFrom: queryFilter.timeSlot.hourFrom,
                HourTo: queryFilter.timeSlot.hourTo
            }
        };
    }
}
