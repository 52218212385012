import {Component, OnInit} from '@angular/core';
import {ConfigDataService} from '../../shared/services/config-data.service';
import {NavigationEnd, Router} from '@angular/router';

@Component({
    selector: 'app-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
    resourceVal = 'resource';
    expandInfrastructureManagement = false;
    showSideBarContent = false;

    constructor(
        private router: Router,
        public configDataService: ConfigDataService
    ) {
        router.events.subscribe((event: NavigationEnd) => {

        });
    }

    ngOnInit() {
        // show sidebarContent after the initial config request is finished
        // feature-access directive needs to have all the dates saved in browser storage
        this.configDataService.initialConfigRequestFinish
            .subscribe(val => {
                if (val) {
                    this.showSideBarContent = true;
                }
            });
    }
}

