import {Calendar, EventClickArg, EventInput, SlotLabelContentArg} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {
    CalendarOptions,
    CustomButtonInput,
    EventContentArg,
    EventDropArg,
    FullCalendarComponent
} from '@fullcalendar/angular';
import {DateTimeUtils} from '../../shared/utils/dateTime.utils';
import {NgbDatepicker, NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
    CalendarResourceType,
    CenterOverviewFiltersType,
    ConflictInstancesType,
    ReservationInstanceType,
    ResourceTimeSlotsFiltersType,
    InfrastructureReservationFilterType, CenterOverviewItemType, ResourceTimeSlotsType
} from '../../data-model/infrastructure-reservation.types';
import * as moment from 'moment';
import * as lodash from 'lodash';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessagesService} from '../../shared/services/messages.service';
import {constants} from '../../shared/constants/constants';
import esLocale from '@fullcalendar/core/locales/es';
import enLocale from '@fullcalendar/core/locales/en-gb';
import ptLocale from '@fullcalendar/core/locales/pt-br';
import interactionPlugin, {EventDragStartArg, EventDragStopArg, EventReceiveArg} from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import momentPlugin from '@fullcalendar/moment';
import {TranslatedLanguageService} from '../../shared/services/translated-language.service';
import {forkJoin, of} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {take} from 'rxjs/operators';
import {CenterProvider} from '../../shared/base-modules/providers/center/center.provider';
import {LocationDependentFiltersType, LocationSearchType} from '../../shared/base-modules/data-model/centerTypes';
import {ResourceDependentFiltersType, ResourceSearchType} from '../../shared/base-modules/data-model/resourceTypes';
import {ResourceProvider} from '../../shared/base-modules/providers/resource/resource.provider';
import {TagComponentOptionsType} from '../../shared/base-modules/components/tag/tagTypes';
import {ODataQueryObjectType} from '../../shared/base-modules/data-model/oDataObjectTypes';
import {TagProvider} from '../../shared/base-modules/providers/tag/tag.provider';
import {CreateReservationModalComponent} from './create-reservation-modal/create-reservation-modal.component';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {InfrastructureReservationUtils} from './infrastructure-reservation.utils';
import {
    InfrastructureReservationProvider
} from '../../shared/base-modules/providers/infrastructure-reservation/infrastructure-reservation.provider';
import {ResourceTypeProvider} from '../../shared/base-modules/providers/resource-type/resource-type.provider';
import {TagFilterType} from '../../shared/base-modules/data-model/tagTypes';
import {infrastructureReservationConstants} from './infrastructure-reservation.constants';

@AutoUnsubscribe()
@Component({
    selector: 'app-infrastructure-reservation',
    templateUrl: './infrastructure-reservation.component.html',
    styleUrls: ['./infrastructure-reservation.component.scss']
})
export class InfrastructureReservationComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('resourceCalendar', {static: false}) resourceCalendarComponent: FullCalendarComponent;
    @ViewChild('infrastructuresCalendar', {static: false}) infrastructureCalendarComponent: FullCalendarComponent;
    @ViewChild('datepicker', {static: false}) datepicker: NgbDatepicker;
    private resourceCalendarApi: Calendar;
    private infrastructuresCalendarApi: Calendar;
    private generalCalendarOptions: CalendarOptions;
    private customButtons: { [name: string]: CustomButtonInput };
    private translatedText: { [key: string]: string } = {};
    private translatedLanguage: string;
    private fullCalendarLicenseKey = constants.FULL_CALENDAR_LICENSE_KEY;
    private localConflict: ReservationInstanceType[] = [];
    resourceCalendarOptions: CalendarOptions;
    infrastructuresCalendarOptions: CalendarOptions;
    requestTableFilters: InfrastructureReservationFilterType;
    displayedGroupedConflicts = [];
    groupedConflictsArray: { [key: string]: ConflictInstancesType[] } = {};
    conflictsArray: ConflictInstancesType[] = [];
    showCalendars = false;
    calendarSpinnerId = 'calendarSpinner';
    areMoreAvailableInfrastructures = false;
    events: EventInput[];
    mainDependentFilters: {
        location: LocationDependentFiltersType,
        resource: ResourceDependentFiltersType,
        infrastructure: ResourceDependentFiltersType
    };
    initialResourceValues: any[] = [];
    initialCenterValues: any[] = [];
    initialInfrastructureValues: any[] = [];
    tagOptions: TagComponentOptionsType;
    tags = [];
    openedFilters = true;
    showFilters = true;
    calendarContainerCustomCss = 'col-md-9 my-1 infrastructure-reservation-calendar-container';
    filterContainerCustomCss = '';

    constructor(private dateTimeUtils: DateTimeUtils,
                private changeDetectorRef: ChangeDetectorRef,
                private translateService: TranslateService,
                private tagProvider: TagProvider,
                private resourceTypeProvider: ResourceTypeProvider,
                private modalService: NgbModal,
                private generalUtils: GeneralUtils,
                public centerProvider: CenterProvider,
                public resourceProvider: ResourceProvider,
                public translatedLanguageService: TranslatedLanguageService,
                public ngxLoader: NgxUiLoaderService,
                public messagesService: MessagesService,
                public infrastructureReservationProvider: InfrastructureReservationProvider,
                public infrastructureReservationUtils: InfrastructureReservationUtils) {
        const name = Calendar.name; // add this line in your constructor
    }

    ngOnInit(): void {
        this.translatedLanguage = this.translatedLanguageService.getUsedLanguage().substring(0, 2);
        this.requestTableFilters = this.infrastructureReservationUtils.getInitialTableFilters();
        this.translateLabels();
        this.defineFullCalendarOptions();

        this.setInitialMainDependentFilters();
        this.tagOptions = this.infrastructureReservationUtils.getTagOptionsFilter();

        this.startTagsAndResourceTypesRequest();
    }

    ngAfterViewInit(): void {
        this.changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
    }

    onSelectedDateFilter(date: NgbDateStruct): ConflictInstancesType[] {
        const formattedDate: string = moment(this.dateTimeUtils.getNgbDateWithoutOneMonth(date)).format('YYYY-MM-DD');
        this.requestTableFilters.currentDate = date;
        const newStartDate = this.infrastructureReservationUtils.getStartDate(this.requestTableFilters.currentDate,
            this.requestTableFilters.viewType);
        const newEndDate = this.infrastructureReservationUtils.getEndDate(this.requestTableFilters.currentDate,
            this.requestTableFilters.viewType);

        if (!lodash.isEqual(this.requestTableFilters.startDate, newStartDate) ||
            !lodash.isEqual(this.requestTableFilters.endDate, newEndDate)) {
            this.requestTableFilters.startDate = newStartDate;
            this.requestTableFilters.endDate = newEndDate;

            this.resourceCalendarApi.gotoDate(moment(this.dateTimeUtils.getNgbDateWithoutOneMonth(this.requestTableFilters.startDate))
                .format('YYYY-MM-DD'));
            this.infrastructuresCalendarApi.gotoDate(moment(this.dateTimeUtils.getNgbDateWithoutOneMonth(this.requestTableFilters.startDate))
                .format('YYYY-MM-DD'));

            this.getTablesInformation(this.requestTableFilters);

            if (!lodash.isEmpty(this.groupedConflictsArray)) {
                return this.conflictsArray = this.infrastructureReservationUtils.getConflictDateList(this.groupedConflictsArray[formattedDate]);
            }
        }
    }

    //make request data
    getTablesInformation(filter: InfrastructureReservationFilterType): void {
        const areFiltersValid = this.infrastructureReservationUtils.areTableFiltersValid(filter);
        //show full calendar if filters are valid
        this.showCalendars = areFiltersValid;
        if (this.showCalendars) {
            this.getCalendarInstance();
        } else {
            this.cleanCalendars();
        }

        if (areFiltersValid) {
            const centerOverviewRequestFilter: CenterOverviewFiltersType = this.infrastructureReservationUtils.getCenterOverviewRequestFilter(filter);
            const resourceTimeSlotsRequestFilter: ResourceTimeSlotsFiltersType =
                this.infrastructureReservationUtils.getResourceTimeSlotsRequestFilter(filter);

            //start spinner
            this.ngxLoader.startLoader(this.calendarSpinnerId);

            // get new set of events
            this.getProviderListForTableFilter(centerOverviewRequestFilter, resourceTimeSlotsRequestFilter)
                .pipe(take(1))
                .subscribe(([centerTimeSlots, resourceTimeSlots]) => {
                    this.cleanCalendars();
                    //add events and resources in calenders
                    this.addResourcesAndEventsInInfrastructuresCalendar(centerTimeSlots.items);
                    this.areMoreAvailableInfrastructures =
                        this.infrastructureReservationUtils.haveMoreAvailableInfrastructures(centerTimeSlots.count);
                    if (resourceTimeSlots) {
                        this.addResourcesAndEventsInResourceCalendar(resourceTimeSlots.value);
                    }
                    //rerender calendars in order to display the data
                    this.reRenderCalenders();
                }, (message) => {
                    //stop spinner
                    this.ngxLoader.stopLoader(this.calendarSpinnerId);
                    //show error message
                    this.messagesService.handlingErrorMessage(message);
                }, () => {
                    //stop spinner
                    this.ngxLoader.stopLoader(this.calendarSpinnerId);
                });

        }
    }

    getProviderListForTableFilter(centerOverviewRequestFilter: CenterOverviewFiltersType,
                                  resourceTimeSlotsRequestFilter: ResourceTimeSlotsFiltersType): any {
        return forkJoin([this.infrastructureReservationProvider.getCenterOverview(centerOverviewRequestFilter),
            this.infrastructureReservationProvider.getResourceTimeSlots(resourceTimeSlotsRequestFilter)]);
    }

    onSelectedCenterFilter(centerFilterList: LocationSearchType[]): void {
        this.initialCenterValues = centerFilterList;
        if (centerFilterList.length > 0) {
            this.mainDependentFilters.resource.locationId = centerFilterList[0].id;
            this.mainDependentFilters.infrastructure.locationId = centerFilterList[0].id;
            this.requestTableFilters.center = centerFilterList[0];
        } else {
            this.tagOptions.selectedTags = [];
            this.initialResourceValues = [];
            this.initialInfrastructureValues = [];
            this.setInitialMainDependentFilters();
            this.requestTableFilters.center = null;
            this.requestTableFilters.resource = null;
            this.requestTableFilters.infrastructure = null;
            this.requestTableFilters.tags = [];
        }
        this.getTablesInformation(this.requestTableFilters);
    }

    onSelectedResourceFilter(resourceFilterList: ResourceSearchType[]): void {
        this.initialResourceValues = resourceFilterList;
        if (resourceFilterList.length > 0) {
            this.requestTableFilters.resource = resourceFilterList[0];
        } else {
            this.requestTableFilters.resource = null;
            this.areMoreAvailableInfrastructures = false;
        }
        this.getTablesInformation(this.requestTableFilters);
    }

    onSelectedInfrastructureFilter(infrastructureFilterList: ResourceSearchType[]): void {
        this.initialInfrastructureValues = infrastructureFilterList;
        if (infrastructureFilterList.length > 0) {
            this.mainDependentFilters.location.resourceId = infrastructureFilterList[0].id;
            this.requestTableFilters.infrastructure = infrastructureFilterList[0];
        } else {
            this.mainDependentFilters.location.resourceId = null;
            this.requestTableFilters.infrastructure = null;
        }
        this.getTablesInformation(this.requestTableFilters);
    }

    onChangeTags(tags: TagFilterType[]): void {
        this.requestTableFilters.tags = tags;
        this.getTablesInformation(this.requestTableFilters);
    }

    /*******************************/
    // Modal operations
    /*******************************/

    // Create Reservation section
    openCreateReservationModal(draggedEvent: any, action: string): void {
        let infrastructure: any | null;
        let events: any[];
        let calendarTimeSlots = [];
        //get resourceTimeSlot
        const resourceTimeSlot: any = this.infrastructureReservationUtils.getInformationForModal(draggedEvent.event,
            draggedEvent.event._def.extendedProps.eventDate, draggedEvent.event._def.extendedProps.resource.id);
        if (action !== constants.DELETE) {
            //get the infrastructure where the event is dropped
            infrastructure = this.infrastructuresCalendarApi.getResourceById(resourceTimeSlot.infrastructureId);
            //get all the events for selected infrastructure
            events = infrastructure.getEvents();
            //filter events by infrastructureId and date
            calendarTimeSlots = this.infrastructureReservationUtils.getExistingEventsIntervals(draggedEvent.event, events);
        }
        const modalRef = this.modalService
            .open(CreateReservationModalComponent, this.generalUtils.getModalOptions());

        modalRef.componentInstance.resourceTimeSlot = resourceTimeSlot;
        modalRef.componentInstance.calendarTimeSlots = calendarTimeSlots;
        modalRef.componentInstance.action = action;

        modalRef.result.then((result) => {
            //remove event from infrastructuresCalendar
            this.onModalReturnReservation();
            //add all the conflicts in this.localConflicts
            if (action !== constants.CLICK) {
                this.localConflict = lodash.uniq(lodash.concat(this.localConflict, result.conflicts));
                this.setConflictsInformation(this.localConflict);
            }
        }, (onClose) => {
            this.onCloseModal(draggedEvent, action);
        });
    }

    private setConflictsInformation(localConflict: ReservationInstanceType[]): void {
        // this is used to display red dots in the calendar
        this.displayedGroupedConflicts = lodash.uniqBy(localConflict, 'date');

        //grouped the conflicts by date
        this.groupedConflictsArray = lodash.groupBy(this.infrastructureReservationUtils.getConflictDateList(this.displayedGroupedConflicts),
            'formattedDate');

        // this is used to display all the conflicts
        this.conflictsArray = this.infrastructureReservationUtils.getConflictDateList(localConflict);
    }

    private onModalReturnReservation(): void {
        this.getTablesInformation(this.requestTableFilters);
    }

    private onCloseModal(draggedEvent: any, action: string): void {
        if (action === constants.CREATE) {
            // remove event from infrastructuresCalendar
            draggedEvent.revert();

            // refetch the resource calendar events
            const eventSources = this.resourceCalendarApi.getEventSources();
            for (const eventSource of eventSources) {
                eventSource.refetch();
            }
        } else if (action === constants.MOVE) {
            //reverse event to initial position in infrastructures calendar
            draggedEvent.revert();
        }
    }

    // initialize the full calendars
    private defineFullCalendarOptions(): void {
        //general options for both calendars
        this.generalCalendarOptions = this.infrastructureReservationUtils.getGeneralCalendarOptions();
        this.generalCalendarOptions.locales = [esLocale, enLocale, ptLocale]; // available languages
        this.generalCalendarOptions.locale = this.translatedLanguage; // calendar language
        this.generalCalendarOptions.plugins = [momentPlugin, interactionPlugin, dayGridPlugin, timeGridPlugin, resourceTimelinePlugin];

        //resource calendar options (first calender)
        this.resourceCalendarOptions = {
            aspectRatio: this.generalCalendarOptions.aspectRatio,
            contentHeight: '40%',
            height: '40%',
            resourceOrder: '-title', // sort descending by title
            locales: this.generalCalendarOptions.locales, // all translated languages
            locale: this.generalCalendarOptions.locale, // language in use
            initialView: this.generalCalendarOptions.initialView, // Set initial view
            slotMinTime: infrastructureReservationConstants.SLOT_MIN_TIME,
            slotMaxTime: infrastructureReservationConstants.SLOT_MAX_TIME,
            droppable: this.generalCalendarOptions.droppable, // events droppable in calendar: boolean
            editable: this.generalCalendarOptions.editable, // events editable in calendar: boolean
            //Defines the buttons and title at the top of the calendar.
            headerToolbar: this.infrastructureReservationUtils.getResourceCalendarHeaderOption(),
            resourceAreaWidth: this.generalCalendarOptions.resourceAreaWidth, //Determines the width of the area that contains the list of resources.
            resourceAreaHeaderContent: this.translatedText.resourceTranslation, // resource column header name (translated)
            slotLabelFormat: {hour: 'numeric', omitZeroMinute: false, hour12: false}, // text that will be displayed within a time slot
            duration: this.generalCalendarOptions.duration, // calendar duration per view
            eventTimeFormat: this.generalCalendarOptions.eventTimeFormat, // Determines the time-text that will be displayed on each event.
            plugins: this.generalCalendarOptions.plugins,
            slotDuration: this.generalCalendarOptions.slotDuration, //The frequency for displaying time slots
            customButtons: this.getResourceCalendarCustomButtons(), //Defines custom buttons that can be used in the header/footer.
            displayEventTime: this.generalCalendarOptions.displayEventTime, //boolean
            dragRevertDuration: this.generalCalendarOptions.dragRevertDuration,
            titleFormat: {month: 'long', day: 'numeric'},
            resources: [],
            events: [],
            //Triggered when event dragging starts.
            eventDragStart: (info) => this.onResourceCalendarEventDragStart(info),
            eventDragStop: (info) => this.onResourceCalendarEventDragStop(info),
            eventDidMount: (info) => this.onResourceCalendarEventRender(info),
            // Exact programmatic control over where an event can be dropped.
            eventAllow: (dropLocation, draggedEvent) => {
                return false;
            },
            slotLabelDidMount: (info) => this.onResourceCalendarDatesRender(info),
            schedulerLicenseKey: this.fullCalendarLicenseKey
        };

        //infrastructure time-slots (second calender)
        this.infrastructuresCalendarOptions = {
	    aspectRatio: this.generalCalendarOptions.aspectRatio,
	        windowResizeDelay: 1000,
            contentHeight: '60%',
            height: '60%',
            resourceOrder: 'title', // sort ascending by title
            timeZone: 'UTC',
            locales: this.generalCalendarOptions.locales, // all translated languages
            locale: this.generalCalendarOptions.locale, // language in use
            initialView: this.generalCalendarOptions.initialView, // Set initial view
            slotMinTime: infrastructureReservationConstants.SLOT_MIN_TIME,
            slotMaxTime: infrastructureReservationConstants.SLOT_MAX_TIME,
            droppable: this.generalCalendarOptions.droppable, // events droppable in calendar: boolean
            editable: this.generalCalendarOptions.editable, // events editable in calendar: boolean
            headerToolbar: false, //Defines the buttons and title at the top of the calendar.
            resourceAreaWidth: this.generalCalendarOptions.resourceAreaWidth,
            resourceAreaHeaderContent: this.translatedText.infrastructuresTranslation, // resource column header name (translated),
            dayMaxEventRows: true, // for all non-TimeGrid views
            dayMaxEvents: true, // allow "more" link when too many events
            slotLabelFormat: {hour: 'numeric', omitZeroMinute: false, hour12: false},
            duration: this.generalCalendarOptions.duration, // calendar duration per view
            eventTimeFormat: this.generalCalendarOptions.eventTimeFormat, // Determines the time-text that will be displayed on each event.
            plugins: this.generalCalendarOptions.plugins,
            slotDuration: this.generalCalendarOptions.slotDuration,
            // Or drag an event from another calendar.
            eventReceive: (info) => this.onInfrastructuresCalendarEventReceive(info),
            // this event is triggered when an event is moved in the same calendar
            eventDrop: (info) => this.onInfrastructuresCalendarEventDrop(info),
            // Triggered when the user clicks an event.
            eventClick: (eventClickInfo) => this.onInfrastructuresCalendarEventClick(eventClickInfo),
            eventDragStart: (info) => this.onInfrastructuresCalendarEventDragStart(info), //Triggered when an event start dragging
            eventDidMount: (info) => this.onInfrastructuresCalendarEventRender(info), //Triggered when an event was dragged
            // Exact programmatic control over where an event can be dropped.
            eventAllow: (dropLocation: any, draggedEvent: any) => {
                //     check if event has reservationId and if it is moved in other infrastructure
                //     do not allow moving the event in the same infrastructure
                if (draggedEvent._def.extendedProps.reservationId && dropLocation.resource._resource.id === draggedEvent._def.resourceIds[0]) {
                    return false;
                }
                return true;
            },
            // Determines if events being dragged and resized are allowed to overlap each other.
            eventOverlap: (stillEvent, movingEvent) => {
                return true;
            },
            resourceLabelDidMount: (info) => this.onInfrastructuresCalendarResourceNameRender(info),
            // tslint:disable-next-line:max-line-length
            dragRevertDuration: this.generalCalendarOptions.dragRevertDuration, //Time it takes for an event to revert to its original position after an unsuccessful drag.
            resources: [],
            events: [],
            schedulerLicenseKey: this.fullCalendarLicenseKey
        };
    }

    // Triggered when a new set of dates has been rendered.(next, previous, gotoDate)
    private onResourceCalendarDatesRender(info: SlotLabelContentArg & { el: HTMLElement }): void {
        // get tables information for the new interval
        const currentDate = this.dateTimeUtils.convertDateInNgbDateStruct(info.date);
        const newStartDate = this.infrastructureReservationUtils.getStartDate(currentDate, this.requestTableFilters.viewType);
        const newEndDate = this.infrastructureReservationUtils.getEndDate(currentDate, this.requestTableFilters.viewType);

        // make a new request when is a new time range
        if (!lodash.isEqual(this.requestTableFilters.startDate, newStartDate) ||
            !lodash.isEqual(this.requestTableFilters.endDate, newEndDate)) {
            this.requestTableFilters.currentDate = currentDate;
            this.requestTableFilters.startDate = newStartDate;
            this.requestTableFilters.endDate = newEndDate;

            this.getTablesInformation(this.requestTableFilters);
        }
    }

    // Triggered when event dragging begins.
    private onResourceCalendarEventDragStart(info: EventDragStartArg): void {
        // adjust event styles (the only way to do that)
        const timeEl = info.el.querySelector('.fc-event-title');
        info.el.style.backgroundColor = '#99CC00';
        info.el.style.borderColor = '#99CC00';
        info.el.style.borderRadius = '5px';
        info.el.style.opacity = '1';
        info.el.style.borderRadius = '5px 5px 5px 5px';
        info.el.style['-moz-border-radius'] = '5px 5px 5px 5px';
        info.el.style['-webkit-border-radius'] = '5px 5px 5px 5px';
        // @ts-ignore
        timeEl.style.color = '#ffffff';
    }

    // Trigger when event dragging ends
    private onResourceCalendarEventDragStop(info: EventDragStopArg): void {
        // adjust event styles (the only way to do that)
        const timeEl = info.el.querySelector('.fc-event-title');
        info.el.style.backgroundColor = '#D8D8D8';
        info.el.style.borderColor = '#5B5B5B';
        info.el.style.borderRadius = '5px';
        info.el.style.opacity = '1';
        info.el.style.borderRadius = '5px 5px 5px 5px';
        info.el.style['-moz-border-radius'] = '5px 5px 5px 5px';
        info.el.style['-webkit-border-radius'] = '5px 5px 5px 5px';
        // @ts-ignore
        timeEl.style.color = '#ffffff';
    }

    // Triggered while an event is being rendered. A hook for modifying its DOM.
    private onResourceCalendarEventRender(info: EventContentArg & { el: HTMLElement }): void {
        // add blocked svg picture in front of title (in this case: title = time interval)
        const hourToDate = this.dateTimeUtils.getStringHourFromMinutes(info.event._def.extendedProps.hourTo);
        const eventEnd = this.dateTimeUtils.getDateWithAddedTime(info.event._def.extendedProps.eventDate, hourToDate);
        const isExpiredEvent = this.infrastructureReservationUtils.isExpiredEventFromResourceCalendar(eventEnd);
        if (info.event._def.extendedProps.status === constants.BLOCKED) {
            const titleEl = info.el.querySelector('.fc-event-title.fc-sticky');
            if (titleEl) {
                titleEl.innerHTML = this.infrastructureReservationUtils.getSvgPicture(info.event._def.extendedProps.status, isExpiredEvent) +
                    info.event._def.title;
            }
        }

        this.infrastructureReservationUtils.addCustomTimelineEventsClass();
        this.infrastructureReservationUtils.addCustomTimelineEventHarness();
        this.infrastructureReservationUtils.addTooltipForResourceEvent(info);
    }

    /****************************************/
    // Calendars options
    // Resource Calendar options (first calender:#resourcecalendar)
    /****************************************/
    private getResourceCalendarCustomButtons(): { [name: string]: CustomButtonInput; } {
        this.customButtons = {
            customPreviousButton: {
                text: '<',
                click: () => {
                    this.resourceCalendarApi.prev();
                    this.infrastructuresCalendarApi.prev();
                }
            },
            customNextButton: {
                text: '>',
                click: () => {
                    this.resourceCalendarApi.next();
                    this.infrastructuresCalendarApi.next();
                }
            },
            customTodayButton: {
                text: this.translatedText.todayTranslation,
                click: () => {
                    const today = moment().format('YYYY-MM-DD');
                    this.resourceCalendarApi.gotoDate(today);
                    this.infrastructuresCalendarApi.gotoDate(today);
                    this.reRenderCalenders();
                }
            },
            customRefreshButton: {
                text: '↻',
                click: () => {
                    this.getTablesInformation(this.requestTableFilters);
                }
            }

        };
        return this.customButtons;
    }

    private addResourcesAndEventsInInfrastructuresCalendar(response: CenterOverviewItemType[]): void {
        const infrastructures: CalendarResourceType[] = this.infrastructureReservationUtils.getResourcesForInfrastructuresCalendar(response);
        const events: EventInput[] = this.infrastructureReservationUtils.getInfrastructuresCalendarEvents(response);

        this.infrastructuresCalendarOptions.resources = infrastructures;
        this.infrastructuresCalendarOptions.events = events;
    }

    private addResourcesAndEventsInResourceCalendar(response: ResourceTimeSlotsType[]): void {
        const events: EventInput[] = this.infrastructureReservationUtils.getResourceCalendarEvents(response);
        const resources: CalendarResourceType[] = this.infrastructureReservationUtils.getResourcesForResourceEvents(response);

        this.resourceCalendarOptions.resources = resources;
        this.resourceCalendarOptions.events = events;
        this.translateLabels();
    }

    private getCalendarInstance(): void {
        this.changeDetectorRef.detectChanges();
        //get calendars instance
        this.resourceCalendarApi = this.resourceCalendarComponent.getApi();
        this.infrastructuresCalendarApi = this.infrastructureCalendarComponent.getApi();
    }

    private cleanCalendars(): void {
        //clean calendars resources and events
        // @ts-ignore
        this.resourceCalendarOptions.resources.splice(0, this.infrastructuresCalendarOptions.resources.length);
        // @ts-ignore
        this.resourceCalendarOptions.events.splice(0, this.resourceCalendarOptions.events.length);
        // @ts-ignore
        this.infrastructuresCalendarOptions.resources.splice(0, this.infrastructuresCalendarOptions.resources.length);
        // @ts-ignore
        this.infrastructuresCalendarOptions.events.splice(0, this.infrastructuresCalendarOptions.events.length);

        if (this.resourceCalendarApi) {
            this.resourceCalendarApi.removeAllEvents();
        }
        if (this.infrastructuresCalendarApi) {
            this.infrastructuresCalendarApi.removeAllEvents();
        }
    }

    private reRenderCalenders(): void {
        this.resourceCalendarApi.refetchResources();
        this.infrastructuresCalendarApi.refetchResources();

        const resourceEvents = this.resourceCalendarApi.getEventSources();
        const infrastructureEvents = this.infrastructuresCalendarApi.getEventSources();

        // refetch events
        for (const event of resourceEvents) {
            event.refetch();
        }
        for (const event of infrastructureEvents) {
            event.refetch();
        }
    }

    private translateLabels(): void {
        // translate today, resource and infrastructures
        this.setTranslatedTexts(); // translate when the language didn't change
        // translate when language changed (and on refresh)
        this.translateService.onLangChange
            .subscribe((newLanguage) => {
                // set new language
                this.translatedLanguage = this.translatedLanguageService.getUsedLanguage().substring(0, 2);
                this.datepicker.focus();
                if (this.resourceCalendarApi) {
                    this.resourceCalendarApi.setOption('locale', this.translatedLanguage);
                }
                this.setTranslatedTexts();
            });
    }

    private setTranslatedTexts(): void {
        forkJoin([this.messagesService.translateMessage('label.today'),
            this.messagesService.translateMessage('label.timeSlotAndHour'),
            this.messagesService.translateMessage('label.infrastructureAndHour'),
            this.messagesService.translateMessage('label.unassignedTimeSlots')])
            .subscribe(translatedMessages => {
                this.translatedText.todayTranslation = translatedMessages[0];
                this.translatedText.resourceTranslation = translatedMessages[1];
                this.translatedText.infrastructuresTranslation = translatedMessages[2];
                this.translatedText.unassignedTimeSlots = translatedMessages[3];

                if (this.resourceCalendarOptions) {
                    this.resourceCalendarOptions.resourceAreaHeaderContent = this.translatedText.resourceTranslation;
                }
                if (this.infrastructuresCalendarOptions) {
                    this.infrastructuresCalendarOptions.resourceAreaHeaderContent = this.translatedText.infrastructuresTranslation;
                }
                if (this.customButtons) {
                    this.customButtons.customTodayButton.text = this.translatedText.todayTranslation;
                }
                if (this.resourceCalendarOptions?.resources) {
                    this.resourceCalendarOptions.resources[0].title = this.translatedText.unassignedTimeSlots;
                }
                if (this.resourceCalendarApi && this.infrastructuresCalendarApi) {
                    this.resourceCalendarApi.refetchResources();
                }
            });
    }

    // The event is dragged from other calendar
    private onInfrastructuresCalendarEventReceive(info: EventReceiveArg): void {
        this.openCreateReservationModal(info, constants.CREATE);
    }

    // Triggered when an event is on final position
    private onInfrastructuresCalendarEventDrop(info: EventDropArg): void {
        this.openCreateReservationModal(info, constants.MOVE);
    }

    // Click event
    private onInfrastructuresCalendarEventClick(info: EventClickArg): void {
        this.openCreateReservationModal(info, constants.CLICK);
    }

    // Triggered when an event is dragged on other row
    private onInfrastructuresCalendarEventDragStart(info: EventDragStartArg): void {
        // adjust event styles (the only way to do that)
        info.el.style.borderRadius = '5px 5px 5px 5px';
        info.el.style['white-space'] = 'initial';
    }

    // Triggered when an event is rendered. A hook for modifying its DOM.
    private onInfrastructuresCalendarEventRender(info: EventContentArg & { el: HTMLElement }): void {
        // add blocked svg picture in front of title (in this case in title = time interval)
        if (info.event._def.extendedProps.status === constants.BLOCKED) {
            const titleEl = info.el.querySelector('.fc-event-title.fc-sticky');
            if (titleEl) {
                titleEl.innerHTML = this.infrastructureReservationUtils.getSvgPicture(info.event._def.extendedProps.status,
                    undefined) + info.event._def.title;
            }
        }

        // add the custom styles for the time slot container (it is higher than the moment when the events are created)
        const elements = document.getElementsByClassName('fc-timeline-event-harness');
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.add('custom-timeline-infrastructure-events-harness');
        }

        this.infrastructureReservationUtils.addTooltipForInfrastructureEvent(info);
    }

    // Resource name did mount (rendered)
    private onInfrastructuresCalendarResourceNameRender(info): void {
        // get text element
        const infrastructureNameEl: any = info.el.querySelector('.fc-datagrid-cell-main');
        // check if the resource has reservation
        if (info.resource._resource.extendedProps.isResourceHaveReservation) {
            this.messagesService.translateMessage('label.infrastructureHasReservation')
                .subscribe(translatedMessage => {
                    // add hasReservation bullet and tooltip
                    infrastructureNameEl.classList.add('bullet-point-infrastructures');
                    infrastructureNameEl.setAttribute('title', translatedMessage);
                });
        }
    }

    //get all the tags for tag filter and add resourceTypeId in resource-TypeExclusionList if resourceType is 'infrastructure'
    private startTagsAndResourceTypesRequest(): void {
        const filter: ODataQueryObjectType = {
            select: ['Id', 'Name', 'ScopedResource'],
            filter: {ScopedResource: true}
        };

        forkJoin([
            this.tagProvider.getEntries(filter),
            this.resourceTypeProvider.getEntries({select: 'Name, IsProfessional, Id, IsInfrastructure'})
        ])
            .pipe(take(1))
            .subscribe(([tags, resourceType]) => {
                this.tagOptions.tagList = tags.value;
                const resourceTypeInfrastructureId = this.infrastructureReservationUtils.getIdOfResourceTypeInfrastructure(resourceType.value);
                if (resourceTypeInfrastructureId) {
                    this.mainDependentFilters.resource.resourceTypeExclusionList.push(resourceTypeInfrastructureId);
                    this.mainDependentFilters.infrastructure.resourceTypeId = resourceTypeInfrastructureId;
                }
            }, (message) => {
                this.ngxLoader.stop();
                this.messagesService.handlingErrorMessage(message);
            }, () => {
                this.ngxLoader.stop();
            });
    }

    private setInitialMainDependentFilters() {
        this.mainDependentFilters = {
            location: this.infrastructureReservationUtils.getLocationDependentFilters(),
            resource: this.infrastructureReservationUtils.getResourceDependentFilters(),
            infrastructure: this.infrastructureReservationUtils.getInfrastructureDependentFilters()
        };
    }

    // SideNav Filters Functions

    private filtersOpened(): void {

        this.openedFilters = !this.openedFilters;
        this.setFiltersCustomsCss();
        //without TimeOut not worked
        setTimeout(() => {
            this.reRenderCalenders();
        }, 501);
    }

    private setFiltersCustomsCss(){
        if (this.openedFilters) {
            this.showFilters = true;
            this.calendarContainerCustomCss = 'col-md-9 my-1 infrastructure-reservation-calendar-container';
            this.filterContainerCustomCss = 'animate__animated animate__fadeInLeft';
        } else {
            this.filterContainerCustomCss = 'animate__animated animate__fadeOutLeft';
            //TIME FOR ANIMATED CSS
            setTimeout(() => {
                this.showFilters = false;
                this.calendarContainerCustomCss = 'col-md-12 my-1 infrastructure-reservation-calendar-container w-100 bd-highlight ';
            }, 500);
        }
    }
}
