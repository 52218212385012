import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpBaseProvider} from '../http-base/http-base.provider';
import {UserLoginType} from '../../components/user-info/user-info.type';

@Injectable({
    providedIn: 'root'
})
export class UserProvider {
    private environment;

    constructor(private httpBase: HttpBaseProvider,
                @Inject('environment') environment) {
        this.environment = environment;
    }

    getUserInformation(): Observable<UserLoginType> {
        const URL = this.environment.BASE_URL + 'Users/GetUserInformation()';

        return this.httpBase.get(URL, false);
    }
}
