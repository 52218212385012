import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {LanguageType} from '../../data-model/language.type';
import * as Lodash from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class LocalJsonProvider {
  private languagesCache: LanguageType[] = [];
  private languagesObservableCache: Observable<LanguageType[]>;

  constructor(private http: HttpClient) {
  }

  getLanguages() {
    if (!Lodash.isEmpty(this.languagesCache)) {
      return of(this.languagesCache);
    } else if (this.languagesObservableCache) {
      return this.languagesObservableCache;
    } else {
      this.languagesObservableCache = this.fetchLanguages();
    }
    return this.languagesObservableCache;
  }

  private fetchLanguages(): Observable<LanguageType[]> {
    const url = '../assets/json/languages.json';

    return this.http.get<LanguageType[]>(url);
    // .pipe(
    //   map(rawData => {
    //     this.mapCachedLanguages(rawData);
    //   })
    //   // catchError(this.handleError)
    // );

  }

  private mapCachedLanguages(languages: LanguageType[]): LanguageType[] {
    this.languagesObservableCache = null;
    this.languagesCache = languages;
    return this.languagesCache;
  }

  private handleError(error: HttpErrorResponse): void {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    // return throwError(
    //   'Something bad happened; please try again later.');
  }
}
