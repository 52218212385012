import {Injectable} from '@angular/core';
import {AddressDTOType, AddressType} from '../data-model/generalTypes';
import {ExternalKeysDTOType, ExternalKeysType} from '../data-model/externalKeysTypes';
import {ChannelBaseDTOType, ChannelBaseType} from '../data-model/channelRestrictionTypes';

@Injectable({
    providedIn: 'root'
})

export class GeneralProviderMapper {
    public getIdsArray(array: any[]): any[] {
        const arrayDTO = [];
        for (const item of array) {
            arrayDTO.push({Id: item.id});
        }
        return arrayDTO;
    }

    public mapChannelsEntityToDTO(channels: ChannelBaseType[]): ChannelBaseDTOType[] {
        const channelsDTO: ChannelBaseDTOType[] = [];

        for (const channel of channels) {
            channelsDTO.push(this.mapChannelEntityToDTO(channel));
        }

        return channelsDTO;
    }

    public mapChannelEntityToDTO(channel: ChannelBaseType): ChannelBaseDTOType {
        const channelDTO: ChannelBaseDTOType = {} as ChannelBaseDTOType;
        if (channel?.id?.length > 10) {
            channelDTO.Id = channel.id;
        }
        channelDTO.Channel = channel.channel;

        return channelDTO;
    }

    public mapExternalKeysEntityToDTO(externalKeys: ExternalKeysType[]): ExternalKeysDTOType[] {
        const arrayDTO: ExternalKeysDTOType[] = [];

        for (const externalKey of externalKeys) {
            arrayDTO.push(this.mapExternalKeysItemToDTO(externalKey));
        }

        return arrayDTO;
    }

    private mapExternalKeysItemToDTO(externalKey: ExternalKeysType): ExternalKeysDTOType {
        const item: ExternalKeysDTOType = {} as ExternalKeysDTOType;

        if (externalKey.id) {
            item.Id = externalKey.id;
        }
        item.Key = externalKey.key;
        item.Origin = externalKey.origin;

        return item;
    }

    public mapAddressItemToDTO(address: AddressType): AddressDTOType {
        const addressDTO: AddressDTOType = {} as AddressDTOType;

        addressDTO.Locality = address.locality;
        addressDTO.Street = address.street;
        addressDTO.StreetNumber = address.streetNumber;
        addressDTO.Floor = address.floor;
        addressDTO.ApartmentNumber = address.apartmentNumber;

        return addressDTO;
    }

    public mapAddressDTOToEntity(addressDTO: AddressDTOType): AddressType {
        const address: AddressType = {} as AddressType;

        address.locality = addressDTO.Locality;
        address.street = addressDTO.Street;
        address.streetNumber = addressDTO.StreetNumber;
        address.floor = addressDTO.Floor;
        address.apartmentNumber = addressDTO.ApartmentNumber;

        return address;
    }
}
