import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DashboardComponent} from './dashboard/dashboard.component';
import {RequestAuthenticatedUserGuard} from '../core/guards/request-authenticated-user.guard';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {InfrastructureReservationComponent} from './infrastructure-reservation/infrastructure-reservation.component';

const routes: Routes = [
    {path: 'dashboard', component: DashboardComponent, canActivate: [RequestAuthenticatedUserGuard]},
    {path: 'infrastructureReservation', component: InfrastructureReservationComponent, canActivate: [RequestAuthenticatedUserGuard]},
    {path: 'notFound', component: PageNotFoundComponent, canActivate: []}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class FeatureRoutingModule {
}

