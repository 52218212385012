import {Injectable} from '@angular/core';
import * as lodash from 'lodash';
import {PhoneNumberUtil} from 'google-libphonenumber';
import {FormValidationType} from "./validations.types";

@Injectable({
    providedIn: 'root'
})
export class Validations {
    libPhoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();

    constructor() {
    }

    // Validates phone number and country code using the PhoneNumberUtil library
    getValidatePhoneNumberLibPhone(phoneNumber: string, isRequired: boolean, phoneCountryCode: string): FormValidationType {
        if (lodash.isEmpty(phoneNumber)) {
            return {isValid: !isRequired, errorMessage: isRequired ? 'label.pleaseAddPhoneNumber' : ''} as FormValidationType;
        }
        // phoneNumber is not empty
        if (lodash.isEmpty(phoneCountryCode)) {
            return {isValid: false, errorMessage: 'label.pleaseAddPhoneNumberCountryCode'} as FormValidationType;
        }
        // phoneNumber and phoneCountryCode are not empty
        const fullPhoneNumber = `+${phoneCountryCode}${phoneNumber}`;
        try {
            const libFullPhoneNumber = this.libPhoneUtil.parseAndKeepRawInput(fullPhoneNumber);
            if (this.libPhoneUtil.isValidNumber(libFullPhoneNumber)) {
                return {isValid: true, errorMessage: ''} as FormValidationType;
            }
            return {isValid: false, errorMessage: 'patientPortal.errorMessage.invalidPhoneNumber'} as FormValidationType;
        } catch { // in case libPhoneUtil.parseAndKeepRawInput throws error => it means the string is not a valid phone number
            return {isValid: false, errorMessage: 'patientPortal.errorMessage.invalidPhoneNumber'} as FormValidationType;
        }
    }

    // Currently used only on awa-new in user-md and systemconfig
    getValidateEmail(email: string, isRequired: boolean): FormValidationType {
        if (lodash.isEmpty(email)) {
            return {isValid: !isRequired, errorMessage: isRequired ? 'label.error.required' : ''} as FormValidationType;
        }
        const regexCode = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const regexPattern = new RegExp(regexCode);
        if (!regexPattern.test(email)) {
            return {isValid: false, errorMessage: 'label.error.inputValidEmail'} as FormValidationType;
        }
        return {isValid: true, errorMessage: ''} as FormValidationType;
    }

    // Currently used only in awa-new in systemconfig
    getValidateIntegerBetween(numberString: string, lowerBound: number, higherBound: number, errorMessage: string): FormValidationType {
        const numberNumber = Number(numberString);
        if (isNaN(numberNumber) || !lodash.isInteger(numberNumber) || numberNumber < lowerBound || numberNumber > higherBound) {
            return {isValid: false, errorMessage} as FormValidationType;
        }
        return {isValid: true, errorMessage: ''} as FormValidationType;
    }
}
