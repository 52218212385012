<ngx-ui-loader></ngx-ui-loader>

<div class="fix-header fix-sidebar card-no-border"
     [ngClass]="{'mini-sidebar':toggledSideBar, 'is-full-screen':isFullScreen}">
    <div id="main-wrapper">
        <app-nav-bar *ngIf="!isFullScreen"></app-nav-bar>
        <app-side-bar *ngIf="!isFullScreen"></app-side-bar>
        <div class="page-wrapper"
             [ngClass]="{'page-wrapper-container-max': toggledSideBar, 'page-wrapper-container-min': !toggledSideBar}">
            <div class="container-fluid">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
