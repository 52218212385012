import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavBarComponent} from './nav-bar.component';
import {SharedModule} from '../../shared/shared.module';
import {RouterModule} from '@angular/router';

@NgModule({
    declarations: [NavBarComponent],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule
    ],
    exports: [NavBarComponent]
})
export class NavBarModule {
}
