export const infrastructureReservationConstants = {
    RESOURCE_OVERVIEW: 'resourceOverview',
    WEEKLY: 'weekly',
    DAILY: 'daily',
    SINGLE_INSTANCE: 'SingleInstance',
    ALL_INSTANCES: 'AllInstances',
    UNTIL_END_DATE: 'UntilEndDate',
    THIS_INSTANCE: 'ThisInstance',
    THIS_AND_FOLLOWING_INSTANCES: 'ThisAndFollowingInstances',
    THIS_AND_FOLLOWING_INSTANCES_TILL_END_DATE: 'ThisAndFollowingInstancesTillEndDate',
    DELETE_ORIGINAL_RESERVATION: 'DeleteOriginalReservation',
    KEEP_ORIGINAL_RESERVATION: 'KeepOriginalReservation',
    SPLIT: 'split',
    SHRINK: 'shrink',
    SLOT_MIN_TIME: '08:00',
    SLOT_MAX_TIME: '21:00'
};
