import {Injectable} from '@angular/core';
import {GeneralProviderMapper} from '../../providers/generalProviderMapper';
import {FormValidationType} from './validations.types';
import {Validations} from './validations';
import * as lodash from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class GeneralUtils {
    constructor(private generalProviderMapper: GeneralProviderMapper,
                private validations: Validations) {
    }

    public getIdsArray(array: any[]): any[] {
        const arrayDTO = [];
        for (const item of array) {
            arrayDTO.push({Id: item.id});
        }
        return arrayDTO;
    }

    isValueTrue(stringValue: string | boolean): boolean {
        return stringValue === 'true' || stringValue === 'True' || stringValue === true;
    }

    isEmpty(value: any): boolean {
        return !!(value === undefined || value === null || value === '' || value === 0);
    }

    getPhoneNumberValidation(phoneNumber: string, isRequired: boolean, phoneCountryCode: string): FormValidationType {
        return this.validations.getValidatePhoneNumberLibPhone(phoneNumber, isRequired, phoneCountryCode) as FormValidationType;
    }

    encodeStringAndRemoveInvalidChars(url: string) {
        return this.encodeStringForFilterQuery(this.removeInvalidCharactersForFilter(url));
    }

    private encodeStringForFilterQuery(item: string) {
        return encodeURIComponent(lodash.replace(lodash.replace(item, /"/g, '""'), /'/g, '\'\''));
    }

    private removeInvalidCharactersForFilter(item: string) {
        return item.replace(/[#&()]/g, '');
    }

    public getJsonPatchForExternalKeys(initialObjectExternalKeys: any[], finalObjectExternalKeys: any[], entityName: string): any[] {
        let objectToRemove = [];
        let objectToAdd = [];
        const externalKeyPatchData = [];

        // REMOVE SECTION
        const oldObjectExternalKeysDTO = this.generalProviderMapper.mapExternalKeysEntityToDTO(initialObjectExternalKeys);
        const newObjectExternalKeysDTO = this.generalProviderMapper.mapExternalKeysEntityToDTO(finalObjectExternalKeys);

        objectToRemove = lodash.differenceBy(oldObjectExternalKeysDTO, newObjectExternalKeysDTO, 'Id');
        objectToRemove = lodash.concat(objectToRemove, lodash.differenceBy(oldObjectExternalKeysDTO, newObjectExternalKeysDTO, 'Key'));
        objectToRemove = lodash.concat(objectToRemove, lodash.differenceBy(oldObjectExternalKeysDTO, newObjectExternalKeysDTO, 'Origin'));
        objectToRemove = lodash.uniqBy(objectToRemove, 'Id');

        // ADD SECTION
        if (oldObjectExternalKeysDTO.length > 0) {
            objectToAdd = lodash.differenceBy(newObjectExternalKeysDTO, oldObjectExternalKeysDTO, 'Id');
            objectToAdd = lodash.concat(objectToAdd, lodash.differenceBy(newObjectExternalKeysDTO, oldObjectExternalKeysDTO, 'Key'));
            objectToAdd = lodash.concat(objectToAdd, lodash.differenceBy(newObjectExternalKeysDTO, oldObjectExternalKeysDTO, 'Origin'));
            objectToAdd = lodash.uniqBy(objectToAdd, 'Origin');
            objectToAdd = lodash.uniqBy(objectToAdd, 'Key');
        } else {
            objectToAdd = newObjectExternalKeysDTO;
        }

        for (let i = 0; i < objectToRemove.length; i++) {
            externalKeyPatchData.push({op: 'remove', path: '/' + entityName + 'ExternalKeys', value: {Id: objectToRemove[i].Id}});
        }

        for (let j = 0; j < objectToAdd.length; j++) {
            externalKeyPatchData.push({op: 'add', path: '/' + entityName + 'ExternalKeys', value: objectToAdd[j]});
        }

        return externalKeyPatchData;
    }
}
