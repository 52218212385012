import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {retry, catchError} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';

@Injectable()
export class HttpRetryInterceptor implements HttpInterceptor {
    constructor(private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                retry(5),
                catchError(error => {
                    if (error.status === 401) {
                        window.open(environment.WEBAPP_OLD_BACKOFFICE_URL, '_self');
                        this.router.navigate(['notFound'], {state: {displayLoginAgainMessage: true}});
                    }
                    return throwError(error);
                }),
            );
    }
}
