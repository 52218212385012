import {Inject, Injectable} from '@angular/core';
import {HttpBaseProvider} from '../http-base/http-base.provider';
import {forkJoin, Observable, of} from 'rxjs';
import {map, share, tap} from 'rxjs/operators';
import {TenantConfigMapper} from './tenant-config.mapper';
import * as lodash from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class TenantConfigProvider {

  private environment;

  constructor(private httpBase: HttpBaseProvider,
              private tenantConfigMapper: TenantConfigMapper,
              @Inject('environment') environment) {
    this.environment = environment;
  }

  private tenantConfigCache: any = {};

  getTenantConfig(): Observable<any> {
    if (!lodash.isEmpty(this.tenantConfigCache)) {
      return of(this.tenantConfigCache);
    } else {
      return this.getTenantConfigDTO()
        .pipe(
          tap(response => this.saveTenantConfigIntoCache(response)),
          share());
    }
  }


  private getTenantConfigDTO(): Observable<any> {
    return forkJoin(
      this.getDisplayRules(),
      this.getValidationSets())
      .pipe(
        map(([displayRules, validationSet]) => {
          return {displayRules, validationSet};
        }));
  }

  private getDisplayRules(): Observable<any> {
    const URL = this.environment.BASE_URL + 'GetDisplayRules';
    return this.httpBase.get(URL)
      .pipe(
        map(response => this.tenantConfigMapper.autoMapDTOToEntity(response)
        ));
  }

  private getValidationSets(): Observable<any> {
    const URL = this.environment.BASE_URL + 'GetValidationSets';
    return this.httpBase.get(URL)
      .pipe(
        map(response => this.tenantConfigMapper.autoMapDTOToEntity(response)
        ));
  }

  private saveTenantConfigIntoCache(value: any): void {
    this.tenantConfigCache = lodash.cloneDeep(value);
  }
}
