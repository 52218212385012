import {Inject, Injectable} from '@angular/core';
import {IGenericProvider} from '../../data-model/genericProviderInterface';
import {Observable} from 'rxjs';
import {HttpBaseProvider} from '../http-base/http-base.provider';
import {HttpBaseProviderUtils} from '../http-base/http-base.provider.utils';
import {CenterProviderMapper} from './center.provider.mapper';
import {HttpHeaders} from '@angular/common/http';
import {RequestOptionsType} from '../../data-model/generalTypes';
import {map} from 'rxjs/operators';
import {ODataQueryObjectType} from '../../data-model/oDataObjectTypes';
import {GeneralProvider} from '../generalProvider';
import {compare, Operation} from 'fast-json-patch';
import * as lodash from 'lodash';
import {GeneralProviderMapper} from '../generalProviderMapper';
import {GeneralUtils} from '../../shared/utils/generalUtils';
import {
    CenterType,
    LocationDependentFiltersType, LocationSearchResultDTOType,
    LocationSearchResultType
} from '../../data-model/centerTypes';

@Injectable({
    providedIn: 'root'
})

export class CenterProvider implements IGenericProvider {
    private CENTERS = 'Centers';
    private CENTER = 'Center';
    public name: string;
    private environment;

    constructor(private httpBase: HttpBaseProvider,
                private httpBaseUtils: HttpBaseProviderUtils,
                private centerProviderMapper: CenterProviderMapper,
                private generalProvider: GeneralProvider,
                private generalProviderMapper: GeneralProviderMapper,
                private generalUtils: GeneralUtils,
                @Inject('environment') environment) {
        this.environment = environment;
        this.name = this.CENTER;
    }

    getDataSearching(queryFilter: LocationDependentFiltersType): Observable<LocationSearchResultType> {
        const FULL_URL = this.environment.BASE_URL + 'search/location(' +
            'searchPhrase=@sP,' +
            'includeAvailabilities=@iA,' +
            'onlyAssignedToUser=@oATU,' +
            'resourceId=@rId,' +
            'serviceId=@sId,' +
            'areaId=@aId)?' + this.getURlQueryFilterForGenericFilter(queryFilter).concat('&$top=100');

        return this.httpBase.get(FULL_URL, true)
            .pipe(map((response: LocationSearchResultDTOType) => {
                const result: LocationSearchResultType = {} as LocationSearchResultType;

                result.value = this.centerProviderMapper.mapFilterDataDTOToEntity(response.value);

                return result;
            }));
    }

    addEntry(item: CenterType): Observable<{ value: CenterType }> {
        const FULL_URL = this.environment.BASE_URL + this.CENTERS;

        return this.httpBase.post(FULL_URL, this.centerProviderMapper.mapItemToCenterDTO(item), false);
    }

    deleteEntry(id: string): Observable<any> {
        const FULL_URL = this.environment.BASE_URL + this.CENTERS + '(' + id + ')';

        return this.httpBase.delete(FULL_URL, false);
    }

    getEntityNameForCancelGraph(): string {
        return this.CENTERS;
    }

    getEntries(filter: ODataQueryObjectType): Observable<{ value: CenterType[], count?: number }> {
        const query = this.httpBaseUtils.getQuery(filter);
        const FULL_URL = this.environment.BASE_URL + this.CENTERS + query;

        return this.httpBase.get(FULL_URL, false);
    }

    updateEntry(oldCenter: CenterType, newCenter: CenterType): Observable<{ value: CenterType }> {
        const FULL_URL = this.environment.BASE_URL + this.CENTERS + '(' + newCenter.id + ')';
        const requestHeaderObject = {
            'Content-Type': 'application/json-patch+json',
            'If-Match': newCenter.etag
        };
        const requestHeader: HttpHeaders = new HttpHeaders(requestHeaderObject);
        const requestOptions: RequestOptionsType = {headers: requestHeader};

        const patchData: Operation[] = this.getCustomJsonPatch(oldCenter, newCenter);

        return this.httpBase.patch(FULL_URL, patchData, requestOptions, false);
    }

    getCustomJsonPatch(initialArray: CenterType, finalArray: CenterType) {
        let patchData = [];

        // JsonPatch objects for center without collections
        const oldCenterWithoutListsDTO = this.centerProviderMapper.mapCenterItemWithoutListsEntityToDTO(initialArray);
        oldCenterWithoutListsDTO.Address = this.generalProviderMapper.mapAddressItemToDTO(initialArray.address);
        const newCenterWithoutListsDTO = this.centerProviderMapper.mapCenterItemWithoutListsEntityToDTO(finalArray);
        newCenterWithoutListsDTO.Address = this.generalProviderMapper.mapAddressItemToDTO(finalArray.address);
        patchData = lodash.concat(patchData, compare(oldCenterWithoutListsDTO, newCenterWithoutListsDTO));

        // JsonPatch objects for Areas
        const oldCenterAreasDTO = this.generalProviderMapper.getIdsArray(initialArray.areas);
        const newCenterAreasDTO = this.generalProviderMapper.getIdsArray(finalArray.areas);
        const patchAreas = this.httpBaseUtils.getCustomJsonPatchArrayById(oldCenterAreasDTO, newCenterAreasDTO, 'Areas');
        patchData = lodash.concat(patchData, patchAreas);

        // JsonPatch objects for ExternalKeysList
        const patchExternalKeys = this.generalUtils.getJsonPatchForExternalKeys(initialArray.centerExternalKeys,
            finalArray.centerExternalKeys, this.CENTER);
        patchData = lodash.concat(patchData, patchExternalKeys);

        return patchData;
    }

    private getURlQueryFilterForGenericFilter(queryFilter: any): string {
        const queryArray: string[] = [];
        let url = '';

        //searchPhrase
        if (!queryFilter.searchPhrase) {
            queryFilter.searchPhrase = '';
        }
        queryArray.push('@sP=\'' + this.generalUtils.encodeStringAndRemoveInvalidChars(queryFilter.searchPhrase) + '\'');

        //includeAvailabilities
        if (queryFilter.includeAvailabilities === undefined) {
            queryFilter.includeAvailabilities = false;
        }
        queryArray.push('@iA=' + queryFilter.includeAvailabilities);

        //onlyAssignedToUser
        if (queryFilter.onlyAssignedToUser === undefined) {
            queryFilter.onlyAssignedToUser = false;
        }
        queryArray.push('@oATU=' + queryFilter.onlyAssignedToUser);

        //resourceId
        if (queryFilter.resourceId === undefined || queryFilter.resourceId === '') {
            queryFilter.resourceId = null;
        }
        queryArray.push('@rId=' + queryFilter.resourceId);

        //serviceId
        if (queryFilter.serviceId === undefined || queryFilter.serviceId === '') {
            queryFilter.serviceId = null;
        }
        queryArray.push('@sId=' + queryFilter.serviceId);

        //areaId
        if (queryFilter.areaId === undefined || queryFilter.areaId === '') {
            queryFilter.areaId = null;
        }
        queryArray.push('@aId=' + queryFilter.areaId);

        for (let i = 0; i < queryArray.length; i++) {
            url = url + queryArray[i];
            if (i < queryArray.length - 1) {
                url = url + '&';
            }
        }

        return url;
    }
}
