import * as lodash from 'lodash';
// import * as lodash from 'lodash';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class HttpBaseProviderMapper {
  constructor() {
  }

  public autoMapDTOToEntity(dtoObj: any) {
    const ODATA = '@odata.';
    // entity to return - we don't know if it's an object or an array
    let entity;

    // check type of object
    if (dtoObj instanceof Array) {
      // the type of the dto is array
      // initialize returned entity
      entity = [];

      for (const obj of dtoObj) {
        // recursively call the mapper for each item
        entity.push(this.autoMapDTOToEntity(obj));
      }

    } else if (dtoObj instanceof Object) {
      // the type of the dto is object
      // initialize returned entity
      entity = {};

      // loop over each attribute of the object
      for (const key in dtoObj) {
        if (dtoObj.hasOwnProperty(key)) {
          // change attribute name to lowerCase
          const attrName = lodash.lowerFirst(key);

          // check type of newly found object
          if (dtoObj[key] instanceof Array) {

            // initialize found object to array
            entity[attrName] = [];

            for (const itemArray of dtoObj[key]) {
              // recursively call the mapper for each sub item
              entity[attrName].push(this.autoMapDTOToEntity(itemArray));
            }
          } else if (dtoObj[key] instanceof Object) {
            // seams to be an object
            // recursively call the mapper for the found object
            entity[attrName] = this.autoMapDTOToEntity(dtoObj[key]);
          } else {
            // remove '@odata.' from attribute name
            if (lodash.startsWith(attrName, ODATA)) {
              entity[lodash.trimStart(attrName, ODATA)] = dtoObj[key];
            } else {
              entity[attrName] = dtoObj[key];
            }
          }
        }
      }
    } else {
      // a simple item has been passed just return it
      entity = dtoObj;
    }

    return entity;
  }
}
