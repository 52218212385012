import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    HostListener,
    ElementRef
} from '@angular/core';
import {UserInfoType} from './user-info.type';
import * as lodash from 'lodash';

@Component({
    selector: 'sbase-user-info',
    templateUrl: './user-info.component.html',
    styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit, OnChanges {
    isVisible = false;
    isLoggedIn: boolean;
    @Input() userInfo: UserInfoType;
    @Input() routerLink?: string | string[]; // if not provided, defaults to ['userProfile']

    constructor(private elementRef: ElementRef) {
    }

    @HostListener('document:click', ['$event'])
    click(e) {
        if (!this.elementRef.nativeElement.contains(e.target)) {
            this.isVisible = false;
        }
    }

    ngOnInit() {
        this.isLoggedIn = !lodash.isEmpty(this.userInfo);
        if (this.routerLink === undefined || this.routerLink === null) {
            this.routerLink = ['userProfile'];
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            const chng = changes[propName];
            if (propName === 'userInfo' && !chng.isFirstChange()) {
                this.userInfo = chng.currentValue;
                this.isLoggedIn = !lodash.isEmpty(this.userInfo);
            }
        }
    }

    getArrowIcon(): string {
        if (this.isVisible && this.isLoggedIn) {
            return 'mdi-menu-up';
        } else if (!this.isVisible && !this.isLoggedIn) {
            return '';
        } else if (!this.isVisible && this.isLoggedIn) {
            return 'mdi-menu-down';
        }
    }
}
