import {Inject, Injectable} from '@angular/core';
import {HttpBaseProvider} from '../http-base/http-base.provider';
import {HttpBaseProviderUtils} from '../http-base/http-base.provider.utils';
import {SystemConfigProviderMapper} from './system-config.provider.mapper';
import * as lodash from 'lodash';
import {HttpHeaders} from '@angular/common/http';
import {RequestOptionsType} from '../../data-model/generalTypes';
import {ODataQueryObjectType} from '../../data-model/oDataObjectTypes';
import {Observable} from 'rxjs';
import {SystemConfigurationType} from '../../data-model/systemConfigurationTypes';
import {compare, Operation} from 'fast-json-patch';

@Injectable({
  providedIn: 'root'
})

export class SystemConfigProvider {
  private environment;

  constructor(private httpBase: HttpBaseProvider,
              private httpBaseUtils: HttpBaseProviderUtils,
              private systemConfigProviderMapper: SystemConfigProviderMapper,
              @Inject('environment') environment) {
    this.environment = environment;
  }

  uploadSystemConfigurationImages(uploadPath: string, blob: Blob): Observable<any> {
    const FULL_URL = this.environment.BASE_URL + `SystemConfigurationImages/${uploadPath}`;
    const OPTIONS = { headers: { contentType: 'multipart/form-data' } };
    const imageFormData = new FormData();
    imageFormData.append('', blob);
    return this.httpBase.post(FULL_URL, imageFormData, true, OPTIONS);
  }

  public getSystemConfig(filter) {
    // TODO: add getQuery function
    // const query = this.httpBaseUtils.getQuery(filter);
    const query = '';
    const FULL_URL = this.environment.BASE_URL + 'SystemConfigurations' + query;
    return this.httpBase.get(FULL_URL, false);
  }

  public getSystemConfigDefaultValue(filter:ODataQueryObjectType) {
    const query = this.httpBaseUtils.getQuery(filter);
    const FULL_URL = this.environment.BASE_URL + 'SystemConfigurations/GetWithDefaultValues' + query;
    return this.httpBase.get(FULL_URL, false);
  }

  public getSystemConfigV3(filter: ODataQueryObjectType): Observable<{value: SystemConfigurationType[], count?: number}> {
    const query = this.httpBaseUtils.getQuery(filter);
    const FULL_URL = this.environment.BASE_URL + 'SystemConfigurations' + query;
    return this.httpBase.get(FULL_URL, false);
  }

  public getAllowedConfigurations(filter: ODataQueryObjectType): Observable<{value: SystemConfigurationType[], count?: number}> {
    const query = this.httpBaseUtils.getQuery(filter);
    const FULL_URL = this.environment.BASE_URL + 'SystemConfigurations/GetAllowedConfigurations' + query;
    return this.httpBase.get(FULL_URL, false);
  }

  updateEntry(oldItem: SystemConfigurationType, newItem: SystemConfigurationType): Observable<SystemConfigurationType> {
    const FULL_URL = this.environment.BASE_URL + 'SystemConfigurations(' + newItem.id + ')';
    const requestHeaderObject = {
      'Content-Type': 'application/json-patch+json',
      'If-Match': newItem.etag
    };
    const requestHeader: HttpHeaders = new HttpHeaders(requestHeaderObject);
    const requestOptions: RequestOptionsType = {headers: requestHeader};

    const patchData: Operation[] = this.getJsonPatchChanges(oldItem, newItem);

    return this.httpBase.patch(FULL_URL, patchData, requestOptions, false);
  }

  getJsonPatchChanges(oldItem: SystemConfigurationType, newItem: SystemConfigurationType): Operation[] {
    let patchData: Operation[] = [];

    // JsonPatch objects for items without collections
    const oldSystemConfigItem = this.systemConfigProviderMapper.mapItemToSystemConfigDTO(oldItem);
    const newSystemConfigItem = this.systemConfigProviderMapper.mapItemToSystemConfigDTO(newItem);
    patchData = lodash.concat(patchData, compare(oldSystemConfigItem, newSystemConfigItem));

    return patchData;
  }

  public getSystemConfigByName(name) {
    const FullUrl = this.environment.BASE_URL + 'SystemConfigurations?$filter=Name eq ' + name + '&$select=Id,Name,Value,RowVersion';
    return this.httpBase.get(FullUrl, false);
  }

  public updateSystemConfig(item) {
    // This method is obsolete in favor of updateEntry
    const FullUrl = this.environment.BASE_URL + 'SystemConfigurations(' + item.id + ')';
    const ifMatchHeader: HttpHeaders = new HttpHeaders().append('If-Match', item.etag);
    const requestOptions: RequestOptionsType = {headers: ifMatchHeader};

    return this.httpBase.patch(FullUrl, this.systemConfigProviderMapper.mapItemToSystemConfigDTO(item), requestOptions, false);
  }

  public getAllowedConfigurationKeys() {
    const FullUrl = this.environment.BASE_URL + 'SystemConfigurations/GetAllowedConfigurationKeys';
    return this.httpBase.get(FullUrl, false);
  }

  public getSchedulingConfigByType(type) {
    const FullUrl = this.environment.BASE_URL + 'SchedulingConfigurations?$filter=Type eq  WebApiModel.Enum.NotificationType\'' +
      lodash.upperFirst(type) + '\'';
    return this.httpBase.get(FullUrl, false);
  }

  public createSchedulingConfig(item) {
    const FullUrl = this.environment.BASE_URL + 'SchedulingConfigurations';
    const data = this.systemConfigProviderMapper.mapItemToSystemConfigDTO(item);
    return this.httpBase.post(FullUrl, data, false);
  }

  public updateSchedulingConfig(item) {
    const data = this.systemConfigProviderMapper.mapItemToSystemConfigDTO(item);
    const FullUrl = this.environment.BASE_URL + 'SchedulingConfigurations(' + item.id + ')';
    const ifMatchHeader: HttpHeaders = new HttpHeaders().append('If-Match', item.etag);
    const requestOptions: RequestOptionsType = {headers: ifMatchHeader};

    return this.httpBase.patch(FullUrl, data, requestOptions, false);
  }

  public getThirdPartySendGridApiKey() {
    const FullUrl = this.environment.BASE_URL + 'SystemConfigurations/GetThirdPartySendGridApiKey';
    return this.httpBase.get(FullUrl, false);
  }

  public updateValuesForThirdPartySendGridIntegration(sendGridObject) {
    const objToSend = this.systemConfigProviderMapper.mapItemToSystemConfigDTO(sendGridObject);
    const FullUrl = this.environment.BASE_URL + 'SystemConfigurations/UpdateValuesForThirdPartySendGridIntegration';
    return this.httpBase.post(FullUrl, objToSend, false);
  }

  public getApiKey(name) {
    const FullUrl = this.environment.BASE_URL + 'GetApiKey(Name=\'' + name + '\')';
    return this.httpBase.get(FullUrl, false);
  }

  public getApiKeysName() {
    const FullUrl = this.environment.BASE_URL + 'GetApiKeys()';
    return this.httpBase.get(FullUrl, false);
  }
}
