import {Inject, Injectable} from '@angular/core';
import {HttpBaseProvider} from '../http-base/http-base.provider';
import {HttpBaseProviderUtils} from '../http-base/http-base.provider.utils';
import {TagProviderMapper} from './tag.provider.mapper';
import {GeneralProvider} from '../generalProvider';
import {Observable} from 'rxjs';
import {ODataQueryObjectType} from '../../data-model/oDataObjectTypes';
import {TagResultType, TagsFiltersType, TagsType, TagType} from '../../data-model/tagTypes';
import {HttpHeaders} from '@angular/common/http';
import {RequestOptionsType} from '../../data-model/generalTypes';
import {compare, Operation} from 'fast-json-patch';

@Injectable({
    providedIn: 'root'
})

export class TagProvider {
    private environment;
    private TAGS = 'Tags';
    private TAG = 'Tag';
    public name: string;

    constructor(private httpBase: HttpBaseProvider,
                private httpBaseUtils: HttpBaseProviderUtils,
                private tagProviderMapper: TagProviderMapper,
                private generalProvider: GeneralProvider,
                @Inject('environment') environment) {
        this.environment = environment;
        this.name = this.TAG;
    }

    // GetTagsForEntity
    getTagsForEntity(filter: TagsFiltersType): Observable<{ value: TagResultType[] }> {
        const FULL_URL = this.environment.BASE_URL + 'Tags/GetTagsForEntity(EntityType=\'' + filter.entityType + '\',EntityId=' +
            filter.entityId + ')';

        return this.httpBase.get(FULL_URL);
    }

    getEntries(filter: ODataQueryObjectType): Observable<{ value: TagsType[], count?: number }> {
        const query = this.httpBaseUtils.getQuery(filter);
        const FULL_URL = this.environment.BASE_URL + this.TAGS + query;

        return this.httpBase.get(FULL_URL, false);
    }

    updateTagsForService(serviceId: string, tagIds: string[]) {
        const serviceObject = this.tagProviderMapper.mapEntityForUpdateTags('Service', serviceId);
        return this.updateTagsForEntity(serviceObject, tagIds);
    }

    updateTagsForResource(resourceId: string, tagIds: string[]) {
        const resource = this.tagProviderMapper.mapEntityForUpdateTags('Resource', resourceId);
        return this.updateTagsForEntity(resource, tagIds);
    }

    updateTagsForAvailability(availabilityId: string, tagIds: string[]) {
        const availability = this.tagProviderMapper.mapEntityForUpdateTags('Availability', availabilityId);
        return this.updateTagsForEntity(availability, tagIds);
    }

    updateTagsForAvailabilityService(availabilityServiceId: string, tagIds: string[]) {
        const availabilityService = this.tagProviderMapper.mapEntityForUpdateTags('AvailabilityService', availabilityServiceId);
        return this.updateTagsForEntity(availabilityService, tagIds);
    }

    updateTagsForEntity(entity: TagsFiltersType, tagIds: string[]) {
        const FULL_URL = this.environment.BASE_URL + 'Tags/UpdateTagsForEntity';

        const dataToSend = this.tagProviderMapper.mapUpdateTagsItemForEntityToDTO(entity, tagIds);

        return this.httpBase.post(FULL_URL, dataToSend, false);
    }

    public addEntry(item: TagType): Observable<{ value: TagType }> {
        const FULL_URL = this.environment.BASE_URL + this.TAGS;

        return this.httpBase.post(FULL_URL, this.tagProviderMapper.mapItemToTagDTO(item), false);
    }

    public updateEntry(oldTag: TagType, newTag: TagType): Observable<{ value: TagType }> {
        const FULL_URL = this.environment.BASE_URL + this.TAGS + '(' + newTag.id + ')';
        const requestHeaderObject = {
            'Content-Type': 'application/json-patch+json',
            'If-Match': newTag.etag
        };

        const requestHeader: HttpHeaders = new HttpHeaders(requestHeaderObject);
        const requestOptions: RequestOptionsType = {headers: requestHeader};

        const patch: Operation[] = compare(this.tagProviderMapper.mapItemToTagDTO(oldTag),
            this.tagProviderMapper.mapItemToTagDTO(newTag));

        return this.httpBase.patch(FULL_URL, patch, requestOptions);
    }

    public deleteEntry(id: string): Observable<any> {
        const FULL_URL = this.environment.BASE_URL + this.TAGS + '(' + id + ')';

        return this.httpBase.delete(FULL_URL);
    }

    public getEntityNameForCancelGraph(): string {
        return this.TAGS;
    }
}
