import {Inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {AccessConfigType} from './access-config.type';
// import {SessionStorage, SessionStorageService} from 'ngx-store';
import {HttpBaseProvider} from '../http-base/http-base.provider';
import {tap} from 'rxjs/operators';
import * as lodash from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AccessConfigProvider {
  private environment;

  constructor(private httpBase: HttpBaseProvider,
              @Inject('environment') environment) {
    this.environment = environment;
  }

  private accessConfigCache: any = [];

  getAccessConfig(): Observable<AccessConfigType[]> {
    if (!lodash.isEmpty(this.accessConfigCache)) {
      return of(this.accessConfigCache);
    } else {
      return this.getAccessConfigDTO();
    }
  }

  private getAccessConfigDTO(): Observable<AccessConfigType[]> {
    const URL = this.environment.BASE_URL + 'GetFeatureAccessConfiguration';
    return this.httpBase.get(URL)
      .pipe(
        tap(response => this.saveAccessConfigIntoCache(response)
        ));

  }

  private saveAccessConfigIntoCache(value: AccessConfigType[]): void {
    this.accessConfigCache = lodash.cloneDeep(value);
  }

}
