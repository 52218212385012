import {HttpBaseProviderUtils} from '../http-base/http-base.provider.utils';
import {Inject, Injectable} from '@angular/core';
import {HttpBaseProvider} from '../http-base/http-base.provider';
import {Observable} from 'rxjs';
import {ODataQueryObjectType} from '../../data-model/oDataObjectTypes';
import {ResourceTypeType} from '../../data-model/resourceTypeTypes';

@Injectable({
    providedIn: 'root'
})
export class ResourceTypeProvider {
    private environment;
    private RESOURCE_TYPES = 'ResourceTypes';

    constructor(private httpBaseUtils: HttpBaseProviderUtils,
                private httpBase: HttpBaseProvider,
                @Inject('environment') environment) {
        this.environment = environment;
    }

    getEntries(filter: ODataQueryObjectType): Observable<{ value: ResourceTypeType[], count?: number }> {
        const query = this.httpBaseUtils.getQuery(filter);
        const FULL_URL = this.environment.BASE_URL + this.RESOURCE_TYPES + query;

        return this.httpBase.get(FULL_URL);
    }
}
