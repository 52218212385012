import {NgModule} from '@angular/core';
import {BaseModulesComponent} from './base-modules.component';
import {LanguageComponent} from './components/language/language.component';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {ClickOutsideModule} from 'ng-click-outside';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {FullCalendarModule} from '@fullcalendar/angular';
import {ImagePreloadDirective} from './directives/image-preload/image-preload.directive';
import {BrowserModule} from '@angular/platform-browser';
import {ngfModule} from 'angular-file';
import {TextFieldModule} from '@angular/cdk/text-field';
import {UserInfoComponent} from './components/user-info/user-info.component';
import {FilterComponent} from './components/filter/filter.component';
import {TagComponent} from './components/tag/tag.component';
import {TagInputModule} from 'ngx-chips';

@NgModule({
    declarations: [BaseModulesComponent, LanguageComponent, ImagePreloadDirective,
        UserInfoComponent, FilterComponent, TagComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        RouterModule,
        ClickOutsideModule,
        TranslateModule,
        NgSelectModule,
        NgbModule,
        NgxUiLoaderModule,
        BrowserModule,
        FullCalendarModule,
        TextFieldModule,
        ngfModule,
        TagInputModule,
        // for FullCalendar!
    ],
    entryComponents: [],
    exports: [BaseModulesComponent, LanguageComponent, NgSelectModule, ImagePreloadDirective,
        UserInfoComponent, FilterComponent, TagComponent]
})
export class BaseModulesModule {
}
