import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {NavBarService} from './features/nav-bar/nav-bar.service';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {LocalJsonProvider} from './core/providers/localJson.provider';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {LoggerService} from './shared/services/logger.service';
import {RefreshPageToastrService} from './shared/services/refresh-page-toastr.service';
import {ConfigDataService} from './shared/services/config-data.service';
import {MessagesService} from './shared/services/messages.service';
import {TranslatedLanguageService} from './shared/services/translated-language.service';
import * as moment from 'moment';
import {HttpBaseProvider} from "./shared/base-modules/providers/http-base/http-base.provider";
import {Router} from "@angular/router";

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'redsalud-frontend-backoffice-new';
    toggledSideBar = false;
    isFullScreen;

    constructor(private jsonService: LocalJsonProvider,
                private ngxLoader: NgxUiLoaderService,
                private messagesService: MessagesService,
                private loggerService: LoggerService,
                private refreshPageService: RefreshPageToastrService,
                private navBarService: NavBarService,
                private httpBase: HttpBaseProvider,
                private translatedLanguageService: TranslatedLanguageService,
                private configData: ConfigDataService,
                private router: Router,
                private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        // subscribing to toggle side bar event
        this.subscribeToggleSideBar(this.navBarService.toggleSideBar$);
        this.subscribeToggleFullScreen(this.navBarService.toggleFullScreen$);
        //update moment locale date format
        this.setMomentLocaleDate();
        //get initial data only if the user is logged in
        this.getConfigData();
        // checking the app version
        this.checkAppVersion();
        //avoid “expression has changed after it was checked”
        this.changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
    }

    private subscribeToggleSideBar(toggleSideBar$) {
        toggleSideBar$
            .subscribe(toggleEvent => {
                    this.toggledSideBar = toggleEvent;
                },
                errorMessage => {
                    this.loggerService.log(errorMessage);
                });
    }

    private subscribeToggleFullScreen(toggleFullScreen$) {
        toggleFullScreen$
            .subscribe(toggleEvent => {
                    this.isFullScreen = toggleEvent;
                },
                errorMessage => {
                    this.loggerService.log(errorMessage);
                });
    }

    private getConfigData(): void {
        // this.configLoadingService.startLoading();
        this.ngxLoader.start();
        //todo get userId and initial config

        this.configData.getInitialConfigData()
            .subscribe(value => {
                this.router.navigate(['dashboard']);
                // const defaultTranslatedLanguageCode = this.translatedLanguageService.translatedLanguage;
                // if (defaultTranslatedLanguageCode) {
                //     this.translatedLanguageService.onSetLanguage(lodash.lowerCase(defaultTranslatedLanguageCode));
                // }

            }, error => {
                this.messagesService.handlingErrorMessage(error);
                this.ngxLoader.stop();
            }, () => {
                this.ngxLoader.stop();
            });

        this.translatedLanguageService.onSetLanguage('es-cl');

    }

    private setMomentLocaleDate(): void {
        moment.updateLocale('en', {
            longDateFormat: {
                // @ts-ignore
                dDM: 'ddd M/D',
                dateTime: 'MM/dd/yyyy, HH:mm', //used in dateFormatPipe
                fullDate: 'MM/DD/yyyy, HH:mm', //used in fullDateFormatPipe
                fullDateNoTime: 'MM/DD/yyyy', // same as fullDate but without time (used in FullDateNoTimeFormatPipe, DateEndOfDayPipe)
                shortDate: 'MMM dd yy',
                MMdd: 'MM/dd', //used in DateMMddFormatPipe
                MMddyyyy: 'MM/dd/yyyy', //used in DateMMddyyyFormatPipe
                MMDD: 'MM/DD', //used in NgbDateFixedOrMovableFormatter
                MMDDYYY: 'MM/DD/YYYY', //used in NgbDateFixedOrMovableFormatter
                Md: 'M/d', //used in infrastructure-reservation-modal.component.html
                dddd: 'dddd', // used in resource utilization
                MMDDcommaYYYY: 'MMMM DD, YYYY', // used in resource utilization
            }
        });
        moment.updateLocale('es', {
            longDateFormat: {
                // @ts-ignore
                dDM: 'ddd D/M',
                dateTime: 'dd-MM-yyyy, HH:mm', //used in dateFormatPipe
                fullDate: 'DD-MM-yyyy, HH:mm', //used in fullDateFormatPipe
                fullDateNoTime: 'DD-MM-yyyy', // same as fullDate but without time (used in FullDateNoTimeFormatPipe, DateEndOfDayPipe)
                shortDate: 'dd MMM yy',
                MMdd: 'dd-MM', //used in DateMMddFormatPipe
                MMddyyyy: 'dd-MM-yyyy', //used in DateMMddyyyFormatPipe
                MMDD: 'DD-MM', //used in NgbDateFixedOrMovableFormatter
                MMDDYYY: 'DD-MM-YYYY', //used in NgbDateFixedOrMovableFormatter
                Md: 'd-M', //used in infrastructure-reservation-modal.component.html
                dddd: 'dddd', // used in resource utilization
                MMDDcommaYYYY: 'DD MMMM, YYYY', // used in resource utilization
            }
        });
        moment.updateLocale('pt', {
            longDateFormat: {
                // @ts-ignore
                dDM: 'ddd D/M',
                dateTime: 'dd-MM-yyyy, HH:mm', //used in dateFormatPipe
                fullDate: 'DD-MM-yyyy, HH:mm', //used in fullDateFormatPipe
                fullDateNoTime: 'DD-MM-yyyy', // same as fullDate but without time (used in FullDateNoTimeFormatPipe, DateEndOfDayPipe)
                shortDate: 'dd MMM yy',
                MMdd: 'dd-MM', //used in DateMMddFormatPipe
                MMddyyyy: 'dd-MM-yyyy', //used in DateMMddyyyFormatPipe
                MMDD: 'DD-MM', //used in NgbDateFixedOrMovableFormatter
                MMDDYYY: 'DD-MM-YYYY', //used in NgbDateFixedOrMovableFormatter
                Md: 'd-M', //used in infrastructure-reservation-modal.component.html
                dddd: 'dddd', // used in resource utilization
                MMDDcommaYYYY: 'DD MMMM, YYYY', // used in resource utilization
            }
        });
    }

    private checkAppVersion() {
        setInterval(() => {
            this.refreshPageService.updateAppVersion();
        }, 3600000);
    }
}
