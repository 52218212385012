import {NgModule} from '@angular/core';
import {FeatureRoutingModule} from './feature-routing.module';
import {DashboardModule} from './dashboard/dashboard.module';
import {PageNotFoundModule} from './page-not-found/page-not-found.module';
import {SharedModule} from '../shared/shared.module';
import {NavBarModule} from './nav-bar/nav-bar.module';
import {NavBarComponent} from './nav-bar/nav-bar.component';
import {SideBarComponent} from './side-bar/side-bar.component';
import {SideBarModule} from './side-bar/side-bar.module';
import {FullCalendarModule} from '@fullcalendar/angular';
import {InfrastructureReservationModule} from './infrastructure-reservation/infrastructure-reservation.module';

@NgModule({
    declarations: [],
    imports: [
        FeatureRoutingModule,
        DashboardModule,
        PageNotFoundModule,
        SharedModule,
        NavBarModule,
        SideBarModule,
        FullCalendarModule,
        InfrastructureReservationModule
    ],
    exports: [FeatureRoutingModule, NavBarComponent, SideBarComponent]
})
export class FeaturesModule {
}
