import {Injectable} from '@angular/core';
import {GeneralProviderMapper} from '../generalProviderMapper';
import {
    ServiceDTOType, ServiceSearchDTOType, ServiceSearchResultDTOType, ServiceSearchResultType, ServiceSearchType,
    ServiceType
} from '../../data-model/serviceTypes';
import * as lodash from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class ServiceProviderMapper {
    constructor(private generalProviderMapper: GeneralProviderMapper) {
    }

    public mapServicesDTOToEntity(servicesDTO: ServiceDTOType[]): ServiceType[] {
        const specialityServices: ServiceType[] = [];
        for (const value of servicesDTO) {
            specialityServices.push(this.mapServiceDTOToEntity(value));
        }

        return specialityServices;
    }

    public mapServiceDTOToEntity(serviceDTO: ServiceDTOType): ServiceType {
        const service: ServiceType = {} as ServiceType;

        service.name = serviceDTO.Name;
        service.defaultDuration = serviceDTO.DefaultDuration;
        service.areaId = serviceDTO.AreaId;
        service.subAreaId = serviceDTO.SubAreaId;
        service.checkinDisabled = serviceDTO.CheckinDisabled;
        service.shortId = serviceDTO.ShortId;
        service.specialityId = serviceDTO.SpecialityId;
        service.multiResourceBluePrintId = serviceDTO.MultiResourceBluePrintId;
        service.bookWithRole = serviceDTO.BookWithRole;
        service.immediateVisit = serviceDTO.ImmediateVisit;
        service.trackCareCore = serviceDTO.TrackCareCore;
        service.iconUrl = serviceDTO.IconUrl;
        service.excludeFromWaitList = serviceDTO.ExcludeFromWaitList;
        service.excludeForNonExistentTreatmentPlans = serviceDTO.ExcludeForNonExistentTreatmenPlans;
        service.id = serviceDTO.Id;

        return service;
    }

    public mapItemToServiceDTO(service: ServiceType): ServiceDTOType {
        const serviceDTO: ServiceDTOType = this.mapItemWithoutListsEntityToDTO(service);

        if (service?.channelRestrictions?.length > 0) {
            serviceDTO.ChannelRestrictions = this.generalProviderMapper.mapChannelsEntityToDTO(service.channelRestrictions);
        }

        if (service?.centers?.length > 0) {
            serviceDTO.Centers = this.generalProviderMapper.getIdsArray(service.centers);
        }

        if (service?.visitReasons?.length > 0) {
            serviceDTO.VisitReasons = this.generalProviderMapper.getIdsArray(service.visitReasons);
        }

        if (service?.coveragePlans?.length > 0) {
            serviceDTO.CoveragePlans = this.generalProviderMapper.getIdsArray(service.coveragePlans);
        }

        if (service?.appointmentPreconditionFlags?.length > 0) {
            serviceDTO.AppointmentPreconditionFlags = this.generalProviderMapper.getIdsArray(service.appointmentPreconditionFlags);
        }

        if (service?.resources?.length > 0) {
            serviceDTO.Resources = this.generalProviderMapper.getIdsArray(service.resources);
        }

        if (service?.serviceExternalKeys?.length > 0) {
            serviceDTO.ServiceExternalKeys = this.generalProviderMapper.mapExternalKeysEntityToDTO(service.serviceExternalKeys);
        }

        if (service?.oversellingDefinitions?.length > 0) {
            serviceDTO.OversellingDefinitions = this.generalProviderMapper.getIdsArray(service.oversellingDefinitions);
        }

        return serviceDTO;
    }

    public mapItemWithoutListsEntityToDTO(service: ServiceType): ServiceDTOType {
        const serviceDTO: ServiceDTOType = {} as ServiceDTOType;
        serviceDTO.Id = service.id;
        serviceDTO.Name = service.name;
        serviceDTO.DefaultDuration = service.defaultDuration;
        serviceDTO.AreaId = service.areaId;
        serviceDTO.SubAreaId = service.subAreaId;
        serviceDTO.CheckinDisabled = service.checkinDisabled;
        serviceDTO.SpecialityId = service.specialityId;

        if (service.multiResourceBluePrintId) {
            serviceDTO.MultiResourceBluePrintId = service.multiResourceBluePrintId;
        } else {
            serviceDTO.MultiResourceBluePrintId = null;
        }

        serviceDTO.BookWithRole = service.bookWithRole;
        serviceDTO.ImmediateVisit = service.immediateVisit;
        serviceDTO.TrackCareCore = service.trackCareCore;
        serviceDTO.IconUrl = service.iconUrl;
        serviceDTO.ExcludeFromWaitList = service.excludeFromWaitList;
        serviceDTO.ExcludeForNonExistentTreatmenPlans = service.excludeForNonExistentTreatmentPlans;

        return serviceDTO;
    }

    public mapFilterDataDTOToEntity(servicesDTO: ServiceSearchDTOType[]): ServiceSearchType[] {
        const resourcesEntity: ServiceSearchType[] = [];

        for (let i = 0; i < servicesDTO.length; i++) {
            resourcesEntity.push(this.mapFilterItemDTOToEntity(servicesDTO[i]));
        }

        return lodash.orderBy(resourcesEntity, ['name']);
    }

    public mapFilterItemDTOToEntity(serviceDTO: ServiceSearchDTOType): ServiceSearchType {
        const serviceItemEntity: ServiceSearchType = {} as ServiceSearchType;

        serviceItemEntity.id = serviceDTO.ServiceId;
        serviceItemEntity.name = serviceDTO.Name;
        serviceItemEntity.specialityId = serviceDTO.SpecialityId;
        serviceItemEntity.specialityName = serviceDTO.SpecialityName;
        serviceItemEntity.iconUrl = serviceDTO.IconUrl;

        return serviceItemEntity;
    }
}
