<div class="navbar-collapse language-component">
    <ul class="navbar-nav my-lg-0">
        <li class="nav-item dropdown" (click)="isVisible = !isVisible"
            [ngClass]="{'show':isVisible}">
            <a class="nav-link dropdown-toggle waves-effect waves-dark"
               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="flag-icon"
                   *ngIf="selectedLanguage.name==='spanishPE'"
                   [ngClass]="'flag-icon-pe'"></i>
                <i class="flag-icon"
                   *ngIf="selectedLanguage.name!=='spanishPE'"
                   [ngClass]=" 'flag-icon-'+ getNameCountry(selectedLanguage)"></i>
            </a>
            <div *ngIf='isVisible' class="dropdown-menu dropdown-menu-right animated bounceInDown"
                 [ngClass]="{'show':isVisible}"
            >
                <a class="dropdown-item"
                   *ngFor='let language of languages' (click)="onSelectedLanguage(language)">
                    <i class="flag-icon"
                       *ngIf="language.name==='spanishPE'"
                       [ngClass]="'flag-icon-pe'"></i>
                    <i class="flag-icon"
                       *ngIf="language.name!=='spanishPE'"
                       [ngClass]="'flag-icon-'+ getNameCountry(language)"></i>
                    {{"label.languages." + language.name|translate}}
                </a>
            </div>
        </li>
    </ul>
</div>
