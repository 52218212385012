import {ServiceProviderMapper} from '../service/service.provider.mapper';
import {Injectable} from '@angular/core';
import * as lodash from 'lodash';
import {CountryProviderMapper} from '../country/country.provider.mapper';
import {GeneralProviderMapper} from '../generalProviderMapper';
import {
    CenterDTOType,
    CenterType, LocationSearchDTOType, LocationSearchGroupDTOType, LocationSearchGroupType,
    LocationSearchType
} from '../../data-model/centerTypes';
import {ResourceProviderMapper} from '../resource/resource.provider.mapper';

@Injectable({
    providedIn: 'root'
})

export class CenterProviderMapper {

    constructor(private serviceProviderMapper: ServiceProviderMapper,
                private countryProviderMapper: CountryProviderMapper,
                private resourceProviderMapper: ResourceProviderMapper,
                private generalProviderMapper: GeneralProviderMapper
    ) {
    }

    public mapCentersDTOToEntity(centersDTO: CenterDTOType[]): CenterType[] {
        const centers: CenterType[] = [];
        for (const centerDTO of centersDTO) {
            centers.push(this.mapCenterDTOToEntity(centerDTO));
        }

        return centers;
    }

    mapFilterDataDTOToEntity(regionsLocationsDTO: LocationSearchGroupDTOType[]): LocationSearchGroupType[] {
        const regionLocationsEntity: LocationSearchGroupType[] = [];

        for (const regionLocationDTO of regionsLocationsDTO) {
            regionLocationsEntity.push(this.mapFilterItemDTOToEntity(regionLocationDTO));
        }

        return this.mapRegionLocation(lodash.orderBy(regionLocationsEntity, ['name']));
    }

    private mapRegionLocation(regionLocationsEntityList: LocationSearchGroupType[]): any {
        const regionLocationList = [];

        lodash.each(regionLocationsEntityList, (item) => {
            // add the isParent flag when is a region
            // @ts-ignore
            item.isParent = true;
            // push the region
            regionLocationList.push(item);
            const locations = lodash.orderBy(lodash.pick(item, 'locations').locations, 'name');
            // added the ignore here because the compiler complains that the isParent property
            // doesn't exist on the location type
            // (not sure how we come up setting a property on an array
            // since I don't know where this is used it will stay like this for now)
            // @ts-ignore
            locations.isParent = true;
            // push the locations
            regionLocationList.push(lodash.orderBy(lodash.pick(item, 'locations').locations, 'name'));
        });

            // flatten the array
        return lodash.flatten(regionLocationList);
    }

    private mapFilterItemDTOToEntity(regionDTO: LocationSearchGroupDTOType): LocationSearchGroupType {
        const regionEntity: LocationSearchGroupType = {} as LocationSearchGroupType;
        regionEntity.id = regionDTO.RegionId;
        regionEntity.name = regionDTO.RegionName;
        regionEntity.locations = this.mapFilterLocationsDataDTOToEntity(regionDTO.Locations);

        return regionEntity;
    }

    public mapFilterLocationsDataDTOToEntity(locationsDTO: LocationSearchDTOType[]): LocationSearchType[] {
        const locationsEntity: LocationSearchType[] = [];

        for (const locationDTO of locationsDTO) {
            locationsEntity.push(this.mapFilterLocationItemDTOToEntity(locationDTO));
        }
        return lodash.orderBy(locationsEntity, ['name']);
    }

    private mapFilterLocationItemDTOToEntity(locationDTO: LocationSearchDTOType): LocationSearchType {
        const locationEntity: LocationSearchType = {} as LocationSearchType;

        locationEntity.id = locationDTO.Id;
        locationEntity.name = locationDTO.Name;
        locationEntity.latitude = locationDTO.Latitude;
        locationEntity.longitude = locationDTO.Longitude;
        locationEntity.regionId = locationDTO.RegionId;
        locationEntity.regionName = locationDTO.RegionName;

        return locationEntity;
    }

    public mapCenterDTOToEntity(centerDTO: CenterDTOType): CenterType {
        const center: CenterType = {} as CenterType;

        center.id = centerDTO.Id;
        center.name = centerDTO.Name;
        center.countryId = centerDTO.CountryId;
        center.regionId = centerDTO.RegionId;
        center.latitude = centerDTO.Latitude;
        center.longitude = centerDTO.Longitude;
        center.parkingArea = centerDTO.ParkingArea;
        center.mainPhoneNumber = centerDTO.MainPhoneNumber;
        center.alternatePhoneNumber = centerDTO.AlternatePhoneNumber;
        center.email = centerDTO.Email;
        center.openingHours = centerDTO.OpeningHours;
        center.closingHours = centerDTO.ClosingHours;
        center.directorOfCenter = centerDTO.DirectorOfCenter;
        center.medicalDirector = centerDTO.MedicalDirector;
        center.dentalDirector = centerDTO.DentalDirector;
        center.chiefNurse = centerDTO.ChiefNurse;
        center.dentalSupervisor = centerDTO.DentalSupervisor;
        center.metroStations = centerDTO.MetroStations;
        center.busStations = centerDTO.BusStations;
        center.taxiNumbers = centerDTO.TaxiNumbers;
        center.timeZoneId = centerDTO.TimeZoneId;
        center.shortId = centerDTO.ShortId;
        center.alternatePhoneNumber = centerDTO.AlternatePhoneNumber;
        center.email = centerDTO.Email;

        if (centerDTO.Services) {
            center.services = this.serviceProviderMapper.mapServicesDTOToEntity(centerDTO.Services);
        }
        if (centerDTO.Address) {
            center.address = this.generalProviderMapper.mapAddressDTOToEntity(centerDTO.Address);
        }
        if (centerDTO.Resources) {
            center.resources = this.resourceProviderMapper.mapResourcesDTOToEntity(centerDTO.Resources);
        }

        return center;
    }

    public mapItemToCenterDTO(centerData: CenterType): CenterDTOType {
        const centerItemDTO = this.mapCenterItemWithoutListsEntityToDTO(centerData);

        if (centerData.services) {
            centerItemDTO.Services = this.generalProviderMapper.getIdsArray(centerData.services);
        }

        if (centerData.resources) {
            centerItemDTO.Resources = this.generalProviderMapper.getIdsArray(centerData.resources);
        }

        if (centerData.areas) {
            centerItemDTO.Areas = this.generalProviderMapper.getIdsArray(centerData.areas);
        }

        if (centerData.centerExternalKeys) {
            centerItemDTO.CenterExternalKeys = this.generalProviderMapper.mapExternalKeysEntityToDTO(centerData.centerExternalKeys);
        }

        if (centerData.address) {
            centerItemDTO.Address = this.generalProviderMapper.mapAddressItemToDTO(centerData.address);
        }

        return centerItemDTO;
    }

    public mapCenterItemWithoutListsEntityToDTO(center: CenterType): CenterDTOType {
        const centerItemDTO: CenterDTOType = {} as CenterDTOType;

        centerItemDTO.Id = center.id;
        centerItemDTO.Name = center.name;
        centerItemDTO.CountryId = center.countryId;
        centerItemDTO.RegionId = center.regionId;
        if (center.address) {
            centerItemDTO.Address = this.generalProviderMapper.mapAddressItemToDTO(center.address);
        }
        centerItemDTO.Latitude = center.latitude;
        centerItemDTO.Longitude = center.longitude;
        centerItemDTO.ParkingArea = center.parkingArea;
        centerItemDTO.MainPhoneNumber = center.mainPhoneNumber;
        centerItemDTO.AlternatePhoneNumber = center.alternatePhoneNumber;
        centerItemDTO.Email = center.email;
        centerItemDTO.OpeningHours = center.openingHours;
        centerItemDTO.ClosingHours = center.closingHours;
        centerItemDTO.DirectorOfCenter = center.directorOfCenter;
        centerItemDTO.MedicalDirector = center.medicalDirector;
        centerItemDTO.DentalDirector = center.dentalDirector;
        centerItemDTO.ChiefNurse = center.chiefNurse;
        centerItemDTO.DentalSupervisor = center.dentalSupervisor;
        centerItemDTO.MetroStations = center.metroStations;
        centerItemDTO.BusStations = center.busStations;
        centerItemDTO.TaxiNumbers = center.taxiNumbers;
        centerItemDTO.TimeZoneId = center.timeZoneId;
        centerItemDTO.ShortId = center.shortId;

        return centerItemDTO;
    }
}
