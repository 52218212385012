import {Inject, Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {ILanguage} from './language-config.type';
import {HttpBaseProvider} from '../http-base/http-base.provider';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LanguageConfigProvider {
  private environment;
  private languageConfigCache: ILanguage[] = [];

  constructor(private httpBase: HttpBaseProvider,
              @Inject('environment') environment) {
    this.environment = environment;
  }

  getLanguageConfig(): Observable<ILanguage[]> {
    if (this.languageConfigCache && this.languageConfigCache.length > 0) {
      return of(this.languageConfigCache);
    } else {
      return this.getLanguages();
    }
  }

  getLanguages(): Observable<ILanguage[]> {
    const URL = 'assets/json/languages.json';
    return this.httpBase.get(URL)
      .pipe(
        tap(data => JSON.stringify(data))
      );
  }
}
