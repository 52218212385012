import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpBaseProviderMapper} from './http-base.provider.mapper';
import {RequestOptionsType} from '../../data-model/generalTypes';

@Injectable({
    providedIn: 'root'
})
export class HttpBaseProvider {

    constructor(private http: HttpClient,
                private httpBaseProviderMapper: HttpBaseProviderMapper) {
    }

    get(url: string, useCustomMapper?: boolean, responseTypeOption?: any): Observable<any> {
        const self = this;
        let httpGetOptions;
        if (responseTypeOption) {
            httpGetOptions = {responseType: responseTypeOption};
        }
        return this.http.get(url, httpGetOptions)
            .pipe(
                map((response: any) => {
                    // default: use autoMapper
                    return !useCustomMapper ? self.httpBaseProviderMapper.autoMapDTOToEntity(response) : response;
                })
            );
    }


    post(url: string, body: any, useCustomMapper?: boolean, options: any = {}): Observable<any> {
        const self = this;
        return this.http.post(url, body, options)
            .pipe(
                map((response: any) => {
                    // default: use autoMapper
                    return !useCustomMapper ? self.httpBaseProviderMapper.autoMapDTOToEntity(response) : response;
                })
            );
    }

    patch(url: string, body: any, requestOptions?: RequestOptionsType, useCustomMapper?: boolean): Observable<any> {
        const self = this;

        return this.http.patch(url, body, requestOptions)
            .pipe(
                map((response: any) => {
                    // default: use autoMapper
                    return !useCustomMapper ? self.httpBaseProviderMapper.autoMapDTOToEntity(response) : response;
                })
            );
    }

    delete(url: string, useCustomMapper?: boolean): Observable<any> {
        const self = this;
        return this.http.delete(url)
            .pipe(
                map((response: any) => {
                    // default: use autoMapper
                    return !useCustomMapper ? self.httpBaseProviderMapper.autoMapDTOToEntity(response) : response;
                })
            );
    }

    download(url: string): Observable<{ file: Blob, fileName: string }> {
        return this.http.get(url, {
            responseType: 'blob',
            observe: 'response'
        }).pipe(
            map((response: HttpResponse<Blob>) => {
                // header looks like --> content-disposition: attachment; filename="filename.extn"
                const contentDisposition = response.headers.get('content-disposition');
                // select value filename="filename.extn"
                const name = contentDisposition.split(';')[1].trim().split('=')[1];
                const data = {
                    file: new Blob([response.body], {type: response.headers.get('content-type')}),
                    fileName: name
                };
                return data;
            })
        );
    }
}
