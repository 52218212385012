import {Injectable} from '@angular/core';
import {SessionStorageService, LocalStorageService} from '@efaps/ngx-store';
import {BehaviorSubject, forkJoin, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import * as lodash from 'lodash';
import {constants} from '../constants/constants';
import {AccessConfigProvider} from '../base-modules/providers/access-config/access-config.provider';
import {TenantConfigProvider} from '../base-modules/providers/tenant-config/tenant-config.provider';
import {SystemConfigProvider} from '../base-modules/providers/system-config/system-config.provider';
import {ODataQueryObjectType} from '../base-modules/data-model/oDataObjectTypes';

@Injectable({
    providedIn: 'root'
})
export class ConfigDataService {
    constructor(private accessConfigProvider: AccessConfigProvider,
                private tenantConfigProvider: TenantConfigProvider,
                private systemConfigProvider: SystemConfigProvider,
                private sessionStorageService: SessionStorageService,
                private localStorageService: LocalStorageService) {
    }


    initialConfigRequestFinish: BehaviorSubject<any> = new BehaviorSubject(false);

    get systemConfig() {
        const systemConfig = this.getConfigFromStorage(constants.SYSTEM_CONFIG_STORAGE_NAME);
        return systemConfig || null;
    }

    get featureAccessConfig() {
        const featureAccessConfig = this.getConfigFromStorage(constants.ACCESS_CONFIG_STORAGE_NAME);
        return featureAccessConfig || null;
    }

    get userData() {
        const userData = this.getConfigFromStorage(constants.USER_INFO_STORAGE_NAME);
        return userData || null;
    }

    getInitialConfigData() {
        return forkJoin([
            this.getAccessConfigData(),
            this.getTenantConfig(),
            this.getSystemConfig()
        ])
            .pipe(
                tap(() => {
                    this.initialConfigRequestFinish.next(true);
                }),
                map(([accessConfig, tenantConfig, systemConfig]) => {
                    return {accessConfig, tenantConfig, systemConfig};
                })
            );
    }

    getAccessConfigData() {
        // get accessConfig from browser storage
        const storedConfig = this.getConfigFromStorage(constants.ACCESS_CONFIG_STORAGE_NAME);
        // if it is not empty return saved data, otherwise make the request
        if (!lodash.isEmpty(storedConfig)) {
            return of(storedConfig);
        } else {
            return this.accessConfigProvider.getAccessConfig()
                .pipe(
                    tap(response => {
                            // save response into browser storage
                            this.setConfigToStorage(constants.ACCESS_CONFIG_STORAGE_NAME, response);
                        }
                    ));
        }
    }

    getTenantConfig() {
        // get accessConfig from browser storage
        const storedConfig = this.getConfigFromStorage(constants.TENANT_CONFIG_STORAGE_NAME);
        // if it is not empty return saved data, otherwise make the request
        if (!lodash.isEmpty(storedConfig)) {
            return of(storedConfig);
        } else {
            return this.tenantConfigProvider.getTenantConfig()
                .pipe(
                    tap(response => {
                            // save response into browser storage
                            this.setConfigToStorage(constants.TENANT_CONFIG_STORAGE_NAME, response);
                        }
                    ));
        }
    }

    getSystemConfig() {
        // get sysConfig from browser storage
        const storedConfig = this.getConfigFromStorage(constants.SYSTEM_CONFIG_STORAGE_NAME);
        // if it is not empty return saved data, otherwise make the request
        if (!lodash.isEmpty(storedConfig)) {
            return of(storedConfig);
        } else {
            return this.systemConfigProvider.getSystemConfigV3(this.getSystemConfigQueryFilter())
                .pipe(
                    tap(response => {
                            // save response into browser storage
                            // this.setConfigToStorage(constants.SYSTEM_CONFIG_STORAGE_NAME, response);
                        }
                    ));
        }
    }

    getUserByToken() {
        const storedUserInfo = this.getConfigFromStorage(constants.USER_INFO_STORAGE_NAME);
        if (!lodash.isEmpty(storedUserInfo)) {
            return of(storedUserInfo);
        } else {
          //todo get logged in user data
        }
    }

    isFeatureActive(featureName: string): boolean {
        const featureAccess = this.featureAccessConfig;
        if (featureAccess === null) {
            return false;
        }
        return featureAccess[featureName] && featureAccess[featureName] === 'true';
    }

    getConfigFromStorage(storageName: string) {
        return this.sessionStorageService.get(storageName);
    }

    private setConfigToStorage(storageName: string, value: any) {
        this.sessionStorageService.set(storageName, value);
    }

    private getSystemConfigQueryFilter() {
        const query: any = {};
        query.select = ['Id', 'Name', 'Value', 'RowVersion'];
        return query;
    }

    setValueToLocalStorage(key: string, value: string) {
        this.localStorageService.set(key, value);
    }

    getValueFromLocalStorage(key: string) {
        return this.localStorageService.get(key);
    }

    clearBrowserStorages() {
        this.localStorageService.clear();
        this.sessionStorageService.clear();
    }

    private getQueryFilterForUserInfo(): ODataQueryObjectType {
        return {
            select: ['Id', 'Username', 'FirstName', 'LastName', 'System', 'Email'],
            expand: {
                Centers: {select: ['Id', 'Name']},
                Resource: {select: ['Id', 'Name']},
            }
        };
    }

    //check feature access is enabled for tenant
    checkFeatureAccessByRoute(routeName: string) {
        const featureAccess = this.featureAccessConfig;
        const key = this.getFeatureAccessForSelectedRoute(routeName);

        if (featureAccess[key] !== undefined) {
            return featureAccess[key] === 'true';
        } else {
            return false;
        }
    }

    private getFeatureAccessForSelectedRoute(route: string) {
        const routeFeature = {
            //routeName : 'feature access for this route name'
            home: 'home'
        };

        return routeFeature[route];
    }
}
