import {ConfigDataService} from './../services/config-data.service';
import {Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({
    selector: '[featureDisplay]'
})
export class FeatureDisplayDirective implements OnInit {

    constructor(private el: ElementRef, private configDataService: ConfigDataService) {
    }

    //fetching the feature value from directive input
    @Input('featureDisplay') featureName: string | string[];

    ngOnInit() {
        this.checkFeatureAccess();
    }

    //function to display the element based on access given
    checkFeatureAccess() {
        //do nothing if the session storage value is null
        if (this.configDataService.featureAccessConfig) {
            //fetching ngx-access from session storage
            if (typeof this.featureName === 'string') {
                const access: boolean = this.configDataService.isFeatureActive(this.featureName);
                !access ? this.el.nativeElement.style.display = 'none' : '';
            } else {
                if (this.featureName && this.featureName.length > 0) {
                    this.el.nativeElement.style.display = 'none';
                    for (const feature of this.featureName) {
                        const access: boolean = this.configDataService.isFeatureActive(feature);
                        if (access) {
                            this.el.nativeElement.style.display = '';
                            return;
                        }
                    }
                }
            }
        }
    }
}




