<div class="row">
    <div class="col-9">
        <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title" class="custom-title">
            {{ title }}
        </div>
        <div role="alert" aria-live="polite" class="custom-message" [class]="options.messageClass"
            [attr.aria-label]="message">
            <p class="custom-message-text" translate="label.versionUpgrade"></p>
        </div>
    </div>
    <div class="col-3 text-right">
        <a *ngIf="options.closeButton" (click)="remove()" class="btn toast-close-button">
            &times;
        </a>
    </div>
    <div class="col-12">
        <button (click)="reloadPage()" class="btn btn-sm toast-btn-info" translate="label.reloadPage"></button>
    </div>

</div>