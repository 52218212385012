import {Component, EventEmitter, Input, OnInit, Output, HostListener, ElementRef} from '@angular/core';
import {LanguageConfigProvider} from '../../providers/language/language-config.provider';
import {ILanguage} from '../../providers/language/language-config.type';
import {take} from 'rxjs/operators';
import * as lodash from 'lodash';

@Component({
    selector: 'sbase-language',
    templateUrl: './language.component.html',
    styleUrls: ['./language.component.css'],
})

export class LanguageComponent implements OnInit {
    languages: ILanguage[] = [];
    isVisible = false;
    selectedLanguage: ILanguage = {} as ILanguage;
    @Input() initialLanguageCode: string;
    @Output() onSelectedLanguageOutput: EventEmitter<string> = new EventEmitter<string>();

    constructor(private languageProvider: LanguageConfigProvider,
                private elementRef: ElementRef) {
    }

    @HostListener('document:click', ['$event'])
    click(e) {
        if (!this.elementRef.nativeElement.contains(e.target)) {
            this.isVisible = false;
        }
    }

    ngOnInit() {
        this.getLanguages(this.initialLanguageCode);
    }

    getLanguages(initialLanguageCode: string): void {
        this.languageProvider.getLanguageConfig()
            .pipe(take(1))
            .subscribe(
                languages => {
                    this.languages = languages;
                    this.selectedLanguage = this.getSelectedLanguage(languages, initialLanguageCode);
                }, (error) => {

                }, () => {

                });
    }

    getSelectedLanguage(languages: ILanguage[], initialLanguageCode: string): ILanguage {
        if (initialLanguageCode === undefined) {
            return languages[0];
        }
        const foundLanguage = lodash.find(languages, (language) => {
            return language.code.indexOf(initialLanguageCode) > -1;
        });
        return foundLanguage ? foundLanguage : languages[0];
    }


    getNameCountry(language: ILanguage): string {
        if (language && language.code) {
            return language.code.split('-')[1];
        }
    }

    onSelectedLanguage(language: ILanguage): void {
        this.selectedLanguage = language;
        this.onSelectedLanguageOutput.emit(this.getNameCountry(this.selectedLanguage));
    }
}
