import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SideBarComponent} from './side-bar.component';
import {FeatureRoutingModule} from 'src/app/features/feature-routing.module';
import {SharedModule} from '../../shared/shared.module';

@NgModule({
    declarations: [SideBarComponent],
    imports: [
        CommonModule,
        FeatureRoutingModule,
        SharedModule
    ],
    exports: [SideBarComponent]
})
export class SideBarModule {
}
