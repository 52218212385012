import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {GeneralUtils} from '../../shared/utils/general.utils';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RequestAuthenticatedUserGuard implements CanActivate {

    constructor(private router: Router, private generalUtils: GeneralUtils) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> |
        Promise<boolean | UrlTree> | boolean | UrlTree {

        //the qa2 check is a hack to allow routing to the dashboard UI because we don't have XSRF token for QA currently
        if (this.generalUtils.hasAccessToken() || environment.BASE_URL.indexOf('proxy.qa2')) {
            return true;
        } else {
            this.router.navigate(['notFound']);
            return false;
        }
    }
}
