<div class="navbar-collapse user-info-component">
    <ul class="navbar-nav my-lg-0">
        <li class="nav-item dropdown"
            *ngIf="isLoggedIn"
            (click)="isVisible = !isVisible"
            [ngClass]="{show:isVisible}">
            <a class="nav-link dropdown-toggle waves-effect waves-dark"
               data-toggle="dropdown"
               aria-haspopup="true"
               aria-expanded="false">
                <img src="assets/images/user-profile.png" alt="user"
                     class="profile-pic"/>
                <span class="name-user">
                    <span>{{userInfo?.fullname}}</span>
                    <i class="mdi"[ngClass]="getArrowIcon()"></i>
                </span>
            </a>
        </li>
    </ul>
</div>
