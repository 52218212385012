import {Injectable} from '@angular/core';
import {CountryDTOType, CountryType} from '../../../../data-model/countryTypes';
import {GeneralProviderMapper} from '../generalProviderMapper';

@Injectable({
    providedIn: 'root'
})

export class CountryProviderMapper {
    constructor(private generalProviderMapper: GeneralProviderMapper) {
    }

    public mapCountriesDTOToEntity(countryDTO: CountryDTOType[]): CountryType[] {
        const countries: CountryType[] = [];

        for (const value of countryDTO) {
            countries.push(this.mapCountryDTOToEntity(value));
        }

        return countries;
    }

    public mapCountryDTOToEntity(countryDTO: CountryDTOType): CountryType {
        const country: CountryType = {} as CountryType;

        country.countryCode = countryDTO.CountryCode;
        country.countryName = countryDTO.CountryName;
        country.callingPrefix = countryDTO.CallingPrefix;
        country.id = countryDTO.Id;

        return country;
    }

    public mapItemToCountryDTO(country: CountryType): CountryDTOType {
        const countryDTO: CountryDTOType = {} as CountryDTOType;

        countryDTO.CountryCode = country.countryCode;
        countryDTO.CountryName = country.countryName;
        countryDTO.CallingPrefix = country.callingPrefix;
        countryDTO.Id = country.id;

        return countryDTO;
    }
}
