<div class="create-reservation-modal create-entity-md-wrapper">
    <div class="modal-header">
        <h4 *ngIf="resourceTimeSlot.isEventEditable" class="modal-title" id="modal-basic-title4">
            <strong>{{modalTitle | translate}}
                <span
                        *ngIf="(currentPage===0 || currentPage ===1)"> {{resourceTimeSlot.timeSlot.hourFrom | hourFromMinutes}}
                    - {{resourceTimeSlot.timeSlot.hourTo | hourFromMinutes}}</span>
                <!--                <span *ngIf="currentPage===2"> {{hourFromTime}} - {{hourToTime}}</span>-->
                <!--                <span *ngIf="resourceTimeSlot.startDate">-->
                <!--                    {{resourceTimeSlot.startDate | dateEndOfDay}}-->
                <!--                </span>-->
            </strong>
        </h4>
        <h4 *ngIf="!resourceTimeSlot.isEventEditable">
            <strong><span translate="label.infrastructureReservation"></span>
                <span
                        *ngIf="(currentPage===0 || currentPage ===1)"> {{resourceTimeSlot.timeSlot.hourFrom | hourFromMinutes}}
                    - {{resourceTimeSlot.timeSlot.hourTo | hourFromMinutes}}</span>
                <!--                <span *ngIf="currentPage===2"> {{hourFromTime}} - {{hourToTime}}</span> -->
            </strong>
        </h4>
        <button (click)="activeModal.dismiss('Close')" aria-label="Close" class="close" type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <form>
            <!-- ============================================================== -->
            <!-- Page 0 -->
            <!-- ============================================================== -->
            <div *ngIf="currentPage===0" class="form-group">
                <p class="mb-0"><span class="font-weight-bold"
                                      translate="label.modifyBy"></span>&nbsp;{{resourceTimeSlot.modifiedBy}}</p>
                <p class="mb-1"><span class="font-weight-bold"
                                      translate="label.modifyOn"></span>&nbsp;{{resourceTimeSlot.modifiedOn | dateFormat }}
                </p>
                <h4 *ngIf="resourceTimeSlot.isEventEditable" class="card-title">{{modalText1 | translate}}</h4>
                <div *ngIf="resourceTimeSlot.isEventEditable" class="button-group">
                    <button (click)="onActionButtonClick(infrastructureReservationConstants.SHRINK)"
                            class="btn theme-btn"
                            type="button" translate="label.edit">
                    </button>
                    <button (click)="onActionButtonClick(infrastructureReservationConstants.SPLIT)"
                            class="btn warning-btn"
                            type="button"
                            translate="label.split">
                    </button>
                    <button (click)="onActionButtonClick(constants.DELETE)"
                            class="btn alert-btn"
                            type="button" translate="label.delete">
                    </button>
                </div>
            </div>
            <!-- ============================================================== -->
            <!-- Page 1 -->
            <!-- ============================================================== -->
            <div *ngIf="currentPage===1" class="form-group">
                <!--RESERVATION CONFLICTS-->
                <div *ngIf="showReservationConflicts">
                    <p translate="label.reservationConflictWithSlot"></p>
                    <ul>
                        <li *ngFor="let timeSlot of calendarTimeSlots">
                            {{timeSlot.hourFromTime}} - {{timeSlot.hourToTime}}
                        </li>
                    </ul>
                    <div class="text-reservation orange">
                        <i class="fas fa-exclamation-circle"></i> &nbsp;
                        <strong *ngIf="!showTimeSlotAlternative"
                                translate="label.reservationConflictAlternatives"></strong>
                        <strong *ngIf="showTimeSlotAlternative"
                                translate="label.reservationConflictAlternativeFound"></strong>
                    </div>
                </div>

                <!--TIME PICKERS-->
                <label *ngIf="(showReservationConflicts && showTimeSlotAlternative) || !showReservationConflicts">
                    {{modalText2 | translate }}
                </label>
                <div *ngIf="(showReservationConflicts && showTimeSlotAlternative) || !showReservationConflicts"
                     class="input-group">
                    <div class="time-picker">
                        <ngx-timepicker-field
                                [disableClick]="true"
                                [disabled]="false"
                                [format]="24"
                                [ngClass]="{'time-picker-red-border': !isTimeValid}"
                                [ngxTimepicker]="toggleTimepicker1"
                                (timeChanged)="onHourFromChanged($event)"
                                [cancelBtnTmpl]="closeBtn"
                                [confirmBtnTmpl]="confirmBtn"
                                [defaultTime]="hourFromTime"
                                class="time-picker-input background-white-no-borders mr-3"
                        ></ngx-timepicker-field>
                        <ngx-material-timepicker #toggleTimepicker1
                                                 [editableHintTmpl]="dialHint"
                                                 [enableKeyboardInput]="true"
                        >
                        </ngx-material-timepicker>
                    </div>

                    <div *ngIf="action!==infrastructureReservationConstants.SPLIT" class="time-picker">
                        <ngx-timepicker-field
                                [disableClick]="true"
                                [disabled]="false"
                                [format]="24"
                                [ngClass]="{'time-picker-red-border': !isTimeValid}"
                                [ngxTimepicker]="toggleTimepicker2"
                                [defaultTime]="hourToTime"
                                [cancelBtnTmpl]="closeBtn"
                                [confirmBtnTmpl]="confirmBtn"
                                (timeChanged)="onHourToChanged($event)"
                                class="time-picker-input background-white-no-borders"
                        ></ngx-timepicker-field>
                        <ngx-material-timepicker #toggleTimepicker2
                                                 [editableHintTmpl]="dialHint"
                                                 [enableKeyboardInput]="true"
                        >
                        </ngx-material-timepicker>
                    </div>
                </div>
                <div *ngIf="!isTimeValid" class="row">
                    <div class="invalid-input" translate="toastr.error.invalidTime"></div>
                </div>
                <!--MOVE OPTIONS-->
                <div *ngIf="action===constants.MOVE && showTimeSlotAlternative" class="split-option">
                    <label translate="label.splitOption"></label>
                    <div class="input-group">
                        <mat-checkbox
                                (change)="onSplitOptionChanged(infrastructureReservationConstants.DELETE_ORIGINAL_RESERVATION, splitOption.deleteOriginalReservation)"
                                [(ngModel)]="splitOption.deleteOriginalReservation"
                                name="cbx4">{{"label.deleteOriginalReservation" | translate}}
                        </mat-checkbox>
                    </div>
                    <div class="input-group">
                        <mat-checkbox
                                (change)="onSplitOptionChanged(infrastructureReservationConstants.KEEP_ORIGINAL_RESERVATION, splitOption.keepOriginalReservation)"
                                [(ngModel)]="splitOption.keepOriginalReservation"
                                name="cbx5">{{"label.keepOriginalReservation" | translate}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>

            <!--EDIT: SPLIT/SHRINK - SIMULATION-->

            <div *ngIf="editReservationSimulationList.length>0"
                 class="split-option">

                <strong *ngIf="action===infrastructureReservationConstants.SPLIT && currentPage === 1"
                        translate="label.reservationPreviewSplit"></strong>

                <ul *ngIf="action===infrastructureReservationConstants.SPLIT && currentPage === 1">
                    <li *ngFor="let timeSlot of editReservationSimulationList">

                        {{timeSlot.hourFrom  | hourFromMinutes}} - {{timeSlot.hourTo  | hourFromMinutes}}

                    </li>

                </ul>

            </div>
            <!-- ============================================================== -->
            <!-- Page 2 CREATE-->
            <!-- ============================================================== -->
            <div *ngIf="currentPage===2 && this.action === constants.CREATE" class="form-group">
                <label translate="label.reservationType"></label>
                <div class="input-group">
                    <mat-checkbox
                            (change)="onAssignationTypeChanged(infrastructureReservationConstants.SINGLE_INSTANCE, assignationType.singleInstance)"
                            [(ngModel)]="assignationType.singleInstance"
                            name="cbx1">{{"label.singleInstanceReservation" | translate}}
                    </mat-checkbox>
                </div>
                <div class="input-group">
                    <mat-checkbox
                            (change)="onAssignationTypeChanged(infrastructureReservationConstants.ALL_INSTANCES, assignationType.allInstances)"
                            [(ngModel)]="assignationType.allInstances"
                            name="cbx2">{{"label.allInstancesReservation" | translate}}
                    </mat-checkbox>
                </div>
                <div class="input-group">
                    <mat-checkbox
                            (change)="onAssignationTypeChanged(infrastructureReservationConstants.UNTIL_END_DATE, assignationType.untilEndDate)"
                            [(ngModel)]="assignationType.untilEndDate"
                            name="cbx3">{{"label.untilEndDateReservation" | translate}}
                    </mat-checkbox>
                </div>

                <div class="input-group date-picker">
                    <input #d1="ngbDatepicker"
                           [(ngModel)]="startDate"
                           (ngModelChange)="onStartDateChange($event)"
                           [disabled]="reservation.assignationType !== infrastructureReservationConstants.UNTIL_END_DATE || !this.assignationType.untilEndDate"
                           [minDate]="minDate"
                           [ngClass]="{'disable': (reservation.assignationType !== infrastructureReservationConstants.UNTIL_END_DATE || !this.assignationType.untilEndDate), 'redBorder': !isDateValid || !isDateGreaterThanYearValid }"
                           class="form-control"
                           name="dp1"
                           ngbDatepicker
                           placeholder="yyyy-mm-dd">
                    <div class="input-group-append">
                        <button (click)="d1.toggle()"
                                [disabled]="reservation.assignationType !== infrastructureReservationConstants.UNTIL_END_DATE || !this.assignationType.untilEndDate"
                                [ngClass]="{'disabled': (reservation.assignationType !== infrastructureReservationConstants.UNTIL_END_DATE || !this.assignationType.untilEndDate), 'redBorder': !isDateValid || !isDateGreaterThanYearValid }"
                                class="btn btn-outline-secondary calendar"
                                type="button">
                            <i class="fa fa-calendar" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <div class="input-group date-picker">
                    <input #d2="ngbDatepicker"
                           [(ngModel)]="endDate"
                           (ngModelChange)="onEndDateChange($event)"
                           [disabled]="reservation.assignationType !== infrastructureReservationConstants.UNTIL_END_DATE || !this.assignationType.untilEndDate"
                           [minDate]="minDate"
                           [ngClass]="{'disabled': (reservation.assignationType !== infrastructureReservationConstants.UNTIL_END_DATE || !this.assignationType.untilEndDate), 'redBorder': !isDateValid ||  !isDateGreaterThanYearValid}"
                           class="form-control"
                           name="dp2"
                           ngbDatepicker
                           placeholder="yyyy-mm-dd">
                    <div class="input-group-append">
                        <button (click)="d2.toggle()"
                                [disabled]="reservation.assignationType !== infrastructureReservationConstants.UNTIL_END_DATE || !this.assignationType.untilEndDate"
                                [ngClass]="{'disabled': (reservation.assignationType !== infrastructureReservationConstants.UNTIL_END_DATE || !this.assignationType.untilEndDate), 'redBorder': !isDateValid || !isDateGreaterThanYearValid }"
                                class="btn btn-outline-secondary calendar"
                                type="button">
                            <i class="fa fa-calendar" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <div *ngIf="!isDateValid" class="row">
                    <div class="invalid-input" translate="label.error.invalidDate"></div>
                </div >
                <div *ngIf=" !isDateGreaterThanYearValid && isDateValid" class="row">
                    <div class="invalid-input" translate="label.error.dateMoreThan365Days" ></div>
                </div >
            </div>

            <!-- ============================================================== -->
            <!-- Page 2 EDIT / SPLIT / MOVE / DELETE-->
            <!-- ============================================================== -->
            <div *ngIf="currentPage===2 && this.action !== constants.CREATE" class="form-group">
                <label translate="label.reservationType"></label>
                <div *ngIf="action === constants.DELETE || !reservation.isPartOfSeries"
                     [ngClass]="{'container-warning-message':!reservation.isPartOfSeries}">
                    <span *ngIf="!reservation.isPartOfSeries" class="warning-message">
                        <span class="exclamation-sign"><i class="fas fa-exclamation-circle"></i></span>
                        <label translate="label.warning.reservationIsNotPartOfSeries"></label>
                    </span>
                </div>
                <div class="input-group">
                    <mat-checkbox
                            (change)="onChangeAssignationTypeChanged(infrastructureReservationConstants.THIS_INSTANCE, changeAssignationType.thisInstance)"
                            [(ngModel)]="changeAssignationType.thisInstance"
                            name="cbx1">{{"label.singleInstanceReservation" | translate}}
                    </mat-checkbox>
                </div>
                <div class="input-group">
                    <mat-checkbox
                            (change)="onChangeAssignationTypeChanged(infrastructureReservationConstants.ALL_INSTANCES, changeAssignationType.allInstances)"
                            [(ngModel)]="changeAssignationType.allInstances"
                            [disabled]="!reservation.isPartOfSeries"
                            [ngClass]="{'disable': !reservation.isPartOfSeries}"
                            name="cbx2">{{"label.allInstancesReservation" | translate}}
                    </mat-checkbox>
                </div>
                <div class="input-group">
                    <mat-checkbox
                            (change)="onChangeAssignationTypeChanged(infrastructureReservationConstants.THIS_AND_FOLLOWING_INSTANCES, changeAssignationType.thisAndFollowingInstances)"
                            [(ngModel)]="changeAssignationType.thisAndFollowingInstances"
                            [disabled]="!reservation.isPartOfSeries"
                            [ngClass]="{'disable': !reservation.isPartOfSeries}"
                            name="cbx3">{{"label.thisAndFollowingInstancesReservation" | translate}}
                    </mat-checkbox>
                </div>

                <div class="input-group">
                    <mat-checkbox
                            (change)="onChangeAssignationTypeChanged(infrastructureReservationConstants.THIS_AND_FOLLOWING_INSTANCES_TILL_END_DATE, changeAssignationType.thisAndFollowingInstancesTillEndDate)"
                            [(ngModel)]="changeAssignationType.thisAndFollowingInstancesTillEndDate"
                            [disabled]="!reservation.isPartOfSeries"
                            [ngClass]="{'disable': !reservation.isPartOfSeries}"
                            name="cbx4">{{"label.thisAndFollowingInstancesTillEndDateReservation" | translate}}
                    </mat-checkbox>
                </div>

                <div class="input-group date-picker">
                    <input #d1="ngbDatepicker"
                           [(ngModel)]="endDate"
                           (ngModelChange)="onEndDateChange(endDate)"
                           [disabled]="!reservation.isPartOfSeries || reservation.assignationType !== infrastructureReservationConstants.THIS_AND_FOLLOWING_INSTANCES_TILL_END_DATE || !this.changeAssignationType.thisAndFollowingInstancesTillEndDate"
                           [minDate]="minDate"
                           [ngClass]="{'disable': (!reservation.isPartOfSeries || reservation.assignationType !== infrastructureReservationConstants.THIS_AND_FOLLOWING_INSTANCES_TILL_END_DATE || !this.changeAssignationType.thisAndFollowingInstancesTillEndDate), 'redBor-der': !isDateValid}"
                           class="form-control"
                           name="dp1"
                           ngbDatepicker
                           placeholder="yyyy-mm-dd">
                    <div class="input-group-append">
                        <button (click)="d1.toggle()"
                                [disabled]="!reservation.isPartOfSeries || reservation.assignationType !==  infrastructureReservationConstants.THIS_AND_FOLLOWING_INSTANCES_TILL_END_DATE || !this.changeAssignationType.thisAndFollowingInstancesTillEndDate"
                                [ngClass]="{'disabled': (!reservation.isPartOfSeries || reservation.assignationType !==  infrastructureReservationConstants.THIS_AND_FOLLOWING_INSTANCES_TILL_END_DATE || !this.changeAssignationType.thisAndFollowingInstancesTillEndDate), 'redBor-der': !isDateValid}"
                                class="btn btn-outline-secondary calendar"
                                type="button">
                            <i class="fa fa-calendar" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <div *ngIf="!isDateValid" class="row">
                    <div class="invalid-input" translate="label.error.invalidDate"></div>
                </div>
            </div>

            <!-- ============================================================== -->
            <!-- Page 3 -->
            <!-- ============================================================== -->
            <div *ngIf="currentPage===3" class="form-group">
                <div *ngIf="successfulInstances.length>0" class="text-reservation green">
                    <i class="fas fa-check-circle"></i> &nbsp;
                    <strong
                            *ngIf="successfulInstances.length===1">{{successfulInstances.length}} {{"label.reservationCreatedWithSuccess" | translate}}</strong>
                    <strong
                            *ngIf="successfulInstances.length>1">{{successfulInstances.length}} {{"label.reservationsCreatedWithSuccess" | translate}}</strong>
                </div>
                <div *ngIf="conflictInstances.length>0" class="text-reservation red">
                    <i class="fas fa-exclamation-circle"></i> &nbsp;
                    <label
                            *ngIf="conflictInstances.length===1"> {{conflictInstances.length}} {{"label.reservationCreatedWithConflicts" | translate}}</label>
                    <label
                            *ngIf="conflictInstances.length>1"> {{conflictInstances.length}} {{"label.reservationsCreatedWithConflicts" | translate}}</label>
                    <table *ngFor="let conflict of conflictInstances" class="table table-sm">
                        <tbody>
                        <tr>
                            <td>{{conflict.date | date: 'dd-MM-yyyy'}}</td>
                            <td>{{conflict.hourFrom | hourFromMinutes}} - {{conflict.hourTo | hourFromMinutes}}</td>
                            <td>{{conflict.conflictResourceName}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </form>
    </div>

    <div *ngIf="currentPage>0" class="modal-footer">
        <!--BACK-->
        <button (click)="onPageChanged(currentPage-1)" *ngIf="showBackButton"
                class="btn back-btn"
                translate="label.back"
                type="button">
        </button>
        <!--NEXT/SAVE/SAVE with CONFLICTS/DELETE/MOVE/MOVE with CONFLICTS-->
        <button (click)="onPageChanged(currentPage + 1)" *ngIf="showActionButton"
                [disabled]="!this.isScreenDataValid()"
                class="btn theme-btn"
                translate="{{actionBtnLabel}}"
                type="button">
        </button>
        <!--CANCEL-->
        <button (click)="activeModal.dismiss('Close')"
                class="btn close-btn"
                translate="label.cancel"
                type="button">
        </button>
    </div>

</div>

<ng-template #dialHint>
    <p class="dial-hint">{{"label.timepickerHint" | translate}}</p>
</ng-template>

<ng-template #confirmBtn>
    <div class="timepicker-buttons">
        <button class="btn theme-btn" type="button" translate="label.ok"></button>
    </div>
</ng-template>

<ng-template #closeBtn>
    <div class="timepicker-buttons">
        <button class="btn cancel-btn" translate="label.cancel"
                type="button">
        </button>
    </div>
</ng-template>
