import {DirectiveModule} from './directives/directive.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {FormsModule} from '@angular/forms';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {ClickOutsideModule} from 'ng-click-outside';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgxPaginationModule} from 'ngx-pagination';
import {TagInputModule} from 'ngx-chips';
import {DateFormatPipe} from './pipes/date-format.pipe';
import {VersionInfoMessageComponent} from './component/version-info-message/version-info-message.component';
import {MatStepperModule} from '@angular/material/stepper';
import {MatIconModule} from '@angular/material/icon';
import {YesNoPipe} from './pipes/yes-no.pipe';
import {ShortDateFormatPipe} from './pipes/short-date-format.pipe';
import {FullDateFormatPipe} from './pipes/full-date-format.pipe';
import {FullDateBrowserTzFormatPipe} from './pipes/full-date-browser-tz-format.pipe';
import {DateMMddFormatPipe} from './pipes/date-MMdd-format.pipe';
import {DateMMddyyyyFormatPipe} from './pipes/date-MMddyyyy-format.pipe';
import {DateMdFormatPipe} from './pipes/date-Md-format.pipe';
import {DateEndOfDayPipe} from './pipes/date-end-of-day.pipe';
import {FullDateNoTimeFormatPipe} from './pipes/full-date-no-time-format.pipe';
import {DateBrowserTzFormatPipe} from './pipes/date-browser-tz-format.pipe';
import {DateddddFormatPipe} from './pipes/date-dddd-format.pipe';
import {DateMMDDcommaYYYYFormatPipe} from './pipes/date-MMDDcommaYYYY-format.pipe';
import {BaseModulesModule} from './base-modules/base-modules.module';
import {HourFromMinutesPipe} from './pipes/hour-from-minutes.pipe';
import {MatSidenavModule} from '@angular/material/sidenav';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/language_', '.json');
}

@NgModule({
    declarations: [HourFromMinutesPipe, DateFormatPipe, ShortDateFormatPipe, VersionInfoMessageComponent,
        YesNoPipe, FullDateFormatPipe, FullDateBrowserTzFormatPipe, DateMMddFormatPipe, DateMMddyyyyFormatPipe,
        DateMdFormatPipe, DateEndOfDayPipe, FullDateNoTimeFormatPipe, DateBrowserTzFormatPipe, DateddddFormatPipe,
        DateMMDDcommaYYYYFormatPipe],
    imports: [
        DirectiveModule,
        CommonModule,
        NgbModule,
        BrowserAnimationsModule,
        NgxUiLoaderModule,
        BaseModulesModule,
        ClickOutsideModule,
        NgSelectModule,
        FormsModule,
        NgxPaginationModule,
        MatStepperModule,
        MatIconModule,
        ToastrModule.forRoot({
            preventDuplicates: true,
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        TagInputModule
    ],
    entryComponents: [VersionInfoMessageComponent],
    exports: [NgbModule, CommonModule, TranslateModule, FormsModule, NgxUiLoaderModule, BaseModulesModule,
        ClickOutsideModule, HourFromMinutesPipe, DirectiveModule, TagInputModule, DateFormatPipe, ShortDateFormatPipe,
        MatStepperModule, MatIconModule, YesNoPipe, FullDateFormatPipe, FullDateBrowserTzFormatPipe, DateMMddFormatPipe,
        DateMMddyyyyFormatPipe, DateMdFormatPipe, DateEndOfDayPipe, FullDateNoTimeFormatPipe, DateBrowserTzFormatPipe,
        DateddddFormatPipe, DateMMDDcommaYYYYFormatPipe]
})
export class SharedModule {
}
