import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';

// class decorator that will automatically unsubscribe from observable subscriptions when the component is destroyed
@AutoUnsubscribe()
@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
    displayLoginAgainMessage = false;

    constructor(private router: Router) {
    }

    ngOnInit() {
        if (history?.state?.displayLoginAgainMessage) {
            this.displayLoginAgainMessage = history.state.displayLoginAgainMessage;
        }
    }

    goToHome(): void {
        this.router.navigate(['/dashboard']);
    }

    ngOnDestroy(): void {
    }
}
